import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Input, Button, Space, Image, message } from "antd";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  IResolveParams,
} from "reactjs-social-login";
import { api } from "../../system/api";
import useToken from "../../system/useToken";
import { useNavigate } from "react-router-dom";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import {
  UserOutlined,
  LockOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./style.css";
import useUserInfo from "../../system/useUserInfo";

async function loginUser(credentials) {
  return api
    .post("/api/Systems/login", credentials)
    .then((response) => response?.data?.retdata);
}

export default function Login() {
  const { userinfo } = useUserInfo();
  const { token, setToken } = useToken();
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const googleRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const onFacebookConnectSuccess = useCallback(async (data) => {
    try {
      // Handle successful Facebook connection
      setProvider("facebook");
      setProfile(data);

      // Check if the user with the Facebook ID already exists in the system
      let existingUserResponse;
      try {
        existingUserResponse = await api.get(
          `/api/systems/User/get_user?userid=${data?.id}`
        );
      } catch (error) {
        // If the user does not exist, proceed with registration
        if (error.response && error.response.status === 400) {
          existingUserResponse = null;
        } else {
          throw error; // Re-throw other errors
        }
      }

      if (existingUserResponse?.data?.retdata === null) {
        // User does not exist, proceed with registration
        // Prepare user data for registration
        const userData = {
          username: data?.first_name,
          email: data?.email,
          password: "facebook",
          encryptpass: null,
          roleid: 0,
          gender: 0,
          birthday: "2023-11-18T20:02:58.473Z",
          facebookid: data?.id,
          type: "facebook",
        };
        const response = await api.post("/api/systems/User/set_user", userData);
        if (response.status === 200) {
          // Registration successful, proceed with login
          const token = await loginUser({
            username: data.first_name,
            password: "facebook",
            type: "facebook",
          });
          if (token) {
            // Set the authentication token
            setToken(token);
            navigate("/account/config", {
              state: { userid: userinfo?.userid },
            });
          } else {
            // Handle invalid credentials case
            message.error("Login failed with Facebook");
          }
        } else {
          // Handle registration error
          console.error("User registration failed:", response.statusText);
        }
      } else {
        // User already exists, proceed with login
        const token = await loginUser({
          username: data.first_name,
          password: "facebook",
          type: "facebook",
        });
        if (token) {
          // Set the authentication token
          setToken(token);
          navigate("/", { state: { userid: userinfo?.userid } });
        } else {
          // Handle invalid credentials case
          message.error("Login failed with Facebook");
        }
      }
    } catch (error) {
      console.error("An error occurred during user registration:", error);
    }
  }, []);

  const responseGoogle = useCallback(async (data) => {
    try {
      // Handle successful Google login
      if (data) {
        setProvider("google");
        setProfile(data);

        // Check if the user with the Google ID already exists in the system
        let existingUserResponse;
        try {
          existingUserResponse = await api.get(
            `/api/systems/User/get_user?userid=${data?.sub})}`
          );
        } catch (error) {
          // If the user does not exist, proceed with registration
          if (error.response && error.response.status === 400) {
            existingUserResponse = null;
          } else {
            throw error; // Re-throw other errors
          }
        }

        if (existingUserResponse?.data?.retdata === null) {
          const userData = {
            username: data?.given_name,
            email: "",
            password: "google",
            encryptpass: null,
            roleid: 0,
            gender: 0,
            birthday: "2023-11-18T20:02:58.473Z",
            facebookid: data?.sub,
            type: "google",
          };
          const response = await api.post(
            "/api/systems/User/set_user",
            userData
          );
          if (response.status === 200) {
            // Registration successful, proceed with login
            const token = await loginUser({
              username: data?.given_name,
              password: "google",
              type: "google",
            });
            if (token) {
              // Set the authentication token
              setToken(token);
              navigate("/account/config", {
                state: { userid: userinfo?.userid },
              });
            } else {
              // Handle invalid credentials case
              message.error("Login failed with Facebook");
            }
          } else {
            // Handle registration error
            console.error("User registration failed:", response.statusText);
          }
          // User exists, proceed with login
          const token = await loginUser({
            username: data?.given_name,
            password: "google",
            type: "google",
          });

          if (token) {
            // Set the authentication token
            setToken(token);
            navigate("/account/config", {
              state: { userid: userinfo?.userid },
            });
          } else {
            // Handle invalid credentials case
            message.error("Login failed with Google");
          }
        } else {
          // User does not exist, proceed with registration
          const userData = {
            username: data?.given_name,
            email: "",
            password: "google", // Assuming password for Google login
            encryptpass: null, // You may handle encryption as needed
            roleid: 0, // Set role as needed
            gender: 0, // Set gender as needed
            birthday: "2023-11-18T20:02:58.473Z", // Set birthday as needed
            facebookid: data?.sub,
            type: "google",
          };
          const response = await api.post(
            "/api/systems/User/set_user",
            userData
          );
          if (response.status === 200) {
            // Registration successful, proceed with login
            const token = await loginUser({
              username: data?.given_name,
              password: "google",
              type: "google",
            });
            if (token) {
              // Set the authentication token
              setToken(token);
              navigate("/", {
                state: { userid: userinfo?.userid },
              });
            } else {
              // Handle invalid credentials case
              message.error("Login failed with Google");
            }
          } else {
            // Handle registration error
            console.error("User registration failed:", response.statusText);
          }
        }
      }
    } catch (error) {
      console.error("An error occurred during user login:", error);
    }
  }, []);

  const onFinish = async (values) => {
    try {
      const token = await loginUser(values);

      if (token) {
        setToken(token);
        message.success("Амжилттай нэвтэрлээ.");
        // Redirect to the home page after successful login
        window.location.assign("/");
      } else {
        // Handle invalid credentials case
        message.error("Нэвтрэх нэр эсвэл нууц үг буруу байна");
      }
    } catch (error) {
      // Customize error messages based on the error
      if (error.message === "Invalid username or password") {
        message.error("Нэвтрэх нэр эсвэл нууц үг буруу байна");
      } else {
        message.error("Нэвтрэх нэр эсвэл нууц үг буруу байна");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`${
        !isMobile
          ? "registration-form-container"
          : "registration-form-container-mobile"
      }`}
    >
      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          zIndex: 1,
        }}
        className="custom-back-button"
        href="/"
      >
        <img src="/assets/images/uju4.png" alt="Your Image" />
      </Button>
      <div
        className={`${
          !isMobile
            ? "registration-form-content"
            : "registration-form-content-mobile"
        }`}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/images/uju4.png"
            alt="Your Image"
            width={isMobile ? 100 : 200}
          />
        </div>

        <h2>Нэвтрэх</h2>

        <Form
          name="login"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Хэрэглэгчийн нэр эсвэл и-мэйл хаяг оруулна уу!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Хэрэглэгчийн нэр эсвэл и-мэйл"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Нууц үг оруулна уу!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Нууц үг"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="registration-form-button"
              block
              loading={loading}
              size="large"
            >
              Нэвтрэх
            </Button>
          </Form.Item>
        </Form>
        <div className="registration-options">
          <Button type="link" href="/reset-password">
            Нууц үгээ мартсан уу?
          </Button>
          <Button type="link" href="/register">
            Бүртгүүлэх
          </Button>
        </div>
        <div className="text-center">
          <p className="light">Өөрийн сошиал хаяг ашиглан нэвтрэх боломжтой.</p>
        </div>

        <div className="registration-social-buttons">
          <Space direction="horizontal">
            <LoginSocialGoogle
              ref={googleRef}
              client_id="1089983387918-5js90gudn8k4gpkbq3pecu1pgt0716it.apps.googleusercontent.com"
              onSuccess={responseGoogle}
              onResolve={({ provider, data }) => responseGoogle(data)}
              onReject={(err) => {
                console.log("hbhbdhd", err);
              }}
            >
              <GoogleLoginButton text="Google" size={40} />
            </LoginSocialGoogle>

            <LoginSocialFacebook
              appId={1262242787777446}
              onResolve={({ provider, data }) => onFacebookConnectSuccess(data)}
              onReject={(error) => {
                console.log(error);
              }}
            >
              <FacebookLoginButton
                className="registration-form-button-facebook"
                text="Facebook"
                size={40}
              />
            </LoginSocialFacebook>
          </Space>
        </div>
        <Button type="link" icon={<ArrowLeftOutlined />} href="/register">
          Бүртгүүлэх хуудас руу буцах
        </Button>
      </div>
    </div>
  );
}
