import React, { useEffect, useState } from "react";
import { Steps, Button, message, Row, Col, Layout } from "antd";
import "./style.css";
import Address from "./Address";
import CartSummary from "./RightMessage";
import OrderProducts from "./OrderProducts";
import { useAppContext } from "../../Context/index";

const { Step } = Steps;
const { Content } = Layout;

export default function CheckOutPage() {
  const { updateUserAddress, loadShoppingCart } = useAppContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [current, setCurrent] = React.useState(0);
  const [isAddressFormFilled, setIsAddressFormFilled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const steps = [
    {
      title: "Хаягийн мэдээлэл",
      content: (
        <Address
          updateUserAddress={updateUserAddress}
          onFormFilled={(filled) => setIsAddressFormFilled(filled)}
        />
      ),
    },
    {
      title: "Бараа баталгаажуулах",
      content: <OrderProducts />,
    },
  ];

  const handleStepClick = (step) => {
    setCurrent(step);
  };

  return (
    <Content style={{ maxWidth: 1200, margin: "0 auto" }}>
      <Row gutter={16}>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <div className="checkout-ct">
            <h2>ЗАХИАЛГА / ТӨЛБӨР ТӨЛӨХ</h2>
            <Steps current={current}>
              {steps.map((item, index) => (
                <Step
                  key={item?.title}
                  title={item?.title}
                  onClick={() => handleStepClick(index)}
                />
              ))}
            </Steps>
            <div className="steps-content">{steps[current]?.content}</div>
            <div className="steps-action" style={{ paddingTop: 10 }}>
              {current < steps?.length - 1 && isAddressFormFilled && (
                <Button
                  type="primary"
                  block
                  onClick={() => setCurrent(current + 1)}
                >
                  Үргэлжлүүлэх
                </Button>
              )}

              {current > 0 && (
                <Button onClick={() => setCurrent(current - 1)}>Буцах</Button>
              )}
            </div>
          </div>
        </Col>

        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          {(!isMobile || (isMobile && current === 1)) && (
            <div className="checkout-ct">
              <CartSummary loadShoppingCart={loadShoppingCart} />
            </div>
          )}
        </Col>
      </Row>
    </Content>
  );
}
