import React, { useState, useEffect } from "react";
import { Card, Form, Input, Button, message, Result, Spin } from "antd";
import { api } from "../../system/api";
import { useNavigate } from "react-router-dom";

// Function to decode Base64 token
const decodeBase64 = (base64) => {
  try {
    return atob(base64);
  } catch (e) {
    return null;
  }
};

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [userid, setUserId] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(undefined); // Updated to undefined for initial state
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");

    const checkTokenValidity = async (token) => {
      try {
        const response = await api.post(
          `/api/Systems/check_token?token=${token}`
        );
        if (response.status === 200 && response.data.rettype === 0) {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      } catch (error) {
        console.error("Error checking token:", error);
        setIsTokenValid(false);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    if (tokenParam) {
      const decodedToken = decodeBase64(tokenParam);
      if (decodedToken) {
        const parts = decodedToken.split(";");
        if (parts.length > 0) {
          setUserId(parts[0]);
          checkTokenValidity(tokenParam);
        } else {
          setIsTokenValid(false);
          setLoading(false); // Set loading to false if token is invalid
        }
      } else {
        setIsTokenValid(false);
        setLoading(false); // Set loading to false if token is invalid
      }
    } else {
      setIsTokenValid(false);
      setLoading(false); // Set loading to false if token is invalid
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onFinish = async (values) => {
    if (values.password !== values.confirmPassword) {
      message.error("Нууц үг  ижилхэн биш байна.");
      return;
    }

    try {
      const response = await api.post("/api/systems/User/update_password", {
        userid,
        password: values.password,
        encryptpass: values.confirmPassword,
      });
      if (response.status === 200) {
        message.success("Нууц үг амжилттай шинэчлэгдлээ");
      } else {
        message.error("Нууц үг солих үед алдаа гарлаа.");
      }
    } catch (error) {
      message.error("Нууц үгийг шинэчлэх үед алдаа гарлаа.");
    }
  };

  if (loading) {
    return (
      <Spin
        size="large"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      />
    );
  }

  if (!isTokenValid) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Уучлаарай, Нууц үгийг шинэчлэх холбоосны хугацаа дууссан байна."
        extra={
          <Button type="primary" href="/">
            Нүүр хуудас руу буцах
          </Button>
        }
      />
    );
  }

  return (
    <Card
      className="user-editing-card"
      style={{
        width: isMobile ? "90%" : "50%",
        padding: 20,
        marginBottom: 100,
        marginTop: 50,
      }}
    >
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <h2>Нууц үг шинэчлэх</h2>
        <p>
          Үсэг тоо оролцуулан хамгийн багадаа 8 тэмдэгт оруулан нууц үгийг
          зохиож оруулна уу.
        </p>
      </div>
      <Form
        name="update-password-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Шинэ нууц үг"
          name="password"
          rules={[
            {
              required: true,
              message: "Шинэ нууц үг",
            },
            {
              min: 8,
              message: "Хамгийн багадаа 8 тэмдэгт оруулна уу",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Нууц үг давтах"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Шинэ нууц үгийг давтана уу",
            },
            {
              min: 8,
              message: "Хамгийн багадаа 8 тэмдэгт оруулна уу",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          wrapperCol={{ offset: 0, span: 24 }}
          style={{ paddingTop: 30 }}
        >
          <Button type="primary" htmlType="submit" size="large" block>
            Нууц үгийг шинэчлэх
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default PasswordRecovery;
