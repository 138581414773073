import React from "react";
import { Form, Input, Button, Space, message } from "antd";
import {
  MailOutlined,
  ArrowLeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./style.css";
import { api } from "../../system/api";

export default function ResetPassword() {
  const onFinish = async (values) => {
    try {
      const response = await api.post(
        `/api/Systems/password_recovery_email?email=${values?.email}`
      );
      if (response.status === 200 && response.data.rettype === 0) {
        message.success(
          "Таны бүртгэлтэй и-мэйл хаяг руу нууц үг сэргээх холбоос илгээсэн."
        );
      }

      // Handle success response (e.g., show a message to the user)
    } catch (error) {
      message.error("Хэрэглэгчийн мэдээлэл олдсонгүй.");
      // Handle error response (e.g., show an error message to the user)
    }
  };

  return (
    <div className="registration-form-container">
      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          zIndex: 1,
        }}
        className="custom-back-button"
        onClick={() => window.location.assign("/")}
      >
        <img src="/assets/images/uju4.png" alt="Your Image" width="100" />
      </Button>
      <div className="registration-form-content">
        <h2 className="text-center">Нууц үг сэргээх</h2>
        <Space style={{ paddingBottom: 20 }}>
          Бүртгэлтэй и-мэйл хаягийг оруулна уу. Бид тань руу нууц үг сэргээх
          холбоос илгээх болно.
        </Space>
        <Form
          name="registration"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "И-мэйл хаяг оруулна уу!" },
              { type: "email", message: "И-мэйл хаягийн формат буруу байна!" },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="И-мэйл"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="registration-form-button"
            >
              Нууц үг сэргээх
            </Button>
          </Form.Item>
        </Form>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="link" icon={<ArrowLeftOutlined />} href="/">
            Нүүр хуудас руу буцах
          </Button>
          <Button
            type="link"
            icon={<UserOutlined />}
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Нэвтрэх
          </Button>
        </div>
      </div>
    </div>
  );
}
