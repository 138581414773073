import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Avatar,
  Divider,
  Image,
  Badge,
  Space,
  Typography,
  Tag,
  List,
} from "antd";
import { UserOutlined, StarOutlined } from "@ant-design/icons";
import { api } from "../../system/api";
import Countdown from "antd/lib/statistic/Countdown";
import { getBaseURL } from "../../system/apiconfig";
import useUserInfo from "../../system/useUserInfo";
import { useAppContext } from "../../Context/index";
import { Link } from "react-router-dom";
const { Meta } = Card;
const { Text } = Typography;

const formatNumberWithCommas = (number) => {
  return number?.toLocaleString();
};
export default function CollectionPage() {
  const { state, removeFromFavorites } = useAppContext();
  const { userinfo } = useUserInfo();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userinfo?.userid, state]);

  const handleClick = async (item) => {
    try {
      if (item?.id) {
        await removeFromFavorites(item?.id);
      } else {
        console.error("Favorite item not found for item:", item);
      }
    } catch (error) {
      console.error("Error in handleClick:", error);
    }
  };

  return (
    <div style={{ maxWidth: 1200, margin: "0 auto" }}>
      <h3>Хүслийн жагсаалт</h3>
      <Row justify="center" style={{ textAlign: "center" }}>
        <Col>
          <Avatar
            src={`${getBaseURL()}/api/file/download?ID=${
              state?.userImage
            }&size=50`}
            size="large"
            className="hover-avatar"
          />
          <h2>{userinfo?.username}</h2>
        </Col>
      </Row>
      <Divider />
      <List
        grid={{ gutter: !isMobile ? 16 : 0, column: !isMobile ? 4 : 2 }}
        dataSource={state?.favoriteItems ?? []}
        loading={loading}
        renderItem={(product, index) => (
          <List.Item key={index}>
            <Card
              className={`crt ${isMobile ? "mobile-card" : ""}`}
              key={index}
              style={{ border: 0 }}
            >
              {!isMobile ? (
                <>
                  <div className="image-container">
                    <Link to={`/product/${product?.item?.item?.id}`}>
                      {product?.item?.images !== undefined &&
                      product?.item?.images !== null &&
                      product?.item?.images?.length > 0 ? (
                        <Image
                          src={`${getBaseURL()}/api/file/download?ID=${
                            product?.item?.images[0]?.id
                          }&size=180`}
                          alt="product"
                          className="product-image"
                          preview={false}
                        />
                      ) : (
                        <Image
                          src="https://via.placeholder.com/100" // Placeholder image URL
                          alt="No Image"
                          className="product-image"
                          preview={false}
                        />
                      )}
                    </Link>
                  </div>

                  <div
                    className={`favorite-button ${
                      state?.favoriteItems?.find(
                        (favorite) => favorite?.itemid === product?.item?.id
                      )
                        ? "active"
                        : ""
                    }`}
                  >
                    <Badge>
                      {userinfo?.userid ? (
                        <Link onClick={() => handleClick(product)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <defs>
                              <path
                                id="scrap-icon-40-b"
                                d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                              ></path>
                              <filter
                                id="scrap-icon-40-a"
                                width="150%"
                                height="150%"
                                x="-25%"
                                y="-25%"
                                filterUnits="objectBoundingBox"
                              >
                                <feOffset
                                  in="SourceAlpha"
                                  result="shadowOffsetOuter1"
                                ></feOffset>
                                <feGaussianBlur
                                  in="shadowOffsetOuter1"
                                  result="shadowBlurOuter1"
                                  stdDeviation="1.5"
                                ></feGaussianBlur>
                                <feComposite
                                  in="shadowBlurOuter1"
                                  in2="SourceAlpha"
                                  operator="out"
                                  result="shadowBlurOuter1"
                                ></feComposite>
                                <feColorMatrix
                                  in="shadowBlurOuter1"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                ></feColorMatrix>
                              </filter>
                              <filter
                                id="scrap-icon-40-c"
                                width="150%"
                                height="150%"
                                x="-25%"
                                y="-25%"
                                filterUnits="objectBoundingBox"
                              >
                                <feGaussianBlur
                                  in="SourceAlpha"
                                  result="shadowBlurInner1"
                                  stdDeviation="1.5"
                                ></feGaussianBlur>
                                <feOffset
                                  in="shadowBlurInner1"
                                  result="shadowOffsetInner1"
                                ></feOffset>
                                <feComposite
                                  in="shadowOffsetInner1"
                                  in2="SourceAlpha"
                                  k2="-1"
                                  k3="1"
                                  operator="arithmetic"
                                  result="shadowInnerInner1"
                                ></feComposite>
                                <feColorMatrix
                                  in="shadowInnerInner1"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                ></feColorMatrix>
                              </filter>
                            </defs>
                            <g
                              fill="none"
                              fillRule="nonzero"
                              transform="matrix(1 0 0 -1 0 24)"
                              className="button-link active"
                            >
                              <use
                                fill="#000"
                                filter="url(#scrap-icon-40-a)"
                                href="#scrap-icon-40-b"
                              ></use>
                              <use
                                fill="#fff"
                                fillOpacity="0.5"
                                href="#scrap-icon-40-b"
                              ></use>
                              <use
                                fill="#000"
                                filter="url(#scrap-icon-40-c)"
                                href="#scrap-icon-40-b"
                              ></use>
                              <path
                                stroke="#FFF"
                                d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                              ></path>
                            </g>
                          </svg>
                        </Link>
                      ) : (
                        <Link to={"/login"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <defs>
                              <path
                                id="scrap-icon-40-b"
                                d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                              ></path>
                              <filter
                                id="scrap-icon-40-a"
                                width="150%"
                                height="150%"
                                x="-25%"
                                y="-25%"
                                filterUnits="objectBoundingBox"
                              >
                                <feOffset
                                  in="SourceAlpha"
                                  result="shadowOffsetOuter1"
                                ></feOffset>
                                <feGaussianBlur
                                  in="shadowOffsetOuter1"
                                  result="shadowBlurOuter1"
                                  stdDeviation="1.5"
                                ></feGaussianBlur>
                                <feComposite
                                  in="shadowBlurOuter1"
                                  in2="SourceAlpha"
                                  operator="out"
                                  result="shadowBlurOuter1"
                                ></feComposite>
                                <feColorMatrix
                                  in="shadowBlurOuter1"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                ></feColorMatrix>
                              </filter>
                              <filter
                                id="scrap-icon-40-c"
                                width="150%"
                                height="150%"
                                x="-25%"
                                y="-25%"
                                filterUnits="objectBoundingBox"
                              >
                                <feGaussianBlur
                                  in="SourceAlpha"
                                  result="shadowBlurInner1"
                                  stdDeviation="1.5"
                                ></feGaussianBlur>
                                <feOffset
                                  in="shadowBlurInner1"
                                  result="shadowOffsetInner1"
                                ></feOffset>
                                <feComposite
                                  in="shadowOffsetInner1"
                                  in2="SourceAlpha"
                                  k2="-1"
                                  k3="1"
                                  operator="arithmetic"
                                  result="shadowInnerInner1"
                                ></feComposite>
                                <feColorMatrix
                                  in="shadowInnerInner1"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                ></feColorMatrix>
                              </filter>
                            </defs>
                            <g
                              fill="none"
                              fillRule="nonzero"
                              transform="matrix(1 0 0 -1 0 24)"
                              className="button-link active"
                            >
                              <use
                                fill="#000"
                                filter="url(#scrap-icon-40-a)"
                                href="#scrap-icon-40-b"
                              ></use>
                              <use
                                fill="#fff"
                                fillOpacity="0.5"
                                href="#scrap-icon-40-b"
                              ></use>
                              <use
                                fill="#000"
                                filter="url(#scrap-icon-40-c)"
                                href="#scrap-icon-40-b"
                              ></use>
                              <path
                                stroke="#FFF"
                                d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                              ></path>
                            </g>
                          </svg>
                        </Link>
                      )}
                    </Badge>
                  </div>

                  <Meta
                    title={
                      <Text
                        className="product-title"
                        strong
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {product?.item?.name}
                      </Text>
                    }
                    description={
                      <>
                        <Space>
                          <div>
                            <Text className="discount-percent" strong>
                              {product?.item?.price?.discount}%
                            </Text>
                          </div>
                          <Text className="price">
                            {formatNumberWithCommas(
                              product?.item?.price?.calcprice
                            )}
                            ₮
                          </Text>
                        </Space>
                        <div className="star-ratings">
                          <Space direction="horizontal">
                            <Tag color="geekblue">
                              Үнэлгээ:{" "}
                              {product?.item?.valuationdundaj !== null
                                ? product?.item?.valuationdundaj?.toFixed(1)
                                : 5}
                              <b>
                                {formatNumberWithCommas(
                                  product?.item?.valuation?.length
                                )}
                              </b>
                            </Tag>
                            <Tag icon={<StarOutlined />} color="purple">
                              <b>
                                {product?.item?.wishlistcount !== null
                                  ? product?.item?.wishlistcount?.toFixed(0)
                                  : 1}
                              </b>
                            </Tag>
                          </Space>
                        </div>
                      </>
                    }
                  />
                </>
              ) : (
                <>
                  <div className="image-container">
                    <Link to={`/product/${product?.item?.id}`}>
                      {product?.item?.images !== undefined &&
                      product?.item?.images !== null &&
                      product?.item?.images?.length > 0 ? (
                        <Image
                          src={`${getBaseURL()}/api/file/download?ID=${
                            product?.item?.images[0]?.id
                          }&size=180`}
                          alt="product"
                          className="product-image"
                          preview={false}
                        />
                      ) : (
                        <Image
                          src="https://via.placeholder.com/100" // Placeholder image URL
                          alt="No Image"
                          className="product-image"
                          preview={false}
                        />
                      )}
                    </Link>

                    {product?.item?.price?.discountend !== null ? (
                      <div className="sale-timer">
                        <Countdown
                          value={product?.item?.price.discountend}
                          format="Хямдрал: H[ц] m[м] s[с]"
                        />
                      </div>
                    ) : null}
                    <div
                      className={`favorite-button ${
                        state?.favoriteItems?.find(
                          (favorite) => favorite.itemid === product?.item?.id
                        )
                          ? "active"
                          : ""
                      }`}
                    >
                      <Badge>
                        {userinfo.userid ? (
                          <Link onClick={() => handleClick(product)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <defs>
                                <path
                                  id="scrap-icon-40-b"
                                  d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                                ></path>
                                <filter
                                  id="scrap-icon-40-a"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feOffset
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                  ></feOffset>
                                  <feGaussianBlur
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feComposite
                                    in="shadowBlurOuter1"
                                    in2="SourceAlpha"
                                    operator="out"
                                    result="shadowBlurOuter1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowBlurOuter1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                  ></feColorMatrix>
                                </filter>
                                <filter
                                  id="scrap-icon-40-c"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feGaussianBlur
                                    in="SourceAlpha"
                                    result="shadowBlurInner1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feOffset
                                    in="shadowBlurInner1"
                                    result="shadowOffsetInner1"
                                  ></feOffset>
                                  <feComposite
                                    in="shadowOffsetInner1"
                                    in2="SourceAlpha"
                                    k2="-1"
                                    k3="1"
                                    operator="arithmetic"
                                    result="shadowInnerInner1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowInnerInner1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                  ></feColorMatrix>
                                </filter>
                              </defs>
                              <g
                                fill="none"
                                fill-rule="nonzero"
                                transform="matrix(1 0 0 -1 0 24)"
                                className={`
                          "button-link active"
                          
                        }`}
                              >
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-a)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#fff"
                                  fillOpacity="0.5"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-c)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <path
                                  stroke="#FFF"
                                  d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                                ></path>
                              </g>
                            </svg>
                          </Link>
                        ) : (
                          <Link to={"/login"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <defs>
                                <path
                                  id="scrap-icon-40-b"
                                  d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                                ></path>
                                <filter
                                  id="scrap-icon-40-a"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feOffset
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                  ></feOffset>
                                  <feGaussianBlur
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feComposite
                                    in="shadowBlurOuter1"
                                    in2="SourceAlpha"
                                    operator="out"
                                    result="shadowBlurOuter1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowBlurOuter1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                  ></feColorMatrix>
                                </filter>
                                <filter
                                  id="scrap-icon-40-c"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feGaussianBlur
                                    in="SourceAlpha"
                                    result="shadowBlurInner1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feOffset
                                    in="shadowBlurInner1"
                                    result="shadowOffsetInner1"
                                  ></feOffset>
                                  <feComposite
                                    in="shadowOffsetInner1"
                                    in2="SourceAlpha"
                                    k2="-1"
                                    k3="1"
                                    operator="arithmetic"
                                    result="shadowInnerInner1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowInnerInner1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                  ></feColorMatrix>
                                </filter>
                              </defs>
                              <g
                                fill="none"
                                fill-rule="nonzero"
                                transform="matrix(1 0 0 -1 0 24)"
                                className={`
                          "button-link active"
                          
                        }`}
                              >
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-a)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#fff"
                                  fillOpacity="0.5"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-c)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <path
                                  stroke="#FFF"
                                  d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                                ></path>
                              </g>
                            </svg>
                          </Link>
                        )}
                      </Badge>
                    </div>
                  </div>
                  <Meta
                    title={
                      <Text
                        className="product-title"
                        strong
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {product?.item?.name}
                      </Text>
                    }
                    description={
                      <>
                        <Space>
                          <div>
                            <Text className="discount-percent" strong>
                              {product?.item?.price?.discount}%
                            </Text>
                          </div>
                          <Text className="price">
                            {formatNumberWithCommas(
                              product?.item?.price?.calcprice
                            )}
                            ₮
                          </Text>
                        </Space>

                        <Space className="f9 ff">
                          <Tag color="geekblue" className="f9">
                            Үнэлгээ:
                            <b>
                              {product?.item?.valuationdundaj !== null
                                ? product?.item?.valuationdundaj?.toFixed(1)
                                : 5}
                            </b>
                          </Tag>
                          <Tag
                            icon={<StarOutlined />}
                            color="purple"
                            className="f9"
                          >
                            <b>
                              {product?.item?.wishlistcount !== null
                                ? product?.item?.wishlistcount?.toFixed(0)
                                : 1}
                            </b>
                          </Tag>
                        </Space>
                      </>
                    }
                  />
                </>
              )}
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
}
