import React, { useState, useEffect } from "react";
import { Card, Form, Input, Button, message } from "antd";
import { api } from "../../system/api";

const UpdatePassword = ({ griddata, userid }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const onFinish = async (values) => {
    try {
      const response = await api.post(
        "/api/systems/User/update_password",
        values
      );
      if (response.status === 200) {
        message.success("Нууц үг амжилттай шинэчлэгдлээ.");
      } else {
        message.error("Нууц үгийг шинэчлэхэд алдаа гарлаа.");
      }
    } catch (error) {
      message.error("Нууц үгийг шинэчлэхэд алдаа гарлаа.");
    }
  };

  return (
    <Card
      className="user-editing-card"
      style={{
        width: isMobile ? "90%" : "50%",
        padding: 20,
        marginBottom: 100,
      }}
    >
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <h2>Нууц үг солих</h2>
        <p>
          Үсэг, тоо зэрэг дор хаяж 8 тэмдэгтээс бүрдэх нууц үгээ оруулна уу.
        </p>
      </div>
      <Form
        name="update-password-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        initialValues={{
          userid: griddata?.userid,
        }}
        onFinish={onFinish}
      >
        <Form.Item name="userid" label="Дугаар" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Шинэ нууц үг"
          name="password"
          rules={[
            {
              required: true,
              message: "Шинэ нууц үг оруулна уу!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Шинэ нууц үг давтах"
          name="encryptpass"
          rules={[
            {
              required: true,
              message: "Шинэ нууц үг оруулна уу!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 0, span: 24 }}
          style={{ paddingTop: 30 }}
        >
          <Button type="primary" htmlType="submit" size="large" block>
            Нууц үг шинэчлэх
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default UpdatePassword;
