import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Flex } from "antd";

const Countdown = ({ value }) => {
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    let timer;

    const calculateRemainingTime = () => {
      const currentTime = dayjs();
      const valueTime = dayjs(value, "HH:mm:ss");
      const diffSeconds = valueTime.diff(currentTime, "second"); // Calculate difference in seconds

      if (diffSeconds <= 0) {
        clearInterval(timer);
      } else {
        setRemainingTime(diffSeconds);
      }
    };

    // Calculate remaining time initially
    calculateRemainingTime();

    // Set up interval to update remaining time every second
    timer = setInterval(calculateRemainingTime, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(timer);
  }, [value]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes} мин ${seconds} сек`;
  };

  return (
    <Flex vertical>
      <span>Төлбөр төлөх хугацаа:</span> <b>{formatTime(remainingTime)}</b>
    </Flex>
  );
};

export default Countdown;
