import React, { useCallback, useEffect, useState } from "react";
import { Badge, Card, List, Image } from "antd";
import { api } from "../../system/api";
import { getBaseURL } from "../../system/apiconfig";
import "./style.css"; // Import your custom CSS for styling
import { Link } from "react-router-dom";

export default function ProductList() {
  const [loading, setLoading] = useState(true);
  const [griddata, setGridData] = useState(null);
  const [isMobile] = useState(window?.innerWidth <= 768);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/api/Product/get_Tag_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.rettype === 0) {
          setGridData(res?.data?.retdata);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="card-list">
      <List
        dataSource={griddata || []}
        grid={{
          gutter: 16,
          column: isMobile ? 5 : 10,
        }}
        renderItem={(image) => (
          <List.Item>
            <Link to={`/tag/${image?.id}`}>
              <Badge
                count={
                  image?.subname !== "" ? (
                    <div className="hot-sale-text">{image?.subname}</div>
                  ) : (
                    ""
                  )
                }
              >
                <Card
                  key={image?.id}
                  cover={
                    <Image
                      src={`${getBaseURL()}/api/file/download?ID=${
                        image?.image
                      }&size=100`}
                      preview={false}
                    />
                  }
                  className="card"
                ></Card>
                {image?.name}
              </Badge>
            </Link>
          </List.Item>
        )}
      />
    </div>
  );
}
