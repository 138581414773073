import React, { createContext, useContext, useEffect, useState } from "react";
import { api } from "../system/api";
import useUserInfo from "../system/useUserInfo";
import { message } from "antd";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { userinfo } = useUserInfo();

  const initialState = {
    shoppingCart: [],
    userid: userinfo?.userid,
    userImage: "",
    userpoint: 0,
    loading: false,
    error: null,
    favoriteItems: [],
    saving: false,
    finished: false,
    orderState: {
      id: 0,
      userid: 0,
      orderdate: "2024-01-30T05:21:45.509Z",
      status: 0,
      totalprice: 0,
      userAddress: {
        id: 0,
        userid: userinfo?.userid,
        firstname: null, // Add the default values you want for the userAddress
        lastname: null,
        phone: null,
        phone2: null,
        regno: null,
        province: null,
        district: null,
        committee: null,
        address: null,
      },
    },
    orderDetials: [
      {
        id: 0,
        itemid: 0,
        quantity: 0,
        unitprice: 0,
        totalprice: 0,
        item: "string",
      },
    ],
    orderStatus: [
      {
        id: 0,
        orderid: 0,
        status: 0,
        statusdate: null,
        ownerUserid: 0,
      },
    ],
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const fetchData = async () => {
      if (userinfo?.userid) {
        await fetchUser();
        await loadWishes();
        await loadShoppingCart();
      }
    };

    fetchData();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await api.get(
        `/api/systems/User/get_user?userid=${state.userid}`
      );
      if (response.status === 200) {
        const imagesArray = response?.data?.retdata?.images;
        const userImage =
          imagesArray && imagesArray.length > 0 ? imagesArray[0] : null;
        setState((prevState) => ({
          ...prevState,
          userImage: userImage,
          userpoint: response?.data?.retdata?.userpoint,
        }));
      }
    } catch (error) {
      console.error("Error fetching user image:", error);
    }
  };

  const loadWishes = async () => {
    if (userinfo.userid) {
      try {
        const res = await api.get(
          `/api/Product/get_wishlists?userid=${userinfo?.userid}`
        );
        if (res?.status === 200 && res?.data?.rettype === 0) {
          setState((prevState) => ({
            ...prevState,
            favoriteItems: res?.data?.retdata,
          }));
        }
      } catch (error) {
        setState({ ...state, saving: false, finished: true, error: error });
        console.error("Error fetching favorites:", error);
      }
    }
  };

  const setFavorites = async (favorites) => {
    try {
      const res = await api.post("/api/Product/set_wishlists", [
        {
          itemid: favorites?.itemid,
          userid: favorites?.userid,
        },
      ]);
      const id = res.data.retdata[0].id;

      const updatedFavoriteItem = {
        id: id,
        itemid: favorites?.itemid,
        userid: favorites?.userid,
        item: favorites?.item,
      };

      // Update local state only after successful API call
      setState((prevState) => {
        const favoriteItems = Array.isArray(prevState.favoriteItems)
          ? prevState.favoriteItems
          : [];
        return {
          ...prevState,
          saving: false,
          favoriteItems: [...favoriteItems, updatedFavoriteItem],
        };
      });

      message.success("Хүслийн жагсаалтад нэмэгдлээ.");
    } catch (error) {
      // Handle error if the API call fails
      console.error("Error setting favorites:", error);
      setState((prevState) => ({ ...prevState, saving: false }));
    }
  };

  const removeFromFavorites = async (itemid) => {
    try {
      const res = await api.delete(
        `/api/Product/delete_wishlists?id=${itemid}`
      );

      if (res?.status === 200 && res?.data?.rettype === 0) {
        setState((prevState) => ({
          ...prevState,
          saving: true,
          favoriteItems: prevState.favoriteItems.filter(
            (favorite) => favorite.id !== itemid
          ),
        }));
        message.success("Хүслийн жагсаалтаас устгалаа.");
      } else {
        console.error("Failed to remove from favorites:", res?.data?.retdesc);
      }
    } catch (error) {
      console.error("Error removing from favorites:", error);
    }
  };

  const loadShoppingCart = async () => {
    if (userinfo?.userid) {
      try {
        const res = await api.get(
          `/api/Product/get_shoppingcart?userid=${userinfo?.userid}`
        );

        if (res?.status === 200 && res?.data?.rettype === 0) {
          const shoppingCartData = res?.data?.retdata;

          // Check if shoppingCartData is an array and has at least one item
          if (Array.isArray(shoppingCartData) && shoppingCartData.length > 0) {
            // Calculate total price and order details for all items in the shopping cart
            let totalPrice = 0;
            const orderDetails = [];

            shoppingCartData.forEach((category) => {
              category.children.forEach((item) => {
                const itemTotalPrice = item.price.calcprice * item.quantity;
                totalPrice += itemTotalPrice;

                orderDetails.push({
                  itemid: item.itemid,
                  quantity: item.quantity,
                  unitprice: item.price.calcprice,
                  totalprice: itemTotalPrice,
                });
              });
            });

            setState((prevState) => ({
              ...prevState,
              shoppingCart: shoppingCartData,
              orderState: {
                ...prevState.orderState,
                totalprice: totalPrice,
                orderDetails: orderDetails,
              },
            }));
          }
        }
      } catch (error) {
        setState({
          ...state,
          saving: false,
          finished: true,
          error: error,
        });
        console.error("Error fetching shopping cart:", error);
      }
    }
  };

  const setShoppingCart = async (cartItems) => {
    if (cartItems?.length > 0) {
      try {
        const array = cartItems.map((item) => ({
          itemid: item?.itemid || item?.id,
          userid: item?.userid,
          quantity: item?.quantity,
        }));
        const res = await api.post("/api/Product/set_shoppingcart", array);
        if (res?.status === 200 && res?.data?.rettype === 0) {
          message.success("Амжилттай сагсанд нэмлээ.");
          loadShoppingCart(); // Reload shopping cart data after making changes
        } else {
          console.error("Failed to update shopping cart:", res?.data?.retdata);
        }
      } catch (error) {
        console.error("Error updating shopping cart:", error);
      }
    }
  };

  const updateShoppingCart = async (cartItems) => {
    if (cartItems?.length > 0) {
      try {
        const itemsToUpdate = [];

        // Iterate over each item in cartItems
        cartItems.forEach((item) => {
          // If the item has children, add them to the itemsToUpdate array
          if (item.children && item.children.length > 0) {
            item.children.forEach((child) => {
              itemsToUpdate.push({
                id: child.shoppingcartid,
                itemid: child.itemid,
                userid: userinfo?.userid,
                quantity: child.quantity,
              });
            });
          } else {
            // If the item doesn't have children, add it directly to the itemsToUpdate array
            itemsToUpdate.push({
              id: item.shoppingcartid,
              itemid: item.itemid,
              userid: userinfo?.userid,
              quantity: item.quantity,
            });
          }
        });

        // Send the itemsToUpdate array to the server
        const res = await api.post(
          "/api/Product/set_shoppingcart",
          itemsToUpdate
        );

        if (res?.status === 200 && res?.data?.rettype === 0) {
          loadShoppingCart(); // Reload shopping cart data after making changes
          message.success("Таны сагсан дах мэдээлэл шинэчлэгдээ.");
        } else {
          console.error("Failed to update shopping cart:", res?.data?.retdata);
        }
      } catch (error) {
        console.error("Error updating shopping cart:", error);
      }
    }
  };

  const updateOrderState = (newOrderState) => {
    setState((prevState) => ({
      ...prevState,
      orderState: newOrderState,
    }));
  };

  const updateUserAddress = (newUserAddress) => {
    setState((prevState) => ({
      ...prevState,
      orderState: {
        ...prevState.orderState,
        userAddress: {
          ...prevState.orderState.userAddress,
          ...newUserAddress,
        },
      },
    }));
  };

  const removeFromCart = async (itemIds) => {
    try {
      if (Array.isArray(itemIds) && itemIds?.length > 0) {
        try {
          // Delete items from the cart
          await Promise.all(
            itemIds.map(async (item) => {
              try {
                const response = await api.delete(
                  `/api/Product/delete_shoppingcart?id=${item?.shoppingcartid}`
                );
                if (response?.status !== 200) {
                  console.error("Failed to remove item from cart:", response);
                }
              } catch (error) {
                console.error("Error deleting item from cart:", error);
              }
            })
          );

          // Update the shopping cart state after deletion
          const updatedCart = state.shoppingCart?.filter(
            (item) =>
              !itemIds.some(
                ({ shoppingcartid }) => item.shoppingcartid === shoppingcartid
              )
          );
          setState((prevState) => ({
            ...prevState,
            shoppingCart: updatedCart,
          }));

          // Show success message
          message.success("Амжилттай хаслаа");
        } catch (error) {
          console.error("Error deleting items from cart:", error);
        }
      } else if (typeof itemIds === "number") {
        const response = await api.delete(
          `/api/Product/delete_shoppingcart?id=${itemIds}`
        );

        if (response.status === 200) {
          const updatedCart = state.shoppingCart?.filter(
            (item) => item.itemid !== itemIds
          );
          setState((prevState) => ({
            ...prevState,
            shoppingCart: updatedCart,
          }));
          message.success("Амжилттай хаслаа");
        } else {
          console.error("Failed to remove item from cart:", response);
        }
      }
    } catch (error) {
      console.error("Error removing item(s) from cart:", error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        state,
        setFavorites,
        removeFromFavorites,
        loadWishes,
        setShoppingCart,
        loadShoppingCart,
        updateShoppingCart,
        updateOrderState,
        updateUserAddress,
        removeFromCart,
        fetchUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export { AppProvider, useAppContext };
