import React, { useState, useEffect } from "react";
import {
  Card,
  Badge,
  Typography,
  Space,
  Image,
  Dropdown,
  List,
  Tag,
  Menu,
  Flex,
} from "antd";
import Countdown from "antd/lib/statistic/Countdown";
import "./style.css";
import { Link } from "react-router-dom";
import { DownOutlined, StarOutlined, ShopOutlined } from "@ant-design/icons";
import { getBaseURL } from "../../system/apiconfig";
import useUserInfo from "../../system/useUserInfo";
import { useAppContext } from "../../Context/index";
import { api } from "../../system/api";

const { Meta } = Card;
const { Text } = Typography;

export default function HotSectionComp() {
  const { userinfo } = useUserInfo();
  const [loading, setLoading] = useState(true);
  const { state, setFavorites, removeFromFavorites } = useAppContext();
  const [griddata, setGridData] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dropNum, setDropNum] = useState("1");

  const items = [
    {
      key: "1",
      label: "Борлуулалтаар",
    },
    {
      key: "2",
      label: "Хамгийн их хадгалагдсан бараа",
    },
    {
      key: "3",
      label: "Хамгийн сүүлд нэмэгдсэн бараа",
    },
  ];

  const [selectedLabel, setSelectedLabel] = useState(
    items.find((item) => item.key === dropNum)?.label || ""
  );

  const formatNumberWithCommas = (number) => {
    return number?.toLocaleString();
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/api/Product/get_Item_list?isparent=true&sorttype=${dropNum}`
      );
      setGridData(response?.data?.retdata);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dropNum]);

  const handleClick = async (item) => {
    const isFavorite = state?.favoriteItems?.some(
      (favorite) => favorite.itemid === item.id
    );
    try {
      if (isFavorite) {
        const favoriteItem = state?.favoriteItems?.find(
          (favorite) => favorite?.itemid === item.id
        );

        // Check if favoriteItem exists before trying to access its properties
        if (favoriteItem) {
          await removeFromFavorites(favoriteItem.id);
        } else {
          console.error("Favorite item not found for item:", item);
        }
      } else {
        const favoriteItems = {
          itemid: item?.id,
          userid: userinfo?.userid,
          item: item?.children,
        };

        await setFavorites(favoriteItems);
      }
    } catch (error) {
      console.error("Error in handleClick:", error);
    }
  };

  const handleDropdownChange = ({ key }) => {
    const selectedLabel = items?.find((item) => item.key === key)?.label || "";
    setDropNum(key);
    setSelectedLabel(selectedLabel);
  };

  const menu = (
    <Menu onClick={handleDropdownChange}>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Space direction="horizontal" className="h-style">
        <h2>ЭРЭЛТТЭЙ БАРАА</h2>

        <Dropdown overlay={menu}>
          <Link
            className="ant-dropdown-link"
            to="#"
            onClick={(e) => e.preventDefault()}
          >
            {selectedLabel} <DownOutlined />
          </Link>
        </Dropdown>
      </Space>

      <List
        grid={{ gutter: !isMobile ? 16 : 0, column: !isMobile ? 4 : 2 }}
        dataSource={griddata ?? []}
        loading={loading}
        renderItem={(product, index) => (
          <List.Item key={index}>
            <Card
              className={`crt ${isMobile ? "mobile-card" : ""}`}
              key={index}
              style={{ border: 0 }}
            >
              {!isMobile ? (
                <>
                  <div className="image-container">
                    <Link to={`/product/${product?.id}`}>
                      {product?.images !== undefined &&
                      product?.images !== null &&
                      product?.images?.length > 0 ? (
                        <Image
                          src={`${getBaseURL()}/api/file/download?ID=${
                            product?.images[0]?.id
                          }&size=180`}
                          alt="product"
                          className="product-image"
                          preview={false}
                        />
                      ) : (
                        <Image
                          src="https://via.placeholder.com/100" // Placeholder image URL
                          alt="No Image"
                          className="product-image"
                          preview={false}
                        />
                      )}
                    </Link>
                  </div>
                  {product?.price?.discountend !== null ? (
                    <div className="sale-timer">
                      <Countdown
                        value={product?.price.discountend}
                        format="Хямдрал: H[ц] m[м] s[с]"
                      />
                    </div>
                  ) : null}
                  <div
                    className={`favorite-button ${
                      state?.favoriteItems?.find(
                        (favorite) => favorite?.itemid === product?.id
                      )
                        ? "active"
                        : ""
                    }`}
                  >
                    <Badge>
                      {userinfo?.userid ? (
                        <Link onClick={() => handleClick(product)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <defs>
                              <path
                                id="scrap-icon-40-b"
                                d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                              ></path>
                              <filter
                                id="scrap-icon-40-a"
                                width="150%"
                                height="150%"
                                x="-25%"
                                y="-25%"
                                filterUnits="objectBoundingBox"
                              >
                                <feOffset
                                  in="SourceAlpha"
                                  result="shadowOffsetOuter1"
                                ></feOffset>
                                <feGaussianBlur
                                  in="shadowOffsetOuter1"
                                  result="shadowBlurOuter1"
                                  stdDeviation="1.5"
                                ></feGaussianBlur>
                                <feComposite
                                  in="shadowBlurOuter1"
                                  in2="SourceAlpha"
                                  operator="out"
                                  result="shadowBlurOuter1"
                                ></feComposite>
                                <feColorMatrix
                                  in="shadowBlurOuter1"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                ></feColorMatrix>
                              </filter>
                              <filter
                                id="scrap-icon-40-c"
                                width="150%"
                                height="150%"
                                x="-25%"
                                y="-25%"
                                filterUnits="objectBoundingBox"
                              >
                                <feGaussianBlur
                                  in="SourceAlpha"
                                  result="shadowBlurInner1"
                                  stdDeviation="1.5"
                                ></feGaussianBlur>
                                <feOffset
                                  in="shadowBlurInner1"
                                  result="shadowOffsetInner1"
                                ></feOffset>
                                <feComposite
                                  in="shadowOffsetInner1"
                                  in2="SourceAlpha"
                                  k2="-1"
                                  k3="1"
                                  operator="arithmetic"
                                  result="shadowInnerInner1"
                                ></feComposite>
                                <feColorMatrix
                                  in="shadowInnerInner1"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                ></feColorMatrix>
                              </filter>
                            </defs>
                            <g
                              fill="none"
                              fillRule="nonzero"
                              transform="matrix(1 0 0 -1 0 24)"
                              className="button-link active"
                            >
                              <use
                                fill="#000"
                                filter="url(#scrap-icon-40-a)"
                                href="#scrap-icon-40-b"
                              ></use>
                              <use
                                fill="#fff"
                                fillOpacity="0.5"
                                href="#scrap-icon-40-b"
                              ></use>
                              <use
                                fill="#000"
                                filter="url(#scrap-icon-40-c)"
                                href="#scrap-icon-40-b"
                              ></use>
                              <path
                                stroke="#FFF"
                                d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                              ></path>
                            </g>
                          </svg>
                        </Link>
                      ) : (
                        <Link to={"/login"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <defs>
                              <path
                                id="scrap-icon-40-b"
                                d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                              ></path>
                              <filter
                                id="scrap-icon-40-a"
                                width="150%"
                                height="150%"
                                x="-25%"
                                y="-25%"
                                filterUnits="objectBoundingBox"
                              >
                                <feOffset
                                  in="SourceAlpha"
                                  result="shadowOffsetOuter1"
                                ></feOffset>
                                <feGaussianBlur
                                  in="shadowOffsetOuter1"
                                  result="shadowBlurOuter1"
                                  stdDeviation="1.5"
                                ></feGaussianBlur>
                                <feComposite
                                  in="shadowBlurOuter1"
                                  in2="SourceAlpha"
                                  operator="out"
                                  result="shadowBlurOuter1"
                                ></feComposite>
                                <feColorMatrix
                                  in="shadowBlurOuter1"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                ></feColorMatrix>
                              </filter>
                              <filter
                                id="scrap-icon-40-c"
                                width="150%"
                                height="150%"
                                x="-25%"
                                y="-25%"
                                filterUnits="objectBoundingBox"
                              >
                                <feGaussianBlur
                                  in="SourceAlpha"
                                  result="shadowBlurInner1"
                                  stdDeviation="1.5"
                                ></feGaussianBlur>
                                <feOffset
                                  in="shadowBlurInner1"
                                  result="shadowOffsetInner1"
                                ></feOffset>
                                <feComposite
                                  in="shadowOffsetInner1"
                                  in2="SourceAlpha"
                                  k2="-1"
                                  k3="1"
                                  operator="arithmetic"
                                  result="shadowInnerInner1"
                                ></feComposite>
                                <feColorMatrix
                                  in="shadowInnerInner1"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                ></feColorMatrix>
                              </filter>
                            </defs>
                            <g
                              fill="none"
                              fillRule="nonzero"
                              transform="matrix(1 0 0 -1 0 24)"
                              className="button-link active"
                            >
                              <use
                                fill="#000"
                                filter="url(#scrap-icon-40-a)"
                                href="#scrap-icon-40-b"
                              ></use>
                              <use
                                fill="#fff"
                                fillOpacity="0.5"
                                href="#scrap-icon-40-b"
                              ></use>
                              <use
                                fill="#000"
                                filter="url(#scrap-icon-40-c)"
                                href="#scrap-icon-40-b"
                              ></use>
                              <path
                                stroke="#FFF"
                                d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                              ></path>
                            </g>
                          </svg>
                        </Link>
                      )}
                    </Badge>
                  </div>

                  <Meta
                    title={
                      <Text
                        className="product-title"
                        strong
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {product?.name?.length > 60
                          ? product?.name?.substring(0, 60) + "..."
                          : product?.name}
                      </Text>
                    }
                    description={
                      <>
                        <Space>
                          {product?.price?.discount !== 0 && (
                            <div>
                              <Text className="discount-percent" strong>
                                {product?.price?.discount}%
                              </Text>
                            </div>
                          )}
                          <Text className="price">
                            {formatNumberWithCommas(product?.price?.calcprice)}₮
                          </Text>
                        </Space>
                        <div className="star-ratings">
                          <Flex
                            direction="horizontal"
                            gap={0}
                            style={{ marginTop: 5 }}
                          >
                            <Tag color="geekblue">
                              Үнэлгээ:{" "}
                              {product?.valuationdundaj !== null
                                ? product?.valuationdundaj?.toFixed(1)
                                : 5}
                              <b>
                                {formatNumberWithCommas(
                                  product?.valuation?.length
                                )}
                              </b>
                            </Tag>
                            <Tag icon={<StarOutlined />} color="purple">
                              <b>
                                {product?.wishlistcount !== null
                                  ? product?.wishlistcount?.toFixed(0)
                                  : 1}
                              </b>
                            </Tag>
                            <Tag icon={<ShopOutlined />} color="volcano">
                              <b>
                                {product?.salecount !== null
                                  ? product?.salecount?.toFixed(0)
                                  : 1}
                              </b>
                            </Tag>
                          </Flex>
                        </div>
                      </>
                    }
                  />
                </>
              ) : (
                <>
                  <div className="image-container">
                    <Link to={`/product/${product?.id}`}>
                      {product?.images !== undefined &&
                      product?.images !== null &&
                      product?.images?.length > 0 ? (
                        <Image
                          src={`${getBaseURL()}/api/file/download?ID=${
                            product?.images[0]?.id
                          }&size=180`}
                          alt="product"
                          className="product-image"
                          preview={false}
                        />
                      ) : (
                        <Image
                          src="https://via.placeholder.com/100" // Placeholder image URL
                          alt="No Image"
                          className="product-image"
                          preview={false}
                        />
                      )}
                    </Link>

                    {product?.price?.discountend !== null ? (
                      <div className="sale-timer">
                        <Countdown
                          value={product?.price.discountend}
                          format="Хямдрал: H[ц] m[м] s[с]"
                        />
                      </div>
                    ) : null}
                    <div
                      className={`favorite-button ${
                        state?.favoriteItems?.find(
                          (favorite) => favorite.itemid === product?.id
                        )
                          ? "active"
                          : ""
                      }`}
                    >
                      <Badge>
                        {userinfo.userid ? (
                          <Link onClick={() => handleClick(product)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <defs>
                                <path
                                  id="scrap-icon-40-b"
                                  d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                                ></path>
                                <filter
                                  id="scrap-icon-40-a"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feOffset
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                  ></feOffset>
                                  <feGaussianBlur
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feComposite
                                    in="shadowBlurOuter1"
                                    in2="SourceAlpha"
                                    operator="out"
                                    result="shadowBlurOuter1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowBlurOuter1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                  ></feColorMatrix>
                                </filter>
                                <filter
                                  id="scrap-icon-40-c"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feGaussianBlur
                                    in="SourceAlpha"
                                    result="shadowBlurInner1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feOffset
                                    in="shadowBlurInner1"
                                    result="shadowOffsetInner1"
                                  ></feOffset>
                                  <feComposite
                                    in="shadowOffsetInner1"
                                    in2="SourceAlpha"
                                    k2="-1"
                                    k3="1"
                                    operator="arithmetic"
                                    result="shadowInnerInner1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowInnerInner1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                  ></feColorMatrix>
                                </filter>
                              </defs>
                              <g
                                fill="none"
                                fill-rule="nonzero"
                                transform="matrix(1 0 0 -1 0 24)"
                                className={`
                          "button-link active"
                          
                        }`}
                              >
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-a)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#fff"
                                  fillOpacity="0.5"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-c)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <path
                                  stroke="#FFF"
                                  d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                                ></path>
                              </g>
                            </svg>
                          </Link>
                        ) : (
                          <Link to={"/login"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <defs>
                                <path
                                  id="scrap-icon-40-b"
                                  d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                                ></path>
                                <filter
                                  id="scrap-icon-40-a"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feOffset
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                  ></feOffset>
                                  <feGaussianBlur
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feComposite
                                    in="shadowBlurOuter1"
                                    in2="SourceAlpha"
                                    operator="out"
                                    result="shadowBlurOuter1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowBlurOuter1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                  ></feColorMatrix>
                                </filter>
                                <filter
                                  id="scrap-icon-40-c"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feGaussianBlur
                                    in="SourceAlpha"
                                    result="shadowBlurInner1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feOffset
                                    in="shadowBlurInner1"
                                    result="shadowOffsetInner1"
                                  ></feOffset>
                                  <feComposite
                                    in="shadowOffsetInner1"
                                    in2="SourceAlpha"
                                    k2="-1"
                                    k3="1"
                                    operator="arithmetic"
                                    result="shadowInnerInner1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowInnerInner1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                  ></feColorMatrix>
                                </filter>
                              </defs>
                              <g
                                fill="none"
                                fill-rule="nonzero"
                                transform="matrix(1 0 0 -1 0 24)"
                                className={`
                          "button-link active"
                          
                        }`}
                              >
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-a)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#fff"
                                  fillOpacity="0.5"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-c)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <path
                                  stroke="#FFF"
                                  d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                                ></path>
                              </g>
                            </svg>
                          </Link>
                        )}
                      </Badge>
                    </div>
                  </div>
                  <Meta
                    title={
                      <Text
                        className="product-title"
                        strong
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {product?.name?.length > 50
                          ? product?.name?.substring(0, 50) + "..."
                          : product?.name}
                      </Text>
                    }
                    description={
                      <>
                        <Space>
                          {product?.price?.discount !== 0 && (
                            <div>
                              <Text className="discount-percent" strong>
                                {product?.price?.discount}%
                              </Text>
                            </div>
                          )}
                          <Text className="price">
                            {formatNumberWithCommas(product?.price?.calcprice)}₮
                          </Text>
                        </Space>

                        <Flex className="f9 ff">
                          <Tag color="geekblue" className="f9">
                            Үнэлгээ:
                            <b>
                              {product?.valuationdundaj !== null
                                ? product?.valuationdundaj?.toFixed(1)
                                : 5}
                            </b>
                          </Tag>
                          <Tag
                            icon={<StarOutlined />}
                            color="purple"
                            className="f9"
                          >
                            <b>
                              {product?.wishlistcount !== null
                                ? product?.wishlistcount?.toFixed(0)
                                : 1}
                            </b>
                          </Tag>
                          <Tag
                            icon={<ShopOutlined />}
                            color="volcano"
                            className="f9"
                          >
                            <b>
                              {product?.salecount !== null
                                ? product?.salecount?.toFixed(0)
                                : 1}
                            </b>
                          </Tag>
                        </Flex>
                      </>
                    }
                  />
                </>
              )}
            </Card>
          </List.Item>
        )}
      />
    </>
  );
}
