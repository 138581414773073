import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Menu,
  Badge,
  Typography,
  Rate,
  Space,
  Image,
  Breadcrumb,
  message,
  Anchor,
  List,
} from "antd";
import { api } from "../../system/api";
import Countdown from "antd/lib/statistic/Countdown";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getBaseURL } from "../../system/apiconfig";
import { HomeOutlined } from "@ant-design/icons";
import useUserInfo from "../../system/useUserInfo";
import "./style.css";
import { useAppContext } from "../../Context/index";
const { Meta } = Card;
const { Text } = Typography;

export default function Tag() {
  const { id } = useParams();
  const { userinfo } = useUserInfo();
  const [griddata, setGridData] = useState();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { state, setFavorites, removeFromFavorites, loadWishes } =
    useAppContext();

  const fetchData = useCallback(async (categoryId) => {
    setLoading(true);
    try {
      const response = await api.get(`/api/Product/get_Tag?id=${categoryId}`);
      setGridData(response?.data?.retdata);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchData(id);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [fetchData, state.favoriteItems]);

  useEffect(() => {
    fetchData(id);
  }, [userinfo, id]);

  const formatNumberWithCommas = (number) => {
    return number?.toLocaleString();
  };
  const handleClick = async (item) => {
    const isFavorite = state?.favoriteItems?.some(
      (favorite) => favorite.itemid === item.id
    );
    try {
      if (isFavorite) {
        const favoriteItem = state?.favoriteItems?.find(
          (favorite) => favorite?.itemid === item.id
        );

        // Check if favoriteItem exists before trying to access its properties
        if (favoriteItem) {
          await removeFromFavorites(favoriteItem.id);
        } else {
          console.error("Favorite item not found for item:", item);
        }
      } else {
        const favoriteItems = {
          itemid: item.id,
          userid: userinfo.userid,
        };

        await setFavorites(favoriteItems);
      }
    } catch (error) {
      console.error("Error in handleClick:", error);
    }
  };

  return (
    <div className="tag-op" style={{ maxWidth: 1200, margin: "0 auto" }}>
      <h3>{griddata?.name}</h3>
      <div>
        <List
          grid={{ gutter: !isMobile ? 16 : 0, column: !isMobile ? 4 : 2 }}
          dataSource={
            griddata && griddata?.item ? Object?.entries(griddata?.item) : []
          }
          loading={loading}
          renderItem={(product, index) => (
            <List.Item key={index}>
              <Card
                className={`crt ${isMobile ? "mobile-card" : ""}`}
                key={index}
                style={{ border: 0 }}
              >
                {!isMobile ? (
                  <>
                    <div className="image-container">
                      <Link to={`/product/${product[1]?.id}`}>
                        {product[1]?.images !== undefined ? (
                          <Image
                            src={`${getBaseURL()}/api/file/download?ID=${
                              product[1]?.images[0]?.id
                            }&size=180`}
                            alt="product"
                            className="product-image"
                            preview={false}
                          />
                        ) : (
                          <Image
                            src="https://via.placeholder.com/100" // Placeholder image URL
                            alt="No Image"
                            className="product-image"
                            preview={false}
                          />
                        )}
                      </Link>
                    </div>
                    {product?.price?.discountend !== null ? (
                      <div className="sale-timer">
                        <Countdown
                          value={product?.price?.discountend}
                          format="Хямдрал: H[ц] m[м] s[с]"
                        />
                      </div>
                    ) : null}
                    <div
                      className={`favorite-button ${
                        state?.favoriteItems?.find(
                          (favorite) => favorite.itemid === product?.id
                        )
                          ? "active"
                          : ""
                      }`}
                    >
                      <Badge>
                        {userinfo.userid ? (
                          <Link onClick={() => handleClick(product)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <defs>
                                <path
                                  id="scrap-icon-40-b"
                                  d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                                ></path>
                                <filter
                                  id="scrap-icon-40-a"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feOffset
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                  ></feOffset>
                                  <feGaussianBlur
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feComposite
                                    in="shadowBlurOuter1"
                                    in2="SourceAlpha"
                                    operator="out"
                                    result="shadowBlurOuter1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowBlurOuter1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                  ></feColorMatrix>
                                </filter>
                                <filter
                                  id="scrap-icon-40-c"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feGaussianBlur
                                    in="SourceAlpha"
                                    result="shadowBlurInner1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feOffset
                                    in="shadowBlurInner1"
                                    result="shadowOffsetInner1"
                                  ></feOffset>
                                  <feComposite
                                    in="shadowOffsetInner1"
                                    in2="SourceAlpha"
                                    k2="-1"
                                    k3="1"
                                    operator="arithmetic"
                                    result="shadowInnerInner1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowInnerInner1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                  ></feColorMatrix>
                                </filter>
                              </defs>
                              <g
                                fill="none"
                                fill-rule="nonzero"
                                transform="matrix(1 0 0 -1 0 24)"
                                className={`
                          "button-link active"
                          
                        }`}
                              >
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-a)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#fff"
                                  fillOpacity="0.5"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-c)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <path
                                  stroke="#FFF"
                                  d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                                ></path>
                              </g>
                            </svg>
                          </Link>
                        ) : (
                          <Link to={"/login"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <defs>
                                <path
                                  id="scrap-icon-40-b"
                                  d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                                ></path>
                                <filter
                                  id="scrap-icon-40-a"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feOffset
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                  ></feOffset>
                                  <feGaussianBlur
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feComposite
                                    in="shadowBlurOuter1"
                                    in2="SourceAlpha"
                                    operator="out"
                                    result="shadowBlurOuter1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowBlurOuter1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                  ></feColorMatrix>
                                </filter>
                                <filter
                                  id="scrap-icon-40-c"
                                  width="150%"
                                  height="150%"
                                  x="-25%"
                                  y="-25%"
                                  filterUnits="objectBoundingBox"
                                >
                                  <feGaussianBlur
                                    in="SourceAlpha"
                                    result="shadowBlurInner1"
                                    stdDeviation="1.5"
                                  ></feGaussianBlur>
                                  <feOffset
                                    in="shadowBlurInner1"
                                    result="shadowOffsetInner1"
                                  ></feOffset>
                                  <feComposite
                                    in="shadowOffsetInner1"
                                    in2="SourceAlpha"
                                    k2="-1"
                                    k3="1"
                                    operator="arithmetic"
                                    result="shadowInnerInner1"
                                  ></feComposite>
                                  <feColorMatrix
                                    in="shadowInnerInner1"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                  ></feColorMatrix>
                                </filter>
                              </defs>
                              <g
                                fill="none"
                                fill-rule="nonzero"
                                transform="matrix(1 0 0 -1 0 24)"
                                className={`
                          "button-link active"
                          
                        }`}
                              >
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-a)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#fff"
                                  fillOpacity="0.5"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <use
                                  fill="#000"
                                  filter="url(#scrap-icon-40-c)"
                                  href="#scrap-icon-40-b"
                                ></use>
                                <path
                                  stroke="#FFF"
                                  d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                                ></path>
                              </g>
                            </svg>
                          </Link>
                        )}
                      </Badge>
                    </div>

                    <Meta
                      title={
                        <Text
                          className="product-title"
                          strong
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          {product[1]?.name}
                        </Text>
                      }
                      description={
                        <>
                          <Space>
                            <div>
                              <Text className="discount-percent" strong>
                                {product[1]?.price?.discount}%
                              </Text>
                            </div>
                            <Text className="price">
                              {formatNumberWithCommas(
                                product[1]?.price?.calcprice
                              )}
                              ₮
                            </Text>
                          </Space>
                          {product[1]?.valuation !== undefined &&
                          product[1]?.valuation !== null ? (
                            <div className="star-ratings">
                              <Space direction="horizontal">
                                <div>
                                  <Rate
                                    allowHalf
                                    defaultValue={1}
                                    count={1}
                                    disabled
                                    style={{ fontSize: 18 }}
                                  />
                                  <Text className="star-rating-number">
                                    {product[1]?.valuation?.length?.toFixed(1)}
                                  </Text>
                                </div>
                                <Text className="order-number" type="secondary">
                                  Үнэлгээ:
                                  {formatNumberWithCommas(
                                    product[1]?.valuation?.length
                                  )}
                                </Text>
                              </Space>
                            </div>
                          ) : null}
                        </>
                      }
                    />
                  </>
                ) : (
                  <>
                    <div className="image-container">
                      <Link to={`/product/${product[1]?.id}`}>
                        {product[1]?.images !== undefined ? (
                          <Image
                            src={`${getBaseURL()}/api/file/download?ID=${
                              product[1]?.images[0]?.id
                            }&size=100`}
                            className="product-image"
                            alt="product"
                            preview={false}
                          />
                        ) : (
                          <Image
                            src="https://via.placeholder.com/100" // Placeholder image URL
                            alt="No Image"
                            preview={false}
                            className="product-image"
                          />
                        )}
                      </Link>

                      {product[1]?.price?.discountend !== null ? (
                        <div className="sale-timer">
                          <Countdown
                            value={product[1]?.price?.discountend}
                            format="Хямдрал: H[ц] m[м] s[с]"
                          />
                        </div>
                      ) : null}
                      <div
                        className={`fav-button ${
                          state?.favoriteItems?.find(
                            (favorite) => favorite.itemid === product[1]?.id
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        <Badge>
                          {userinfo.userid ? (
                            <Link onClick={() => handleClick(product)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <defs>
                                  <path
                                    id="scrap-icon-40-b"
                                    d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                                  ></path>
                                  <filter
                                    id="scrap-icon-40-a"
                                    width="150%"
                                    height="150%"
                                    x="-25%"
                                    y="-25%"
                                    filterUnits="objectBoundingBox"
                                  >
                                    <feOffset
                                      in="SourceAlpha"
                                      result="shadowOffsetOuter1"
                                    ></feOffset>
                                    <feGaussianBlur
                                      in="shadowOffsetOuter1"
                                      result="shadowBlurOuter1"
                                      stdDeviation="1.5"
                                    ></feGaussianBlur>
                                    <feComposite
                                      in="shadowBlurOuter1"
                                      in2="SourceAlpha"
                                      operator="out"
                                      result="shadowBlurOuter1"
                                    ></feComposite>
                                    <feColorMatrix
                                      in="shadowBlurOuter1"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                    ></feColorMatrix>
                                  </filter>
                                  <filter
                                    id="scrap-icon-40-c"
                                    width="150%"
                                    height="150%"
                                    x="-25%"
                                    y="-25%"
                                    filterUnits="objectBoundingBox"
                                  >
                                    <feGaussianBlur
                                      in="SourceAlpha"
                                      result="shadowBlurInner1"
                                      stdDeviation="1.5"
                                    ></feGaussianBlur>
                                    <feOffset
                                      in="shadowBlurInner1"
                                      result="shadowOffsetInner1"
                                    ></feOffset>
                                    <feComposite
                                      in="shadowOffsetInner1"
                                      in2="SourceAlpha"
                                      k2="-1"
                                      k3="1"
                                      operator="arithmetic"
                                      result="shadowInnerInner1"
                                    ></feComposite>
                                    <feColorMatrix
                                      in="shadowInnerInner1"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                    ></feColorMatrix>
                                  </filter>
                                </defs>
                                <g
                                  fill="none"
                                  fill-rule="nonzero"
                                  transform="matrix(1 0 0 -1 0 24)"
                                  className={`
                          "button-link active"
                          
                        }`}
                                >
                                  <use
                                    fill="#000"
                                    filter="url(#scrap-icon-40-a)"
                                    href="#scrap-icon-40-b"
                                  ></use>
                                  <use
                                    fill="#fff"
                                    fillOpacity="0.5"
                                    href="#scrap-icon-40-b"
                                  ></use>
                                  <use
                                    fill="#000"
                                    filter="url(#scrap-icon-40-c)"
                                    href="#scrap-icon-40-b"
                                  ></use>
                                  <path
                                    stroke="#FFF"
                                    d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                                  ></path>
                                </g>
                              </svg>
                            </Link>
                          ) : (
                            <Link to={"/login"}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <defs>
                                  <path
                                    id="scrap-icon-40-b"
                                    d="M12.472 6.93l7.056-3.811A1 1 0 0 1 21 4.002v15.496c0 .83-.672 1.502-1.5 1.502h-15c-.828 0-1.5-.673-1.5-1.502V4.002a1 1 0 0 1 1.472-.883l7.056 3.811a.999.999 0 0 0 .944 0z"
                                  ></path>
                                  <filter
                                    id="scrap-icon-40-a"
                                    width="150%"
                                    height="150%"
                                    x="-25%"
                                    y="-25%"
                                    filterUnits="objectBoundingBox"
                                  >
                                    <feOffset
                                      in="SourceAlpha"
                                      result="shadowOffsetOuter1"
                                    ></feOffset>
                                    <feGaussianBlur
                                      in="shadowOffsetOuter1"
                                      result="shadowBlurOuter1"
                                      stdDeviation="1.5"
                                    ></feGaussianBlur>
                                    <feComposite
                                      in="shadowBlurOuter1"
                                      in2="SourceAlpha"
                                      operator="out"
                                      result="shadowBlurOuter1"
                                    ></feComposite>
                                    <feColorMatrix
                                      in="shadowBlurOuter1"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0"
                                    ></feColorMatrix>
                                  </filter>
                                  <filter
                                    id="scrap-icon-40-c"
                                    width="150%"
                                    height="150%"
                                    x="-25%"
                                    y="-25%"
                                    filterUnits="objectBoundingBox"
                                  >
                                    <feGaussianBlur
                                      in="SourceAlpha"
                                      result="shadowBlurInner1"
                                      stdDeviation="1.5"
                                    ></feGaussianBlur>
                                    <feOffset
                                      in="shadowBlurInner1"
                                      result="shadowOffsetInner1"
                                    ></feOffset>
                                    <feComposite
                                      in="shadowOffsetInner1"
                                      in2="SourceAlpha"
                                      k2="-1"
                                      k3="1"
                                      operator="arithmetic"
                                      result="shadowInnerInner1"
                                    ></feComposite>
                                    <feColorMatrix
                                      in="shadowInnerInner1"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                    ></feColorMatrix>
                                  </filter>
                                </defs>
                                <g
                                  fill="none"
                                  fill-rule="nonzero"
                                  transform="matrix(1 0 0 -1 0 24)"
                                  className={`
                          "button-link active"
                          
                        }`}
                                >
                                  <use
                                    fill="#000"
                                    filter="url(#scrap-icon-40-a)"
                                    href="#scrap-icon-40-b"
                                  ></use>
                                  <use
                                    fill="#fff"
                                    fillOpacity="0.5"
                                    href="#scrap-icon-40-b"
                                  ></use>
                                  <use
                                    fill="#000"
                                    filter="url(#scrap-icon-40-c)"
                                    href="#scrap-icon-40-b"
                                  ></use>
                                  <path
                                    stroke="#FFF"
                                    d="M12.71 7.37h-.002a1.5 1.5 0 0 1-1.417 0L4.236 3.56a.499.499 0 0 0-.736.442v15.496c0 .553.448 1.002 1 1.002h15c.552 0 1-.449 1-1.002V4.002a.499.499 0 0 0-.734-.443l-7.057 3.81zm-.475-.88h-.001z"
                                  ></path>
                                </g>
                              </svg>
                            </Link>
                          )}
                        </Badge>
                      </div>
                    </div>
                    <Meta
                      title={
                        <Text
                          className="product-title"
                          strong
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          {product[1]?.name}
                        </Text>
                      }
                      description={
                        <>
                          <Space>
                            <div>
                              <Text className="discount-percent" strong>
                                {product[1]?.price?.discount}%
                              </Text>
                            </div>
                            <Text className="price">
                              {formatNumberWithCommas(
                                product[1]?.price?.calcprice
                              )}
                              ₮
                            </Text>
                          </Space>
                          {product[1]?.valuation !== undefined &&
                          product[1]?.valuation !== null ? (
                            <div className="star-ratings">
                              <Space direction="horizontal">
                                <div>
                                  <Rate
                                    allowHalf
                                    defaultValue={1}
                                    count={1}
                                    disabled
                                    style={{ fontSize: 18 }}
                                  />
                                  <Text className="star-rating-number">
                                    {product[1]?.valuation?.length?.toFixed(1)}
                                  </Text>
                                </div>
                                <Text className="order-number" type="secondary">
                                  Үнэлгээ:
                                  {formatNumberWithCommas(
                                    product[1]?.valuation?.length
                                  )}
                                </Text>
                              </Space>
                            </div>
                          ) : null}
                        </>
                      }
                    />
                  </>
                )}
              </Card>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
