import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  Steps,
  Space,
  Tag,
  Avatar,
  Typography,
  Flex,
  Button,
  Modal,
  Image,
} from "antd";
import moment from "moment";
import {
  StopOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  SolutionOutlined,
  FileProtectOutlined,
  TruckOutlined,
  QrcodeOutlined,
  SmileOutlined,
  SmileTwoTone,
} from "@ant-design/icons"; // Add this import statement

import { getBaseURL } from "../../system/apiconfig";
import { api } from "../../system/api";
const { confirm } = Modal;
const { Paragraph } = Typography;

export default function AllOrdersTab({ griddata, showDrawer, closeDrawer }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [code, setCode] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const getCode = async (itemId) => {
    confirm({
      title: "Анхааруулга",
      content:
        "Хүлээлгэж өгөх кодыг жолоочид хэлж өгснөөр таны бараа ямар ч эвдрэл гэмтгэлгүй очсоныг батлах болно.",
      okText: "Ойлголоо",
      cancelText: "Бараагаа шалгах",
      onCancel() {
        closeDrawer();
      },
      onOk() {
        closeDrawer();
        api
          .get(`/api/Product/get_order_code?id=${itemId}`)
          .then((res) => {
            if (res?.status === 200 && res?.data?.rettype === 0) {
              setCode(res?.data?.retdata?.vercode);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      },
    });
  };

  return isMobile ? (
    <List
      itemLayout="horizontal"
      dataSource={griddata || []}
      pagination
      renderItem={(item, index) => (
        <>
          <Card
            bordered
            style={{
              padding: 0,
              margin: 5,
              minHeight: 10,
            }}
            headStyle={
              item?.status === 0 ||
              (item?.status === 1 && moment().diff(item?.orderdate, "30") <= 30)
                ? { minHeight: 40, backgroundColor: "#f9f9f9" }
                : item?.status >= 2
                ? { minHeight: 40, backgroundColor: "#bee8a0" }
                : item?.status === 0 ||
                  item?.status === 1 ||
                  (item?.status === -1 &&
                    moment().diff(item?.orderdate, "minutes") >= 30)
                ? { minHeight: 40, backgroundColor: "#f9f9f9" }
                : null
            }
            onClick={() => showDrawer(item)}
            title={
              <>
                {item?.status === 1 &&
                moment().diff(item?.orderdate, "minutes") <= 30 ? (
                  <span className="f12">Төлбөр хүлээгдэж байна.</span>
                ) : item?.status === 1 ||
                  item?.status === 0 ||
                  (item?.status === -1 &&
                    moment().diff(item?.orderdate, "minutes") >= 30) ? (
                  <span className="f12">
                    <StopOutlined
                      style={{
                        marginRight: 5,
                        opacity: 0.5,
                        fontSize: 14,
                      }}
                    />
                    Захиалга цуцлагдсан
                  </span>
                ) : (
                  ""
                )}
                {item?.status >= 2 && item?.status <= 4 ? (
                  <Flex justify="space-between" className="f12" align="center">
                    <div>
                      <CheckCircleOutlined
                        style={{
                          marginRight: 5,
                          opacity: 0.5,
                          fontSize: 14,
                        }}
                      />
                      Төлбөр төлөгдсөн
                    </div>
                  </Flex>
                ) : item?.status === 5 ? (
                  <Flex align="center" justify="space-between">
                    <span className="f12">
                      <CheckCircleOutlined
                        style={{
                          marginRight: 5,
                          opacity: 0.5,
                          fontSize: 14,
                        }}
                      />
                      Хүргэлтэд гарсан
                    </span>
                    {code === null ? (
                      <Button
                        size="small"
                        type="dashed"
                        icon={<TruckOutlined />}
                        onClick={() => {
                          getCode(item?.id);
                          closeDrawer();
                        }}
                      >
                        Код авах
                      </Button>
                    ) : (
                      <Tag
                        color="default"
                        bordered={false}
                        icon={<TruckOutlined />}
                      >
                        Код:
                        <b>{code}</b>
                      </Tag>
                    )}
                  </Flex>
                ) : item?.status >= 6 ? (
                  <span className="f12">
                    <SmileTwoTone
                      style={{
                        marginRight: 5,
                        opacity: 0.5,
                        fontSize: 14,
                      }}
                    />
                    Захиалгыг амжилттай хүргэсэн
                  </span>
                ) : null}
              </>
            }
          >
            <List.Item>
              <List.Item.Meta
                title={
                  <span className="f12">Захиалгын дугаар: {item?.id}</span>
                }
                description={
                  <span className="f12">
                    {moment(item?.orderdate).format("YYYY/MM/DD HH:mm ")}
                  </span>
                }
              />
              <Space
                direction="vertical"
                style={{ lineHeight: 0.7, alignItems: "center" }}
              >
                Дүн <b>{(item?.totalprice).toLocaleString()}₮</b>
              </Space>
            </List.Item>
            {item?.status >= 2 && item?.status <= 5 ? (
              <Steps
                size="small"
                style={{ padding: 5 }}
                items={[
                  {
                    title: "Төлбөр төлөгдсөн",
                    status: "finish",
                    icon: <CheckOutlined />,
                  },
                  {
                    title:
                      item?.status === 2
                        ? "Захиалга бэлтгэгдэж байна"
                        : item?.status === 3
                        ? "Захиалга бэлтгэгдэж байна"
                        : item?.status === 4
                        ? "Захиалга бэлдэж дууссан"
                        : item?.status === 5
                        ? "Хүргэлтэд гарсан"
                        : null,
                    status:
                      item?.status === 2
                        ? "wait"
                        : item?.status === 3
                        ? "process"
                        : item?.status === 4
                        ? "process"
                        : item?.status === 5
                        ? "finish"
                        : "finish",
                    icon:
                      item?.status === 2 ? (
                        <SolutionOutlined />
                      ) : item?.status === 3 ? (
                        <FileProtectOutlined />
                      ) : item?.status === 4 ? (
                        <TruckOutlined />
                      ) : item?.status === 5 ? (
                        <TruckOutlined />
                      ) : item?.status === 7 ? (
                        <QrcodeOutlined />
                      ) : null,
                  },
                  {
                    title: "Хүргэгдсэн",
                    status: item?.status === 6 ? "finish" : "wait",
                    icon: <SmileOutlined />,
                  },
                ]}
              />
            ) : null}
          </Card>
        </>
      )}
    />
  ) : (
    <List
      itemLayout="horizontal"
      pagination
      dataSource={griddata || []}
      renderItem={(item, index) => (
        <>
          <Card
            bordered
            style={{
              padding: 10,
              margin: 10,
              borderLeft:
                item?.status === 0 ||
                (item?.status === 1 &&
                  moment().diff(item?.orderdate, "minutes") <= 30)
                  ? "10px solid #f9f9f9"
                  : item?.status >= 2
                  ? "10px solid #bee8a0"
                  : item?.status === 0 ||
                    item?.status === 1 ||
                    (item?.status === -1 &&
                      moment().diff(item?.orderdate, "minutes") >= 30)
                  ? "10px solid #ffbb96"
                  : null,
            }}
            onClick={() => showDrawer(item)}
          >
            <List.Item
              actions={[
                <Space key={index}>
                  {item?.status === 0 ||
                  (item?.status === 1 &&
                    moment().diff(item?.orderdate, "minutes") <= 30) ? (
                    <Tag
                      color="default"
                      style={{
                        whiteSpace: "normal",
                        display: "inline-block",
                      }}
                    >
                      Төлбөр хүлээгдэж байна.
                    </Tag>
                  ) : item?.status === 1 ||
                    item?.status === 0 ||
                    (item?.status === -1 &&
                      moment().diff(item?.orderdate, "minutes") >= 30) ? (
                    <Tag
                      color="default"
                      style={{
                        whiteSpace: "normal",
                        display: "inline-block",
                      }}
                    >
                      <StopOutlined
                        style={{
                          marginRight: 5,
                          opacity: 0.5,
                          fontSize: 14,
                        }}
                      />
                      Захиалга цуцлагдсан
                    </Tag>
                  ) : item?.status >= 2 && item?.status <= 4 ? (
                    <>
                      <Tag color="success">
                        <CheckCircleOutlined
                          style={{
                            marginRight: 5,
                            opacity: 0.5,
                            fontSize: 14,
                          }}
                        />
                        Төлбөр төлөгдсөн
                      </Tag>
                    </>
                  ) : item?.status === 5 ? (
                    <>
                      <Tag color="success">
                        <CheckCircleOutlined
                          style={{
                            marginRight: 5,
                            opacity: 0.5,
                            fontSize: 14,
                          }}
                        />
                        Хүргэлтэд гарсан
                      </Tag>
                      {code === null ? (
                        <Button
                          size="small"
                          type="dashed"
                          icon={<TruckOutlined />}
                          onClick={() => {
                            getCode(item?.id);
                            closeDrawer();
                          }}
                        >
                          Код авах
                        </Button>
                      ) : (
                        <Tag
                          color="default"
                          bordered={false}
                          icon={<TruckOutlined />}
                        >
                          Код:
                          <b>{code}</b>
                        </Tag>
                      )}
                    </>
                  ) : item?.status >= 6 ? (
                    <>
                      <Tag color="success">
                        <SmileTwoTone
                          style={{
                            marginRight: 5,
                            opacity: 0.5,
                            fontSize: 14,
                          }}
                        />
                        Захиалгыг амжилттай хүргэсэн
                      </Tag>
                    </>
                  ) : (
                    ""
                  )}
                </Space>,
              ]}
            >
              <List.Item.Meta
                title={<>Захиалгын дугаар: {item?.id}</>}
                description={`${moment(item?.orderdate).format(
                  "YYYY/MM/DD HH:mm"
                )}`}
              />
              <Space style={{ flex: 1 }}>
                <Avatar
                  src={`${getBaseURL()}/api/file/download?ID=${
                    item?.images[0]
                  }&size=50`}
                  size={"large"}
                />
              </Space>
              <Space direction="vertical" style={{ flex: 1, lineHeight: 0.7 }}>
                Дүн <b>{(item?.totalprice).toLocaleString()}₮</b>
              </Space>
            </List.Item>
            {item?.status >= 2 && item?.status <= 5 ? (
              <Steps
                size="small"
                style={{ padding: 5 }}
                items={[
                  {
                    title: "Төлбөр төлөгдсөн",
                    status: "finish",
                    icon: <CheckOutlined />,
                  },
                  {
                    title:
                      item?.status === 2
                        ? "Захиалга бэлтгэгдэж байна"
                        : item?.status === 3
                        ? "Захиалга бэлтгэгдэж байна"
                        : item?.status === 4
                        ? "Захиалга бэлдэж дууссан"
                        : item?.status === 5
                        ? "Хүргэлтэд гарсан"
                        : null,
                    status:
                      item?.status === 2
                        ? "wait"
                        : item?.status === 3
                        ? "process"
                        : item?.status === 4
                        ? "process"
                        : item?.status === 5
                        ? "finish"
                        : "finish",
                    icon:
                      item?.status === 2 ? (
                        <SolutionOutlined />
                      ) : item?.status === 3 ? (
                        <FileProtectOutlined />
                      ) : item?.status === 4 ? (
                        <TruckOutlined />
                      ) : item?.status === 5 ? (
                        <TruckOutlined />
                      ) : item?.status === 7 ? (
                        <QrcodeOutlined />
                      ) : null,
                  },
                  {
                    title: "Хүргэгдсэн",
                    status: item?.status === 6 ? "finish" : "wait",
                    icon: <SmileOutlined />,
                  },
                ]}
              />
            ) : null}
          </Card>
        </>
      )}
    />
  );
}
