import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Input,
  Space,
  Badge,
  Button,
  Dropdown,
  Drawer,
  Divider,
  Image,
  AutoComplete,
  Avatar,
  Tag,
  Anchor,
  Flex,
} from "antd";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import { getBaseURL } from "../../system/apiconfig";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import {
  SearchOutlined,
  DownOutlined,
  MenuOutlined,
  UserAddOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAppContext } from "../../Context/index";
const { Header } = Layout;
const { Search } = Input;

export default function HeaderMenu() {
  const { state } = useAppContext();
  const { userinfo } = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [griddata, setGridData] = useState();
  const [currentMenu, setCurrentMenu] = useState(null); // Initially set to null
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isMainMenuHovered, setIsMainMenuHovered] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState();
  const [searchHistory, setSearchHistory] = useState([]);

  const menu = (
    <Menu
      overlay
      mode="vertical"
      items={[
        { key: "1", title: "Option 1" },
        { key: "2", title: "Option 2" },
      ]}
    ></Menu>
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/api/Product/Get_category_Tree?parentid=0`);
        if (res?.status === 200 && res?.data?.rettype === 0) {
          setGridData(res?.data?.retdata);
          setAnchorMenu(res?.data?.retdata[0]?.children);
        }
      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let selectedItems = JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSearchHistory(selectedItems);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    setDrawerVisible(false);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const handleSearchClick = () => {
    setExpanded(!expanded);
  };

  const handleMouseEnter = (item) => {
    setCurrentMenu(item);
    setIsMainMenuHovered(true);
  };

  const handleMouseLeave = () => {
    setCurrentMenu(null);
    setIsMainMenuHovered(false);
  };
  const renderTitle = (title, count) => (
    <Link to={`/category/${title?.categoryid}`} key={`category-${count}`}>
      Ангилал:<b>{title?.categoryname}</b>
    </Link>
  );

  const renderItem = (title, count) => ({
    value: title?.name,
    label: (
      <Link to={`/product/${title?.id}`} key={`product-${count}`}>
        <Space key={`space-${count}`}>
          <SearchOutlined />
          {title?.name?.length > 45
            ? title?.name?.substring(0, 45) + "..."
            : title?.name}
        </Space>
      </Link>
    ),
  });

  const handleSearch = async (value) => {
    if (value?.length >= 2) {
      setSearchValue(value);
      const response = await api.get(
        `/api/Product/get_Item_search?search=${encodeURIComponent(
          value
        )}&page=1&size=10`
      );

      if (
        response?.status === 200 &&
        response?.data &&
        response?.data.rettype === 0
      ) {
        setSearchResults(response?.data?.retdata);
      } else {
        setSearchResults([]);
      }
    } else {
      // Clear search results if the input length is less than 3
      setSearchResults([]);
    }
  };

  const handleSelect = (value) => {
    let selectedItems = JSON.parse(localStorage.getItem("searchHistory")) || [];

    if (!selectedItems.includes(value)) {
      selectedItems.unshift(value);

      if (selectedItems.length > 10) {
        selectedItems.pop();
      }

      localStorage.setItem("searchHistory", JSON.stringify(selectedItems));
      setSearchHistory(selectedItems);

      handleSearchClick();
    }
  };

  const handleClose = (removedTag) => {
    const newTags = searchHistory?.filter((tag) => tag !== removedTag);
    setSearchHistory(newTags);
    localStorage.setItem("searchHistory", JSON.stringify(newTags));
  };

  const options = searchResults?.map((result, index) => ({
    key: index,
    value: result?.name,
    label: renderTitle(result),
    options: [renderItem(result, index, () => setSearchValue([]))],
  }));

  const onLogoutClick = async (event) => {
    localStorage.removeItem("token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token_expires_in");
    localStorage.removeItem("searchHistory");
    window.location.assign("/");
  };
  const menuUser = (
    <Menu>
      {/* Add other menu items as needed */}
      <Menu.Item
        key="profile"
        onClick={(event) => {
          navigate("/account", { state: { userid: userinfo.userid } });
        }}
      >
        Профайл
      </Menu.Item>
      <Menu.Item key="logout" onClick={onLogoutClick}>
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  const renderMenuItems = (categories) => {
    return categories?.children?.map((category) => {
      if (category?.children && category?.children?.length > 0) {
        const subMenuTitle = (
          <span>
            <Link to={`/category/${category?.id}`}>
              <Space>
                <Image
                  src={`${getBaseURL()}/api/file/download?ID=${
                    category?.images?.[category?.images?.length - 1]
                  }&size=64`}
                  preview={false}
                  width={30}
                />
                {category?.name}
              </Space>
            </Link>
          </span>
        );

        return (
          <Menu.SubMenu key={category?.id} title={subMenuTitle}>
            {renderMenuItems(category)}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item key={category?.id}>
            <Link to={`/category/${category?.id}`}>
              <Space>
                <Image
                  src={`${getBaseURL()}/api/file/download?ID=${
                    category?.images?.[category?.images?.length - 1]
                  }&size=64`}
                  preview={false}
                  width={30}
                />
                {category?.name}
              </Space>
            </Link>
          </Menu.Item>
        );
      }
    });
  };

  const handleSearchEnter = (value) => {
    if (value) {
      handleSearch(
        value,
        navigate(`/search?query=${searchValue}&page=1&pagesize=10`)
      );
    }
    handleSearchClick();
  };

  return (
    <>
      <Anchor className={"sticky-container1"} offsetTop={0} targetOffset={0}>
        <Header
          className={`header ${isMobile ? "mobile" : ""}`}
          loading={loading}
        >
          <Space direction="horizontal" className="space-align-container">
            {isMobile && (
              <Button
                className="menu-toggle-button"
                type="text"
                icon={<MenuOutlined />}
                onClick={showDrawer}
                style={{ order: 1 }} // Adjust the order for the hamburger menu
              />
            )}

            <div
              className={`logo ${isMobile ? "mobile" : ""}`}
              style={{ order: 2 }}
            >
              <Link to="/" className="button-link">
                <div className="logo-content">
                  <Image
                    src="/assets/images/uju4.png"
                    preview={false}
                    width={isMobile ? 80 : 80}
                  />
                </div>
              </Link>
            </div>

            {!isMobile && (
              <Menu
                mode="horizontal"
                style={{ width: 200, maxWidth: "auto", minWidth: 200 }}
              >
                {griddata?.map((item) => (
                  <Menu.Item
                    key={item.id}
                    onMouseEnter={() => handleMouseEnter(item.id)}
                  >
                    {item.name}
                  </Menu.Item>
                ))}
              </Menu>
            )}
            {isMobile ? (
              <Space style={{ marginTop: 10, marginRight: 10 }}>
                <Badge>
                  <Link onClick={handleSearchClick} className="button-link">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M1780 5104 c-917 -110 -1657 -855 -1764 -1775 -109 -932 438 -1816
  1323 -2139 667 -244 1442 -108 1977 347 28 23 55 43 60 43 5 0 362 -353 794
  -784 684 -684 790 -785 821 -791 74 -14 138 50 124 124 -6 31 -107 137 -791
  821 -431 432 -784 789 -784 794 0 5 20 32 43 60 150 176 280 407 359 636 249
  729 63 1534 -481 2079 -448 448 -1057 660 -1681 585z m515 -220 c656 -97 1203
  -545 1436 -1177 71 -193 103 -381 103 -612 1 -233 -30 -414 -108 -628 -176
  -487 -586 -897 -1073 -1073 -214 -78 -395 -109 -628 -108 -290 0 -511 49 -775
  171 -296 137 -588 398 -759 676 -185 303 -272 608 -272 957 -1 357 86 663 273
  964 138 223 352 436 575 575 372 231 797 319 1228 255z"
                        />
                      </g>
                    </svg>
                  </Link>
                </Badge>
                <Badge count={state?.favoriteItems?.length}>
                  <Link
                    to={userinfo.userid ? "/collections" : "/login"}
                    className="button-link"
                  >
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M1253 4690 c-212 -56 -371 -221 -418 -435 -14 -62 -15 -272 -13
  -1820 l3 -1750 29 -58 c83 -163 281 -238 436 -166 30 15 93 60 140 101 96 85
  546 483 843 747 109 97 213 184 230 194 38 21 82 22 119 3 15 -8 277 -236 583
  -508 629 -559 636 -563 764 -562 134 1 243 70 303 192 l33 67 3 1730 c2 1192
  -1 1750 -8 1795 -38 235 -220 427 -447 475 -57 12 -265 15 -1298 14 -1170 0
  -1233 -1 -1302 -19z m2596 -207 c111 -45 180 -114 223 -221 l23 -57 3 -1713
  c1 -1123 -1 -1726 -8 -1751 -14 -53 -59 -92 -113 -98 -24 -3 -54 0 -66 6 -12
  7 -276 237 -588 513 -424 375 -581 507 -621 526 -105 48 -236 38 -329 -25 -22
  -15 -287 -246 -589 -513 -301 -267 -557 -490 -568 -497 -12 -7 -40 -12 -63
  -13 -53 0 -108 44 -123 98 -14 51 -14 3379 0 3452 31 162 151 283 308 310 26
  4 589 7 1252 6 l1205 -1 54 -22z"
                        />
                      </g>
                    </svg>
                  </Link>
                </Badge>
                <Badge count={state?.shoppingCart?.length}>
                  <Link
                    to={userinfo?.userid ? "/cart" : "/login"}
                    className="button-link"
                  >
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M63 5034 c-31 -15 -63 -65 -63 -98 0 -32 29 -85 56 -103 25 -16 58
  -19 289 -23 245 -5 262 -6 302 -28 57 -30 108 -83 136 -140 13 -26 192 -555
  397 -1176 l374 -1128 -18 -47 c-73 -190 -70 -180 -71 -301 0 -94 4 -127 23
  -182 62 -180 210 -315 397 -363 87 -22 2431 -23 2474 -1 82 43 81 161 -2 209
  -17 9 -293 13 -1232 17 l-1210 5 -54 30 c-55 30 -104 83 -137 145 -13 26 -18
  58 -19 125 0 77 4 100 28 156 28 65 30 67 70 73 23 3 584 62 1247 131 663 69
  1230 130 1260 136 120 23 245 86 334 168 65 60 132 167 166 266 37 107 312
  1285 306 1310 -7 29 -41 63 -76 77 -16 6 -742 20 -1962 37 -1066 15 -1939 29
  -1942 32 -2 2 -27 76 -56 164 -74 223 -101 276 -185 361 -77 78 -175 133 -273
  153 -30 6 -156 11 -290 11 -195 0 -243 -3 -269 -16z m3207 -945 c866 -12 1576
  -23 1578 -25 2 -1 -55 -244 -127 -541 -136 -558 -157 -623 -221 -692 -51 -54
  -123 -97 -191 -115 -56 -14 -2435 -266 -2517 -266 l-31 0 -152 463 c-84 254
  -207 630 -275 836 l-123 374 242 -6 c133 -4 951 -16 1817 -28z"
                        />
                        <path
                          d="M1520 1124 c-192 -41 -360 -205 -405 -395 -43 -184 9 -365 143 -499
  137 -137 315 -188 501 -145 93 22 176 71 252 147 119 119 174 280 151 442 -25
  175 -137 329 -297 406 -118 58 -221 71 -345 44z m244 -258 c105 -51 160 -144
  161 -266 0 -59 -5 -84 -23 -120 -36 -68 -99 -127 -160 -150 -243 -91 -473 139
  -382 382 29 78 108 149 200 179 46 15 147 3 204 -25z"
                        />
                        <path
                          d="M3780 1124 c-102 -22 -185 -68 -265 -148 -108 -108 -155 -220 -155
  -371 0 -152 49 -272 157 -379 208 -208 538 -208 746 0 108 107 157 225 157
  373 0 158 -45 267 -155 377 -81 81 -163 126 -270 149 -85 18 -130 18 -215 -1z
  m232 -251 c60 -27 115 -84 146 -151 65 -142 5 -311 -135 -382 -69 -36 -197
  -36 -266 0 -164 83 -210 284 -101 442 73 105 231 146 356 91z"
                        />
                      </g>
                    </svg>
                  </Link>
                </Badge>
              </Space>
            ) : (
              <>
                <AutoComplete
                  style={{ display: "flex", alignItems: "center" }}
                  options={options}
                  onSelect={handleSelect}
                >
                  <Search
                    style={{ width: 400, maxWidth: 400 }}
                    placeholder="Хайх"
                    value={searchValue}
                    allowClear
                    size="large"
                    onChange={(e) => handleSearch(e.target.value)}
                    onPressEnter={handleSearchEnter}
                    onSearch={handleSearchEnter}
                    enterButton
                  />
                </AutoComplete>
                <div className="button-container">
                  <div className="button-badge">
                    <Badge count={state?.favoriteItems?.length}>
                      <Link
                        to={userinfo.userid ? "/collections" : "/login"}
                        className="button-link"
                      >
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 512.000000 512.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M1253 4690 c-212 -56 -371 -221 -418 -435 -14 -62 -15 -272 -13
  -1820 l3 -1750 29 -58 c83 -163 281 -238 436 -166 30 15 93 60 140 101 96 85
  546 483 843 747 109 97 213 184 230 194 38 21 82 22 119 3 15 -8 277 -236 583
  -508 629 -559 636 -563 764 -562 134 1 243 70 303 192 l33 67 3 1730 c2 1192
  -1 1750 -8 1795 -38 235 -220 427 -447 475 -57 12 -265 15 -1298 14 -1170 0
  -1233 -1 -1302 -19z m2596 -207 c111 -45 180 -114 223 -221 l23 -57 3 -1713
  c1 -1123 -1 -1726 -8 -1751 -14 -53 -59 -92 -113 -98 -24 -3 -54 0 -66 6 -12
  7 -276 237 -588 513 -424 375 -581 507 -621 526 -105 48 -236 38 -329 -25 -22
  -15 -287 -246 -589 -513 -301 -267 -557 -490 -568 -497 -12 -7 -40 -12 -63
  -13 -53 0 -108 44 -123 98 -14 51 -14 3379 0 3452 31 162 151 283 308 310 26
  4 589 7 1252 6 l1205 -1 54 -22z"
                            />
                          </g>
                        </svg>
                      </Link>
                    </Badge>
                  </div>
                  <div className="button-badge">
                    <Badge count={state?.shoppingCart?.length}>
                      <Link
                        to={userinfo?.userid ? "/cart" : "/login"}
                        className="button-link"
                      >
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 512.000000 512.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M63 5034 c-31 -15 -63 -65 -63 -98 0 -32 29 -85 56 -103 25 -16 58
  -19 289 -23 245 -5 262 -6 302 -28 57 -30 108 -83 136 -140 13 -26 192 -555
  397 -1176 l374 -1128 -18 -47 c-73 -190 -70 -180 -71 -301 0 -94 4 -127 23
  -182 62 -180 210 -315 397 -363 87 -22 2431 -23 2474 -1 82 43 81 161 -2 209
  -17 9 -293 13 -1232 17 l-1210 5 -54 30 c-55 30 -104 83 -137 145 -13 26 -18
  58 -19 125 0 77 4 100 28 156 28 65 30 67 70 73 23 3 584 62 1247 131 663 69
  1230 130 1260 136 120 23 245 86 334 168 65 60 132 167 166 266 37 107 312
  1285 306 1310 -7 29 -41 63 -76 77 -16 6 -742 20 -1962 37 -1066 15 -1939 29
  -1942 32 -2 2 -27 76 -56 164 -74 223 -101 276 -185 361 -77 78 -175 133 -273
  153 -30 6 -156 11 -290 11 -195 0 -243 -3 -269 -16z m3207 -945 c866 -12 1576
  -23 1578 -25 2 -1 -55 -244 -127 -541 -136 -558 -157 -623 -221 -692 -51 -54
  -123 -97 -191 -115 -56 -14 -2435 -266 -2517 -266 l-31 0 -152 463 c-84 254
  -207 630 -275 836 l-123 374 242 -6 c133 -4 951 -16 1817 -28z"
                            />
                            <path
                              d="M1520 1124 c-192 -41 -360 -205 -405 -395 -43 -184 9 -365 143 -499
  137 -137 315 -188 501 -145 93 22 176 71 252 147 119 119 174 280 151 442 -25
  175 -137 329 -297 406 -118 58 -221 71 -345 44z m244 -258 c105 -51 160 -144
  161 -266 0 -59 -5 -84 -23 -120 -36 -68 -99 -127 -160 -150 -243 -91 -473 139
  -382 382 29 78 108 149 200 179 46 15 147 3 204 -25z"
                            />
                            <path
                              d="M3780 1124 c-102 -22 -185 -68 -265 -148 -108 -108 -155 -220 -155
  -371 0 -152 49 -272 157 -379 208 -208 538 -208 746 0 108 107 157 225 157
  373 0 158 -45 267 -155 377 -81 81 -163 126 -270 149 -85 18 -130 18 -215 -1z
  m232 -251 c60 -27 115 -84 146 -151 65 -142 5 -311 -135 -382 -69 -36 -197
  -36 -266 0 -164 83 -210 284 -101 442 73 105 231 146 356 91z"
                            />
                          </g>
                        </svg>
                      </Link>
                    </Badge>
                  </div>
                </div>
              </>
            )}
            {isMobile ? (
              ""
            ) : (
              <>
                {userinfo === "-1" ? (
                  <>
                    <Link
                      onClick={() => {
                        window.location.href = "/login";
                      }}
                      className="action-buttons link-style"
                    >
                      НЭВТРЭХ
                    </Link>
                    <Divider type="vertical" />
                    <Link
                      onClick={() => {
                        window.location.href = "/register";
                      }}
                      className=" action-buttons  link-style"
                    >
                      БҮРТГҮҮЛЭХ
                    </Link>
                  </>
                ) : (
                  <Dropdown
                    overlay={menuUser}
                    placement="bottom"
                    trigger={["click"]}
                  >
                    <Avatar
                      size={36}
                      key={state?.userImage}
                      src={`${getBaseURL()}/api/file/download?ID=${
                        state?.userImage
                      }&size=36`}
                      icon={<UserOutlined />}
                      className="hover-avatar"
                    />
                  </Dropdown>
                )}
                {/* <Dropdown
                  className="action-buttons "
                  overlay={menu}
                  menu={menu}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <Button className="custom-dropdown-button" type="primary">
                    Үнэлгээ <DownOutlined className="custom-dropdown-icon" />
                  </Button>
                </Dropdown> */}
              </>
            )}
          </Space>
        </Header>
      </Anchor>

      {isMobile ? (
        <Anchor
          direction="horizontal"
          offsetTop={64}
          targetOffset={10}
          className={"sticky-container1"}
          items={anchorMenu?.map((category) => ({
            title: category?.name,
            href: `/category/${category?.id}`,
          }))}
          style={{ paddingLeft: 10 }}
        />
      ) : (
        ""
      )}
      {isMainMenuHovered && currentMenu && (
        <Header className="submenu-header" onMouseLeave={handleMouseLeave}>
          <div className="submenu-text">
            {griddata[griddata?.length - 1]?.children?.map((subitem) => (
              <div key={subitem.id} className="pm0" style={{ lineHeight: 2 }}>
                <Link to={`/category/${subitem?.id}`} className="pm0">
                  <Image
                    src={`${getBaseURL()}/api/file/download?ID=${
                      subitem?.images?.[subitem?.images?.length - 1]
                    }&size=64`}
                    width={60}
                    className="pm0"
                    preview={false}
                  />
                  <p className="pm0">{subitem.name}</p>
                </Link>
              </div>
            ))}
          </div>
        </Header>
      )}
      {isMobile && expanded && (
        <Drawer
          title="Бараа хайх"
          placement="right"
          onClose={handleSearchClick}
          open={expanded}
          width={"100%"}
        >
          <AutoComplete
            options={options}
            onSelect={handleSelect}
            style={{ width: "100%", padding: 10 }}
          >
            <Search
              placeholder="Хайх"
              value={searchValue}
              allowClear
              size="large"
              onChange={(e) => handleSearch(e.target.value)}
              enterButton
              onSearch={handleSearchEnter}
            />
          </AutoComplete>
          <Space direction="vertical" className="p10" style={{ width: "95%" }}>
            <Flex justify="space-between" align="center">
              <h5>Сүүлийн хайлтууд</h5>
              <Button
                type="link"
                size="small"
                style={{ fontSize: 10 }}
                onClick={() => {
                  localStorage.removeItem("searchHistory");
                  setSearchHistory([]);
                }}
              >
                Бүгдийг устгах
              </Button>
            </Flex>
            {searchHistory?.map((item, index) => (
              <Tag
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleClose(item);
                }}
                key={index}
              >
                {item}
              </Tag>
            ))}
          </Space>
        </Drawer>
      )}

      <Drawer
        title={
          <>
            <div className={`logo ${isMobile ? "mobile" : ""}`}>
              <Image
                src="/assets/images/uju4.png"
                preview={false}
                width={isMobile && 100}
              />
            </div>
            {userinfo === "-1" ? (
              <>
                <div className="login-register-buttons">
                  <Button
                    type="default"
                    icon={<UserOutlined />}
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                    size="small"
                  >
                    НЭВТРЭХ
                  </Button>
                  <Button
                    icon={<UserAddOutlined />}
                    type="primary"
                    onClick={() => {
                      window.location.href = "/register";
                    }}
                    size="small"
                  >
                    БҮРТГҮҮЛЭХ
                  </Button>
                </div>
              </>
            ) : (
              <Space>
                <Avatar
                  size={36}
                  key={state?.userImage}
                  src={`${getBaseURL()}/api/file/download?ID=${
                    state?.userImage
                  }&size=36`}
                  icon={<UserOutlined />}
                  className="hover-avatar"
                />
                <p>
                  <b>{userinfo?.username}</b>
                </p>
              </Space>
            )}
          </>
        }
        placement="left"
        onClose={onCloseDrawer}
        className="cust-draw"
        open={drawerVisible}
        width={250}
        style={{
          transform: drawerVisible ? "translateX(0)" : "translateX(-100%)",
        }}
        footer={
          <Button onClick={onLogoutClick} icon={<LogoutOutlined />}>
            ГАРАХ
          </Button>
        }
      >
        <Menu mode="inline" className="custom-menu">
          {griddata?.map((item) => renderMenuItems(item))}
        </Menu>
      </Drawer>
    </>
  );
}
