import React, { useState, useEffect, useCallback } from "react";
import {
  Carousel,
  Row,
  Col,
  Button,
  Drawer,
  List,
  Image,
  Space,
  Menu,
  Avatar,
  Collapse,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  MenuOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./style.css";
import { api } from "../../system/api";
import { getBaseURL } from "../../system/apiconfig";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Ant Design Title 1",
  },
  {
    title: "Ant Design Title 2",
  },
  {
    title: "Ant Design Title 3",
  },
  {
    title: "Ant Design Title 4",
  },
];

export default function CategoryCarousel() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openKeys, setOpenKeys] = useState([]);
  const [griddata, setGridData] = useState();
  const [activeAnchor, setActiveAnchor] = useState();
  const [selectedMenuKey, setSelectedMenuKey] = useState();

  const fetchData = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/api/Product/Get_category_Tree?parentid=1`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.rettype === 0) {
          setGridData(res?.data?.retdata);
          setSelectedMenuKey(res?.data?.retdata[0]?.key);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleAnchorClick = (link) => {
    const targetElement = document.getElementById(link.key);

    if (targetElement) {
      setActiveAnchor(link.key);

      // Delay the scrollIntoView to ensure that the state is updated
      setTimeout(() => {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }
  };

  useEffect(() => {
    fetchData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [fetchData]);

  const carouselRef = React.useRef();

  const handlePrevClick = () => {
    carouselRef.current.prev();
  };

  const handleNextClick = () => {
    carouselRef.current.next();
  };

  const handleDrawerClick = () => {
    setDrawerVisible(!drawerVisible);
  };

  const items = griddata?.map((category) => ({
    key: `${category?.key}`, // Using id as the key
    title: <span style={{ textTransform: "uppercase" }}>{category?.name}</span>,
    href: `#${category.id}`,
  }));

  const handleRightColumnScroll = (e) => {
    const scrollPosition = e.target.scrollTop;
    let activeKey = null;

    griddata?.forEach((category) => {
      const targetElement = document.getElementById(category?.key);
      if (targetElement) {
        const elementTop = targetElement.offsetTop - 1;
        const elementBottom = elementTop + targetElement.clientHeight;

        if (scrollPosition >= elementTop && scrollPosition < elementBottom) {
          activeKey = category.key;
        }
      }
    });

    if (activeKey && activeKey !== selectedMenuKey) {
      setSelectedMenuKey(activeKey);
    }
  };

  const menuItems = griddata?.map((category) => (
    <Menu.Item key={category?.key} onClick={() => handleAnchorClick(category)}>
      {category?.name}
    </Menu.Item>
  ));

  return (
    <div className={`category-container ${isMobile ? "mobile" : ""}`}>
      <div className="category-carousel">
        <h2>БАРААНЫ АНГИЛАЛ</h2>

        <Carousel
          ref={carouselRef}
          slidesToShow={griddata?.length > 10 ? 10 : griddata?.length}
          infinite={!isMobile ? true : false}
          dots={false}
          draggable
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: !isMobile ? 3 : 5,
              },
            },
          ]}
        >
          {isMobile && (
            <Space className="static-item">
              <Button
                type="text"
                icon={
                  <MenuOutlined
                    style={{ color: "rgba(250, 84, 28)", fontSize: 30 }}
                  />
                }
                onClick={handleDrawerClick}
              />
              <p>БҮХ АНГИЛАЛ</p>
            </Space>
          )}
          {griddata?.map((category, index) => (
            <div key={index} className={`category-slide`}>
              <Row>
                <Col span={24}>
                  <Link to={`/category/${category?.id}`}>
                    <span className="imgTitle">
                      <Image
                        src={`${getBaseURL()}/api/file/download?ID=${
                          category?.images?.[category?.images?.length - 1]
                        }&size=180`}
                        preview={false}
                      />
                      <p>{category.name}</p>
                    </span>
                  </Link>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>
        <>
          {!isMobile && (
            <Button
              className="scroll-button prev-button"
              type="text"
              icon={<LeftOutlined />}
              onClick={handlePrevClick}
            />
          )}
          <Button
            className="scroll-button next-button"
            type="text"
            icon={<RightOutlined />}
            onClick={handleNextClick}
          />
        </>
      </div>

      {isMobile && (
        <Drawer
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={() => setDrawerVisible(false)}
                style={{ fontSize: "20px", padding: "0", marginRight: "10px" }}
              />
              <h3
                style={{
                  flex: 1,
                  textAlign: "center",
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                ҮНДСЭН АНГИЛАЛ
              </h3>
            </div>
          }
          placement="left"
          closable={false}
          visible={drawerVisible}
          width={isMobile && "100%"}
          className="drawer-container"
          style={{ overflow: "auto" }}
        >
          <Row className="row-container">
            <Col span={8} className="left-column">
              <Menu
                mode="vertical"
                onClick={(e) => handleAnchorClick(e.item)}
                defaultSelectedKeys={`${activeAnchor}`}
                defaultOpenKeys={[selectedMenuKey]}
                selectedKeys={[selectedMenuKey]}
              >
                {menuItems}
              </Menu>
            </Col>
            <Col span={16}>
              <div className="right-column" onScroll={handleRightColumnScroll}>
                {griddata?.map((cat, index) => (
                  <div
                    style={{
                      height:
                        index === griddata?.length - 1 && isMobile
                          ? "100%"
                          : "auto",
                    }}
                  >
                    <Space style={{ padding: 15 }} id={cat?.key}>
                      <Link to={`/category/${cat?.id}`}>
                        <span
                          className={selectedMenuKey === cat?.key ? "br" : "bt"}
                        >
                          <b>
                            {cat?.name}
                            <RightOutlined />
                          </b>
                        </span>
                      </Link>
                    </Space>
                    {cat?.children?.map((child, index) => (
                      <Collapse
                        accordion
                        bordered={false}
                        collapsible={child?.children === null && "disabled"}
                        expandIconPosition="end"
                        items={[
                          {
                            key: child?.key,
                            label: (
                              <Space>
                                <Image
                                  src={`${getBaseURL()}/api/file/download?ID=${
                                    child?.images?.[child?.images?.length - 1]
                                  }&size=64`}
                                  preview={false}
                                  width={50}
                                />
                                <span>
                                  {child?.children === null ?? (
                                    <Link to={`/category/${cat?.id}`}>
                                      {cat?.name}
                                    </Link>
                                  )}
                                  <span>
                                    <b className="bt">{child?.name}</b>
                                  </span>
                                </span>
                              </Space>
                            ),
                            children: child?.name,
                            showArrow: child?.children !== null ? true : false,
                          },
                        ]}
                      ></Collapse>
                    ))}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Drawer>
      )}
    </div>
  );
}
