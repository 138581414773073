import { useState } from "react";

export default function useToken() {
  const getToken = () => {
      const tokenString = localStorage.getItem("token");
      if (tokenString === 'undefined')
          return '-1';
    const userToken = JSON.parse(tokenString);
    return userToken?.access_token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    localStorage.setItem("access_token", userToken?.access_token);
    localStorage.setItem("refresh_token", userToken?.refresh_token);
    localStorage.setItem("refresh_token_expires_in",userToken?.refresh_token_expires_in);
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
