import React, { useState, useCallback, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Space,
  message,
  notification,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./style.css";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
} from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { api } from "../../system/api";
import useToken from "../../system/useToken";
import { useNavigate } from "react-router-dom";
import useUserInfo from "../../system/useUserInfo";

async function loginUser(credentials) {
  return api
    .post("/api/Systems/login", credentials)
    .then((response) => response?.data?.retdata);
}

export default function RegistrationForm() {
  const { userinfo } = useUserInfo();
  const { token, setToken } = useToken();
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const googleRef = useRef();

  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const isUsernameDuplicate = await checkUsernameDuplicate(
        values?.username
      );
      if (isUsernameDuplicate) {
        message.error(
          "Хэрэглэгчийн нэр давхардаж байна. Та өөр хэрэглэгчийн нэр ашиглана уу."
        );
        return;
      }
      // Extract user data from form values
      const userData = {
        username: values?.username,
        email: values?.email,
        password: values?.password,
        encryptpass: null, // Assuming encryption is handled server-side
        roleid: 0, // Set the role ID as needed
        gender: 0, // Set the gender as needed
        birthday: "2024-03-26T09:33:12.811Z", // Example birthday (replace with actual value)
        facebookid: "", // Facebook ID if applicable
        type: "custom", // Type of registration (e.g., "custom", "facebook", "google")
      };

      // Make the API call to set the user
      const response = await api.post("/api/systems/User/set_user", userData);

      // Handle the response
      if (response.status === 200) {
        // Registration successful, proceed with login
        const token = await loginUser({
          username: values.username,
          password: values.password,
          type: "custom", // Assuming a custom login type
        });
        if (token) {
          // Set the authentication token
          setToken(token);
          notification.success({
            message: "Амжилттай бүртгэгдлээ.",
            placement: "leftRight",
          });
          navigate("/account/config", { state: { userid: userinfo?.userid } });
        } else {
          // Handle invalid credentials case
          message.error("Login failed");
        }
      } else {
        // Handle registration error
        console.error("User registration failed:", response.statusText);
        message.error("User registration failed");
      }
    } catch (error) {
      console.error("An error occurred during user registration:", error);
      message.error("An error occurred during user registration");
    } finally {
      setLoading(false);
    }
  };

  async function checkUsernameDuplicate(username) {
    try {
      const response = await api.get(
        `/api/systems/User/get_user_search?search=${username}`
      );
      return response?.data && response?.data?.retdata !== null;
    } catch (error) {
      console.error("Error checking username duplication:", error);
      // Handle the error gracefully, for example, showing a message to the user
      message.error("An error occurred while checking username duplication.");
      return true; // Assume duplicate to prevent registration in case of an error
    }
  }

  const onFacebookConnectSuccess = useCallback(async (data) => {
    try {
      // Handle successful Facebook connection
      setProvider("facebook");
      setProfile(data);

      // Check if the user with the Facebook ID already exists in the system
      let existingUserResponse;
      try {
        existingUserResponse = await api.get(
          `/api/systems/User/get_user?userid=${data?.id}`
        );
      } catch (error) {
        // If the user does not exist, proceed with registration
        if (error.response && error.response.status === 400) {
          existingUserResponse = null;
        } else {
          throw error; // Re-throw other errors
        }
      }

      if (existingUserResponse?.data?.retdata === null) {
        // User does not exist, proceed with registration
        // Prepare user data for registration
        const userData = {
          username: data?.first_name,
          email: data?.email,
          password: "facebook",
          encryptpass: null,
          roleid: 0,
          gender: 0,
          birthday: "2023-11-18T20:02:58.473Z",
          facebookid: data?.id,
          type: "facebook",
        };
        const response = await api.post("/api/systems/User/set_user", userData);
        if (response.status === 200) {
          // Registration successful, proceed with login
          const token = await loginUser({
            username: data.first_name,
            password: "facebook",
            type: "facebook",
          });
          if (token) {
            // Set the authentication token
            setToken(token);
            navigate("/account/config", {
              state: { userid: userinfo?.userid },
            });
          } else {
            // Handle invalid credentials case
            message.error("Login failed with Facebook");
          }
        } else {
          // Handle registration error
          console.error("User registration failed:", response.statusText);
        }
      } else {
        // User already exists, proceed with login
        const token = await loginUser({
          username: data.first_name,
          password: "facebook",
          type: "facebook",
        });
        if (token) {
          // Set the authentication token
          setToken(token);
          navigate("/", { state: { userid: userinfo?.userid } });
        } else {
          // Handle invalid credentials case
          message.error("Login failed with Facebook");
        }
      }
    } catch (error) {
      console.error("An error occurred during user registration:", error);
    }
  }, []);

  const responseGoogle = useCallback(async (data) => {
    try {
      // Handle successful Google login
      if (data) {
        setProvider("google");
        setProfile(data);

        // Check if the user with the Google ID already exists in the system
        let existingUserResponse;
        try {
          existingUserResponse = await api.get(
            `/api/systems/User/get_user?userid=${data?.sub})}`
          );
        } catch (error) {
          // If the user does not exist, proceed with registration
          if (error.response && error.response.status === 400) {
            existingUserResponse = null;
          } else {
            throw error; // Re-throw other errors
          }
        }

        if (existingUserResponse?.data?.retdata === null) {
          const userData = {
            username: data?.given_name,
            email: "",
            password: "google",
            encryptpass: null,
            roleid: 0,
            gender: 0,
            birthday: "2023-11-18T20:02:58.473Z",
            facebookid: data?.sub,
            type: "google",
          };
          const response = await api.post(
            "/api/systems/User/set_user",
            userData
          );
          if (response.status === 200) {
            // Registration successful, proceed with login
            const token = await loginUser({
              username: data?.given_name,
              password: "google",
              type: "google",
            });
            if (token) {
              // Set the authentication token
              setToken(token);
              navigate("/account/config", {
                state: { userid: userinfo?.userid },
              });
            } else {
              // Handle invalid credentials case
              message.error("Login failed with Facebook");
            }
          } else {
            // Handle registration error
            console.error("User registration failed:", response.statusText);
          }
          // User exists, proceed with login
          const token = await loginUser({
            username: data?.given_name,
            password: "google",
            type: "google",
          });

          if (token) {
            // Set the authentication token
            setToken(token);
            navigate("/account/config", {
              state: { userid: userinfo?.userid },
            });
          } else {
            // Handle invalid credentials case
            message.error("Login failed with Google");
          }
        } else {
          // User does not exist, proceed with registration
          const userData = {
            username: data?.given_name,
            email: "",
            password: "google", // Assuming password for Google login
            encryptpass: null, // You may handle encryption as needed
            roleid: 0, // Set role as needed
            gender: 0, // Set gender as needed
            birthday: "2023-11-18T20:02:58.473Z", // Set birthday as needed
            facebookid: data?.sub,
            type: "google",
          };
          const response = await api.post(
            "/api/systems/User/set_user",
            userData
          );
          if (response.status === 200) {
            // Registration successful, proceed with login
            const token = await loginUser({
              username: data?.given_name,
              password: "google",
              type: "google",
            });
            if (token) {
              // Set the authentication token
              setToken(token);
              navigate("/", {
                state: { userid: userinfo?.userid },
              });
            } else {
              // Handle invalid credentials case
              message.error("Login failed with Google");
            }
          } else {
            // Handle registration error
            console.error("User registration failed:", response.statusText);
          }
        }
      }
    } catch (error) {
      console.error("An error occurred during user login:", error);
    }
  }, []);

  const handleBack = () => {
    // Implement the logic to navigate back to the home page
    navigate("/"); // Replace "/" with the actual path to your home page
  };

  return (
    <div className="registration-form-container">
      <Button
        type="text"
        icon={<ArrowLeftOutlined />} // Use the Arrow Left icon
        style={{
          position: "absolute",
          top: 20, // Adjust the top position as needed
          left: 20, // Adjust the left position as needed
          zIndex: 1, // Ensure the button appears above the form
        }}
        className="custom-back-button" // Apply the custom CSS class
        onClick={handleBack}
      >
        <img src="/assets/images/uju4.png" alt="Your Image" width="100" />
      </Button>
      <div className="registration-form-content">
        <h2>Бүртгүүлэх</h2>
        <p className="light text-center">
          Өөрийн сошиал хаяг ашиглан бүртгүүлэх боломжтой.
        </p>
        <div className="registration-social-buttons">
          <Space direction="horizontal">
            <LoginSocialGoogle
              ref={googleRef}
              client_id="1089983387918-5js90gudn8k4gpkbq3pecu1pgt0716it.apps.googleusercontent.com"
              onSuccess={responseGoogle}
              onResolve={({ provider, data }) => responseGoogle(data)}
              onReject={(err) => {
                console.log("hbhbdhd", err);
              }}
            >
              <GoogleLoginButton text="Google" size={40} />
            </LoginSocialGoogle>

            <LoginSocialFacebook
              appId={1262242787777446}
              onResolve={({ provider, data }) => onFacebookConnectSuccess(data)}
              onReject={(error) => {
                console.log(error);
              }}
            >
              <FacebookLoginButton
                className="registration-form-button-facebook"
                text="Facebook"
                size={40}
              />
            </LoginSocialFacebook>
          </Space>
        </div>

        <Form
          name="registration"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "Хэрэглэгчийн нэр оруулна уу!" },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Хэрэглэгчийн нэр"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "И-мэйл хаяг оруулна уу!" },
              { type: "email", message: "И-мэйл хаяг буруу байна." },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="И-мэйл"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Нууц үг оруулна уу!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Нууц үг"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Нууц үгийг давтана уу!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Нууц үг ижилхэн биш байна!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Нууц үг давтах"
            />
          </Form.Item>
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              { required: true, message: "Хүлээн зөвшөөрөх товчийг дарна уу!" },
            ]}
          >
            <Checkbox>Бүртгэл хийлгэхийг хүлээн зөвшөөрч байна.</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="registration-form-button"
            >
              Бүртгүүлэх
            </Button>
          </Form.Item>
        </Form>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="link" icon={<ArrowLeftOutlined />} href="/">
            Нүүр хуудас руу буцах
          </Button>
          <Button
            type="link"
            icon={<UserOutlined />}
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Нэвтрэх
          </Button>
        </div>
      </div>
    </div>
  );
}
