import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useUserInfo from "../../system/useUserInfo";
import { Card, Spin, Menu } from "antd";
import { api } from "../../system/api";

const { Meta } = Card;

const CategoryList = () => {
  const { id } = useParams();
  const { userinfo } = useUserInfo();
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openKeys, setOpenKeys] = useState([]);
  const [griddata, setGridData] = useState();
  const [loading, setLoading] = useState(true);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const fetchData = useCallback(async (categoryId) => {
    setLoading(true);
    try {
      const response = await api.get(`/api/Product/Get_category_Tree`);
      setCategoryData(response?.data?.retdata);

      const selectedCategory = findCategoryById(
        response?.data?.retdata,
        Number(categoryId)
      );

      if (selectedCategory) {
        setSelectedCategory(selectedCategory);

        // Update breadcrumb items
        const updatedBreadcrumbItems = [];
        let currentCategory = selectedCategory;
        while (currentCategory) {
          updatedBreadcrumbItems.unshift(currentCategory);
          currentCategory = findCategoryById(
            response?.data?.retdata,
            currentCategory?.parentid
          );
        }
        setBreadcrumbItems(updatedBreadcrumbItems);
      } else {
        setSelectedCategory(categoryData.children);
        setBreadcrumbItems([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, []);

  const findCategoryById = (categories, categoryId) => {
    for (const category of categories) {
      if (category.id === categoryId) {
        return category;
      }

      if (category.children) {
        const foundChild = findCategoryById(category.children, categoryId);
        if (foundChild) {
          return foundChild;
        }
      }
    }

    return null;
  };

  useEffect(() => {
    fetchData(id);
    api.get(`/api/Product/get_Item_list?categoryid=${id}`).then((res) => {
      if (res?.status === 200 && res?.data?.rettype === 0) {
        setGridData(res?.data?.retdata);
      }
    });
  }, [fetchData, id, userinfo.userid]);

  const renderMenuItems = (categories) => {
    return categories?.children?.map((category) => {
      if (category?.children && category?.children?.length > 0) {
        const subMenuTitle = (
          <span>
            <span onClick={() => handleMenuClick(category)}>
              {category?.name}
            </span>
            {openKeys?.includes(category?.key)}
          </span>
        );

        return (
          <Menu.SubMenu key={category?.key} title={subMenuTitle}>
            {renderMenuItems(category)}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item
            key={category?.key}
            onClick={() => handleMenuClick(category)}
          >
            {category?.name}
          </Menu.Item>
        );
      }
    });
  };

  const handleMenuClick = (clickedCategory) => {
    // Update breadcrumb items
    const updatedBreadcrumbItems = [];
    let currentCategory = clickedCategory;
    while (currentCategory) {
      updatedBreadcrumbItems.unshift(currentCategory);
      currentCategory = findCategoryById(
        categoryData,
        currentCategory?.parentid
      );
    }
    setBreadcrumbItems(updatedBreadcrumbItems);

    // Use useHistory to navigate to the new URL
    navigate(`/category/${clickedCategory.id}`);
  };

  return (
    <>
      {loading ? (
        <Spin size="default" />
      ) : (
        <>
          <h3>{selectedCategory?.name}</h3>
          <Menu
            loading={loading}
            style={{
              width: 256,
              color: "black",
            }}
            mode="inline"
            defaultSelectedKeys={[id]}
            defaultOpenKeys={openKeys}
            inlineCollapsed
          >
            {renderMenuItems(categoryData[0])}
          </Menu>
        </>
      )}
    </>
  );
};

export default CategoryList;
