// src/pages/Dashboard.js
import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Image,
  Button,
  Dropdown,
  Avatar,
  Typography,
  Space,
  Divider,
} from "antd";
import {
  DesktopOutlined,
  FileOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import useUserInfo from "../../../system/useUserInfo";
import CategoryOfProduct from "../../../components/Admin/Category";
import Product from "../../../components/Admin/Product";
import Tags from "../../../components/Admin/Tag";
import TagRelations from "../../../components/Admin/TagRelations";
import OrderPage from "../../../components/Admin/Order";
const { Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export default function Admin() {
  const { userinfo } = useUserInfo();
  const [selectedMenu, setSelectedMenu] = useState("5");
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (e) => {
    if (isMobile) {
      setCollapsed(true);
    }
  };

  const handleLogout = () => {
    // Implement your logout logic here
    // For example, clear user session/token and navigate to the login page
    navigate("/login");
  };

  // Mock user data for demonstration
  const user = {
    name: "John Doe",
    // Add more user-related information as needed
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={isMobile ? !collapsed : collapsed}
          onCollapse={toggleCollapsed}
          theme="light"
        >
          <div className="logo" />
          <Link to="/">
            <Space className="fa">
              <Image
                src="/assets/images/uju4.png"
                preview={false}
                width={100}
              />
            </Space>
          </Link>
          <Divider className="pm-5 m10" />
          <Menu
            theme="light"
            defaultOpenKeys={["1"]}
            defaultSelectedKeys={["1"]}
            mode={"inline"}
            onClick={handleMenuClick}
          >
            <Menu.Item
              key="1"
              icon={<DesktopOutlined />}
              onClick={() => setSelectedMenu("1")}
            >
              Категори
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<DesktopOutlined />}
              onClick={() => setSelectedMenu("2")}
            >
              Бараа
            </Menu.Item>
            <Menu.Item
              key="5"
              icon={<FileOutlined />}
              onClick={() => setSelectedMenu("5")}
            >
              Захиалга
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<FileOutlined />}
              onClick={() => setSelectedMenu("3")}
            >
              Барааны TAGs
            </Menu.Item>
            <Menu.Item
              key="4"
              icon={<FileOutlined />}
              onClick={() => setSelectedMenu("4")}
            >
              Барааны хамаарал
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background header-light"
            style={{
              paddingLeft: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Avatar src="./assets/images/uju4.png" />
              <Dropdown overlay={menu}>
                <Button type="text" style={{ color: "#1890ff" }}>
                  {userinfo?.username} {/* Display the user's name */}
                </Button>
              </Dropdown>
            </div>
          </Header>

          <Content style={{ margin: "0 16px" }}>
            {selectedMenu === "1" && <CategoryOfProduct />}
            {selectedMenu === "2" && <Product />}
            {selectedMenu === "3" && <Tags />}
            {selectedMenu === "4" && <TagRelations />}
            {selectedMenu === "5" && <OrderPage />}
          </Content>
          <Footer style={{ textAlign: "center" }}>uju.mn ©2023</Footer>
        </Layout>
      </Layout>
    </>
  );
}
