import React, { useEffect, useState } from "react";
import { Card, List, Steps, Space, Tag, Avatar } from "antd";
import moment from "moment";
import { StopOutlined, SmileOutlined } from "@ant-design/icons"; // Add this import statement
import { getBaseURL } from "../../system/apiconfig";

export default function DeliveredOrderTab({ griddata, showDrawer }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return isMobile ? (
    <List
      itemLayout="horizontal"
      dataSource={griddata || []}
      pagination
      renderItem={(item, index) => (
        <>
          <Card
            bordered
            style={{
              padding: 0,
              margin: 5,
              minHeight: 10,
            }}
            headStyle={
              item?.status === 0 && moment().diff(item?.orderdate, "days") <= 1
                ? { minHeight: 40, backgroundColor: "#f9f9f9" }
                : item?.status >= 2
                ? { minHeight: 40, backgroundColor: "#bee8a0" }
                : item?.status === 0 &&
                  moment().diff(item?.orderdate, "days") >= 1
                ? { minHeight: 40, backgroundColor: "#f9f9f9" }
                : null
            }
            onClick={() => showDrawer(item)}
            title={
              <Space>
                {item?.status === 1 &&
                moment().diff(item?.orderdate, "minutes") <= 30 ? (
                  <span className="f12">Төлбөр хүлээгдэж байна.</span>
                ) : item?.status === -1 &&
                  moment().diff(item?.orderdate, "minutes") >= 30 ? (
                  <span className="f12">
                    <StopOutlined
                      style={{
                        marginRight: 5,
                        opacity: 0.5,
                        fontSize: 14,
                      }}
                    />
                    Захиалга цуцлагдсан
                  </span>
                ) : (
                  ""
                )}
                {item?.status >= 2 && item?.status <= 5 ? (
                  <span className="f12">
                    <SmileOutlined
                      style={{
                        marginRight: 5,
                        opacity: 0.5,
                        fontSize: 14,
                      }}
                    />
                    Төлбөр төлөгдсөн
                  </span>
                ) : item?.status >= 6 ? (
                  <span className="f12">
                    <SmileOutlined
                      style={{
                        marginRight: 5,
                        opacity: 0.5,
                        fontSize: 14,
                      }}
                    />
                    Захиалга хүргэгдсэн
                  </span>
                ) : null}
              </Space>
            }
          >
            <List.Item>
              <List.Item.Meta
                title={
                  <span className="f12">Захиалгын дугаар: {item?.id}</span>
                }
                description={
                  <span className="f12">
                    {moment(item?.orderdate).format("YYYY/MM/DD HH:mm ")}
                  </span>
                }
              />
              <Space
                direction="vertical"
                style={{ lineHeight: 0.7, alignItems: "center" }}
              >
                Дүн <b>{(item?.totalprice).toLocaleString()}₮</b>
              </Space>
            </List.Item>
          </Card>
        </>
      )}
    />
  ) : (
    <List
      itemLayout="horizontal"
      pagination
      dataSource={griddata || []}
      renderItem={(item, index) => (
        <>
          <Card
            bordered
            style={{
              padding: 10,
              margin: 10,
              borderLeft:
                item?.status === 0 ||
                (item?.status === 1 &&
                  moment().diff(item?.orderdate, "minutes") <= 30)
                  ? "10px solid #f9f9f9"
                  : item?.status >= 2
                  ? "10px solid #bee8a0"
                  : item?.status === 0 ||
                    item?.status === 1 ||
                    (item?.status === -1 &&
                      moment().diff(item?.orderdate, "minutes") >= 30)
                  ? "10px solid #ffbb96"
                  : null,
            }}
            onClick={() => showDrawer(item)}
          >
            <List.Item
              actions={[
                <Space key={index}>
                  {item?.status === 0 &&
                  moment().diff(item?.orderdate, "minutes") <= 30 ? (
                    <Tag
                      color="default"
                      style={{
                        whiteSpace: "normal",
                        display: "inline-block",
                      }}
                    >
                      Төлбөр хүлээгдэж байна.
                    </Tag>
                  ) : item?.status === -1 &&
                    moment().diff(item?.orderdate, "minutes") >= 30 ? (
                    <Tag
                      color="default"
                      style={{
                        whiteSpace: "normal",
                        display: "inline-block",
                      }}
                    >
                      <StopOutlined
                        style={{
                          marginRight: 5,
                          opacity: 0.5,
                          fontSize: 14,
                        }}
                      />
                      Захиалга цуцлагдсан
                    </Tag>
                  ) : item?.status >= 2 && item?.status <= 5 ? (
                    <>
                      <Tag color="success">
                        <SmileOutlined
                          style={{
                            marginRight: 5,
                            opacity: 0.5,
                            fontSize: 14,
                          }}
                        />
                        Төлбөр төлөгдсөн
                      </Tag>
                    </>
                  ) : item?.status >= 6 ? (
                    <>
                      <Tag color="success">
                        <SmileOutlined
                          style={{
                            marginRight: 5,
                            opacity: 0.5,
                            fontSize: 14,
                          }}
                        />
                        Захиалгыг амжилттай хүргэсэн
                      </Tag>
                    </>
                  ) : (
                    ""
                  )}
                </Space>,
              ]}
            >
              <List.Item.Meta
                title={<>Захиалгын дугаар: {item?.id}</>}
                description={`${moment(item?.orderdate).format(
                  "YYYY/MM/DD HH:mm"
                )}`}
              />
              <Space style={{ flex: 1 }}>
                <Avatar
                  src={`${getBaseURL()}/api/file/download?ID=${
                    item?.images[item?.images?.length - 1]
                  }&size=50`}
                  size={"large"}
                />
              </Space>
              <Space direction="vertical" style={{ flex: 1, lineHeight: 0.7 }}>
                Дүн <b>{(item?.totalprice).toLocaleString()}₮</b>
              </Space>
            </List.Item>
          </Card>
        </>
      )}
    />
  );
}
