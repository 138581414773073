import React from "react";
import { Collapse } from "antd";
const PrivacyPolicy = () => {
  const items = [
    {
      key: "1",
      label: "Мэдээллийг Цуглуулах",
      children: (
        <ul>
          <li>
            Хувийн мэдээлэл: Бидний үйлчилгээг ашиглаж байгаа хугацаа, имэйл
            хаяг, бусад холбоо барих мэдээлэл гэх мэтчлэн мэдээллийг та өөрийн
            сонирхолтой бөглөөнө. Ашиглалтын Мэдээлэл: Та бидний үйлчилгээнийхээ
            харилцааг хэрхэн харилцаж буй талаарх мэдээллийг цуглуулж болно,
            таны IP хаяг, хөтөчийн төрөл, хуудсуудыг зочилж, хэзээ ч, ямар өдөр
            явж буй талаарх мэдээллийг агуулж болно. Күкий: Бид күкийг ашиглаж,
            таны вэбсайтын туршид сургалтын датагаа цуглуулах ба таны туршлагыг
            сайжруулахын тулд цуглуулга хийдэг.
          </li>
          <li>
            Ашиглалтын Мэдээлэл: Та бидний үйлчилгээнийхээ харилцааг хэрхэн
            харилцаж буй талаарх мэдээллийг цуглуулж болно, таны IP хаяг,
            хөтөчийн төрөл, хуудсуудыг зочилж, хэзээ ч, ямар өдөр явж буй
            талаарх мэдээллийг агуулж болно.
          </li>
          <li>
            Күкий: Бид күкийг ашиглаж, таны вэбсайтын туршид сургалтын датагаа
            цуглуулах ба таны туршлагыг сайжруулахын тулд цуглуулга хийдэг.
          </li>
        </ul>
      ),
    },
    {
      key: "2",
      label: "Таны мэдээллийг Хэрхэн Ашиглах",
      children: (
        <ul>
          <li>
            Бид цуглуулж буй мэдээллийг үйлчилгээ, ашиглах, сайжруулах болон
            таны туршид нээлттэй хийхийг зориулж ашиглаж болно.
          </li>
          <li>
            Таны мэдээлэл ангиллын мэдээлэл, хариуцлага өгөх, санал хүсэлтүүдэд
            хариулах, шинэчлэлтээ илгээх ба сурталчилгааны материал илгээхийг
            зориулан ашигладаг болно.
          </li>
          <li>
            Бид күкийг аутентификац, аюулгүй байдлыг, сургалтын, сурталчилгааны
            ажиллагаанд хэрэглэдэг болон сургалтын агуулгыг сайжруулахын тулд
            ашиглаж болно.
          </li>
        </ul>
      ),
    },
    {
      key: "3",
      label: "Мэдээллийг Хуваалцах",
      children: (
        <ul>
          <li>
            Бид таны хувийн мэдээллийг гадны үйлчилгээний орчинд ашиглаж,
            үйлчилгээ үргэлжлүүлэхийг хариуцдаггүй.
          </li>
          <li>
            Таны мэдээллийг үйлчилгээний үйлчилгээтэй бидэнд тусалж байгаа
            үйлчилгээний агентлаг, вэбсайтын ажилчдад хувийн мэдээллийг
            хүргүүлэхэд ашигладаг.
          </li>
          <li>
            Манай бусад байгууллагуудын зорилго, үйлчилгээ, эрсдэлийн эсвэл
            буруу үйл ажиллагааны хүсэлтүүдэд таны мэдээллийг нээж байна.
          </li>
        </ul>
      ),
    },
    {
      key: "4",
      label: "Таны Сонголт",
      children: (
        <ul>
          <li>
            Та хувийн мэдээллийн цуглуулга, ашиглалтыг (күкийг) гэрчлээд
            өөрчлөхэд өөрчлөхийг сонгоно.
          </li>
          <li>
            Та бидэнд холбогдох, шинэчлэл, устгах зэрэг байгууллагын хариуцах
            эрхтэй.
          </li>
        </ul>
      ),
    },
    {
      key: "5",
      label: "Бидэнтэй Холбоо Барих",
      children: (
        <ul>
          <li>
            Хэрэв танд энэ нууцлалын бодлогын талаар асуудал эсвэл асуудал гарч
            байвал, бидний холбоо барих хаяг руу info@uju.mn хаяг руу холбогдоно
            уу.
          </li>
        </ul>
      ),
    },
  ];
  return (
    <div>
      <div style={{ maxWidth: 1200, margin: "0 auto" }}>
        <h2>Нууцлалын бодлого</h2>
        <Collapse items={items} defaultActiveKey={["1"]} />;
      </div>
    </div>
  );
};

export default PrivacyPolicy;
