import React, { useState, useCallback, useRef } from "react";
import { Table, Input, Button, DatePicker, Space, message, Flex } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { api } from "../../../system/api";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function OrderPage() {
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("hour"));
  const [endDate, setEndDate] = useState(moment().endOf("hour"));
  const [count, setCount] = useState();

  const searchInput = useRef(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const request = {
      object_type: "INVOICE",
      object_id: "INVOICE",
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      offset: {
        page_number: 1,
        page_limit: 100,
      },
    };

    try {
      const res = await api.post(`/api/Product/qpay_payment_list`, request);
      if (res?.status === 200 && res?.data?.rettype === 0) {
        setCount(res?.data?.retdata?.count || 0);
        const dataWithIndex = res?.data?.retdata?.rows?.map((item, index) => ({
          ...item,
          index: index + 1, // Adding an index property
        }));
        setGridData(dataWithIndex);
      } else {
        message.error("Failed to fetch data");
      }
    } catch (error) {
      message.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  const handleDateChange = (dates) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Payment ID",
      dataIndex: "payment_id",
      key: "payment_id",
      ...getColumnSearchProps("payment_id"),
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      sorter: (a, b) => new Date(a.payment_date) - new Date(b.payment_date),
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Status",
      dataIndex: "payment_status",
      key: "payment_status",
      ...getColumnSearchProps("payment_status"),
    },
    {
      title: "Fee",
      dataIndex: "payment_fee",
      key: "payment_fee",
    },
    {
      title: "Amount",
      dataIndex: "payment_amount",
      key: "payment_amount",
    },
    {
      title: "Currency",
      dataIndex: "payment_currency",
      key: "payment_currency",
    },
    {
      title: "Wallet",
      dataIndex: "payment_wallet",
      key: "payment_wallet",
    },
    {
      title: "Name",
      dataIndex: "payment_name",
      key: "payment_name",
      ...getColumnSearchProps("payment_name"),
    },
    {
      title: "Description",
      dataIndex: "payment_description",
      key: "payment_description",
      ...getColumnSearchProps("payment_description"),
    },
    {
      title: "Paid By",
      dataIndex: "paid_by",
      key: "paid_by",
    },
    {
      title: "Object Type",
      dataIndex: "object_type",
      key: "object_type",
    },
    {
      title: "Object ID",
      dataIndex: "object_id",
      key: "object_id",
    },
  ];

  return (
    <>
      <Flex style={{ marginBottom: 16 }} align="center" justify="space-between">
        <span>
          <RangePicker
            defaultValue={[startDate, endDate]}
            onChange={handleDateChange}
            format="YYYY-MM-DD HH:mm"
            showTime={{ format: "HH:mm" }}
          />
          <Button type="primary" onClick={fetchData} loading={loading}>
            Fetch Data
          </Button>
        </span>
        <span>{count !== undefined && <b>Мөрийн тоо: {count}</b>}</span>
      </Flex>
      <Table
        dataSource={gridData}
        columns={columns}
        rowKey="payment_id"
        loading={loading}
        pagination={{ pageSize: 50 }}
      />
    </>
  );
}
