import React, { useState, useEffect } from "react";
import { Card, Descriptions, Image, Divider, List, Alert } from "antd";

import { getBaseURL } from "../../system/apiconfig";
import useUserInfo from "../../system/useUserInfo";
import { api } from "../../system/api";
import { useAppContext } from "../../Context/index";

export default function OrderProducts() {
  const { state } = useAppContext();
  const [cartItems, setCartItems] = useState();
  const [loading, setLoading] = useState(true);
  const { userinfo } = useUserInfo();
  const [userAddress, setUserAddress] = useState(
    state?.orderState?.userAddress
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchCartData = async () => {
      if (userinfo?.userid) {
        try {
          setLoading(true);
          const response = await api.get(
            `/api/product/get_shoppingcart?userid=${userinfo?.userid}`
          );
          if (response?.status === 200 && response?.data) {
            setCartItems(response?.data?.retdata);
          } else {
            console.error("Failed to fetch cart data:", response);
          }
        } catch (error) {
          console.error("Error fetching cart data:", error);
        } finally {
          setLoading(false); // Set loading to false whether the request succeeds or fails
        }
      }
    };
    fetchCartData();

    // Fetch cart data only when the component mounts
  }, [userinfo?.userid]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ paddingTop: 20 }}>
      <Descriptions
        title={<b>ХҮРГЭЛТИЙН МЭДЭЭЛЭЛ</b>}
        bordered
        column={isMobile ? 1 : 2}
      >
        <Descriptions.Item label="Овог">
          {userAddress?.firstname}
        </Descriptions.Item>
        <Descriptions.Item label="Нэр">
          {userAddress?.lastname}
        </Descriptions.Item>
        <Descriptions.Item label="Утас">{userAddress?.phone}</Descriptions.Item>
        <Descriptions.Item label="Утас 2">
          {userAddress?.phone2}
        </Descriptions.Item>
        <Descriptions.Item label="Нийслэл/Орон нутаг">
          {userAddress?.province}
        </Descriptions.Item>
        <Descriptions.Item label="Дүүрэг/Аймаг">
          {userAddress?.district}
        </Descriptions.Item>
        <Descriptions.Item label="Хаяг">
          {userAddress?.address}
        </Descriptions.Item>
      </Descriptions>
      <h3>И-БАРИМТЫН МЭДЭЭЛЭЛ</h3>
      <Alert
        message={
          <>
            {userAddress?.regno === null
              ? "И-баримт хувь хүн дээр авах хүсэлтээ өгсөн байна."
              : `И-баримт: ${userAddress?.regno} байгууллагын регистр дээр авах хүсэлт өгсөн байна. `}
          </>
        }
        type="warning"
        showIcon
      />
      <h3>БАРАА БАТАЛГААЖУУЛАХ</h3>
      <Divider />
      {cartItems?.map((item) => (
        <Card
          bordered
          key={item.id}
          loading={loading}
          title={item?.name}
          type="inner"
          style={{ marginBottom: 10 }}
        >
          {item?.children !== undefined || item?.children?.length > 0 ? (
            <>
              <List
                itemLayout="horizontal"
                dataSource={item?.children}
                className="mlr10"
                renderItem={(itemType, index) => (
                  <List.Item>
                    <List.Item.Meta
                      key={index}
                      avatar={
                        <Image
                          src={`${getBaseURL()}/api/file/download?ID=${
                            item?.images[0]?.id
                          }&size=60`}
                          width={60}
                        />
                      }
                      title={itemType?.itemtype}
                      description={
                        <>
                          <h4 className="product-price">
                            {(itemType?.price?.calcprice).toLocaleString()}₮ x{" "}
                            {itemType?.quantity} ш ={" "}
                            {(
                              itemType?.price?.calcprice * itemType?.quantity
                            ).toLocaleString()}
                            ₮
                          </h4>
                        </>
                      }
                    ></List.Item.Meta>
                  </List.Item>
                )}
              ></List>
            </>
          ) : (
            ""
          )}
        </Card>
      ))}
    </div>
  );
}
