import React, { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import HomePage from "./pages/Home";
import CartPage from "./pages/Cart";
import ProductDetail from "./pages/ProductDetail";
import HeaderMenu from "./components/Header";
import FooterMenu from "./components/Footer";
import Users from "./components/Account";
import CollectionPage from "./pages/Collections";
import Admin from "./pages/Admin/Home";
import CategoryAndProductLayout from "./components/Category/Layout";
import { AppProvider } from "./Context/index";
import Tag from "./pages/Tags";
import CheckOutPage from "./pages/Checkout";
import ResetPassword from "./pages/Reset-Password";
import PrivacyPolicy from "./pages/Privacy";
import TermsOfService from "./pages/Terms";
import SearchComponent from "./components/Search";
import { Helmet } from "react-helmet";
import PasswordRecovery from "./pages/PasswordRecovery";
import NotFound from "./pages/NotFound";

export default function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showHeaderFooter, setShowHeaderFooter] = useState();
  const [showFooter, setShowFooter] = useState();
  // Define a boolean variable to determine whether to show the header and footer

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleRouteChange = () => {
      const currentPath = window.location.pathname;
      const isLoginPage =
        currentPath === "/login" ||
        currentPath === "/register" ||
        currentPath === "/reset-password" ||
        currentPath === "/passwordRecovery" ||
        currentPath === "/sys-admin";

      setIsMobile(window.innerWidth <= 768);
      setShowHeaderFooter(!isLoginPage);
      setShowFooter(
        currentPath === "/" ||
          currentPath === "/account" ||
          currentPath === "/account/order" ||
          currentPath === "/account/profile" ||
          currentPath === "/account/config" ||
          currentPath === "/account/"
      );
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("popstate", handleRouteChange); // Listen for back/forward navigation
    handleRouteChange(); // Call the function once to set the initial state

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [showFooter, showHeaderFooter]);

  return (
    <>
      <Helmet>
        <meta name="og:description" content="Basic description" />
        <meta name="og:title" content="Basic title" />
        <meta name="og:image" content="%PUBLIC_URL%/assets/images/uju4.png" />
      </Helmet>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#fa541c",
            colorInfo: "#fa541c",
            colorSuccess: "#52c41a",
            colorWarning: "#faad14",
          },
          components: {
            Rate: {
              colorPrimary: "#fa541c",
              colorFill: "#fa541c",
              colorText: "#fa541c",
            },
          },
        }}
      >
        <AppProvider>
          <BrowserRouter>
            {showHeaderFooter && <HeaderMenu />}
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/forgot-password" element={<Register />} />
              <Route exact path="/cart" element={<CartPage />} />
              <Route exact path="/product/:id" element={<ProductDetail />} />
              <Route exact path="/account" element={<Users />} />
              <Route exact path="/account/order" element={<Users />} />
              <Route exact path="/account/profile" element={<Users />} />
              <Route exact path="/account/config" element={<Users />} />
              <Route exact path="/collections" element={<CollectionPage />} />
              <Route exact path="/checkout" element={<CheckOutPage />} />
              <Route exact path="/sys-admin" element={<Admin />} />
              <Route exact path="/tag/:id" element={<Tag />} />
              <Route exact path="/terms" element={<TermsOfService />} />
              <Route exact path="/privacy" element={<PrivacyPolicy />} />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/search" element={<SearchComponent />} />
              <Route
                exact
                path="/PasswordRecovery"
                element={<PasswordRecovery />}
              />
              <Route
                exact
                path="/category/:id"
                element={<CategoryAndProductLayout />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
            {showFooter && isMobile && <FooterMenu />}
          </BrowserRouter>
        </AppProvider>
      </ConfigProvider>
    </>
  );
}

// import React, { useEffect, useState } from "react";
// import { ConfigProvider } from "antd";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import "./App.css";
// import Login from "./pages/Login";
// import Register from "./pages/Register";
// import HomePage from "./pages/Home";
// import CartPage from "./pages/Cart";
// import ProductDetail from "./pages/ProductDetail";
// import HeaderMenu from "./components/Header";
// import FooterMenu from "./components/Footer";
// import Users from "./components/Account";
// import CollectionPage from "./pages/Collections";
// import Admin from "./pages/Admin/Home";
// import CategoryAndProductLayout from "./components/Category/Layout";
// import { AppProvider } from "./Context/index";
// import Tag from "./pages/Tags";
// import CheckOutPage from "./pages/Checkout";
// import ComingSoonPage from "./pages/Home/coming";

// export default function App() {
// const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
// const [showHeaderFooter, setShowHeaderFooter] = useState();
// const [showFooter, setShowFooter] = useState();
// // Define a boolean variable to determine whether to show the header and footer

// useEffect(() => {
//   const handleResize = () => {
//     setIsMobile(window.innerWidth <= 768);
//   };

//   const handleRouteChange = () => {
//     const currentPath = window.location.pathname;
//     const isLoginPage =
//       currentPath === "/login" ||
//       currentPath === "/register" ||
//       currentPath === "/sys-admin" ||
//       currentPath === "/";
//     setIsMobile(window.innerWidth <= 768);
//     setShowHeaderFooter(!isLoginPage);
//     setShowFooter(currentPath === "/" || currentPath === "/account");
//   };

//   window.addEventListener("resize", handleResize);
//   window.addEventListener("popstate", handleRouteChange); // Listen for back/forward navigation
//   handleRouteChange(); // Call the function once to set the initial state

//   return () => {
//     window.removeEventListener("resize", handleResize);
//     window.removeEventListener("popstate", handleRouteChange);
//   };
// }, [showFooter, showHeaderFooter]);

// return (
//   <ConfigProvider
//     theme={{
//       token: {
//         // Seed Token
//         colorPrimary: "#fa541c",
//         colorInfo: "#fa541c",
//         colorSuccess: "#52c41a",
//         colorWarning: "#faad14",
//       },
//       components: {
//         Rate: {
//           colorPrimary: "#fa541c",
//           colorFill: "#fa541c",
//           colorText: "#fa541c",
//         },
//       },
//     }}
//   >
//     <AppProvider>
//       <BrowserRouter>
//         <Routes>
//           <Route exact path="/" element={<ComingSoonPage />} />
//           <Route exact path="/home" element={<HomePage />} />
//           <Route exact path="/login" element={<Login />} />
//           <Route exact path="/register" element={<Register />} />
//           <Route exact path="/forgot-password" element={<Register />} />
//           <Route exact path="/cart" element={<CartPage />} />
//           <Route exact path="/product/:id" element={<ProductDetail />} />
//           <Route exact path="/account" element={<Users />} />
//           <Route exact path="/account/order" element={<Users />} />
//           <Route exact path="/account/profile" element={<Users />} />
//           <Route exact path="/account/config" element={<Users />} />
//           <Route exact path="/collections" element={<CollectionPage />} />
//           <Route exact path="/checkout" element={<CheckOutPage />} />
//           <Route exact path="/sys-admin" element={<Admin />} />
//           <Route exact path="/tag/:id" element={<Tag />} />
//           <Route
//             exact
//             path="/category/:id"
//             element={<CategoryAndProductLayout />}
//           />
//         </Routes>
//       </BrowserRouter>
//     </AppProvider>
//   </ConfigProvider>
// );
// }
