import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Table,
  Input,
  Drawer,
  Button,
  Form,
  Upload,
  Image,
  message as antMessage,
  Space,
  message,
  Modal,
  Divider,
  Select,
} from "antd";
import { api } from "../../../system/api";
import { getBaseURL } from "../../../system/apiconfig";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export default function Product() {
  const [griddata, setGridData] = useState();
  const [gridCategory, setGridCategory] = useState();
  const [loading, setLoading] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [getTagList, setGetTagList] = useState();
  const [selectedId, setSelectedId] = useState();
  const [form] = Form.useForm();
  const [formPrice] = Form.useForm();
  const [formDiscountPrice] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [sortInfo, setSortInfo] = useState({ columnKey: "", order: "" });
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);

  const { Option } = Select;
  const { TextArea } = Input;

  const fetchData = useCallback(async () => {
    setLoading(true);
    await api.get(`/api/Product/get_Item_list?isparent=true`).then((res) => {
      if (res?.status === 200 && res?.data?.rettype === 0) {
        setGridData(res?.data?.retdata);
        setSelectedId(res?.data?.retdata?.id);
      }
    });
    await api.get(`/api/Product/Get_category_Tree`).then((res) => {
      if (res?.status === 200 && res?.data?.rettype === 0) {
        setGridCategory(res?.data?.retdata[0]?.children);
      }
    });
    await api
      .get(`/api/Product/get_Tag_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.rettype === 0) {
          const tags = res?.data?.retdata;

          const formattedOptions = tags?.map((item) => ({
            value: item?.id,
            label: item?.name,
          }));
          setGetTagList(formattedOptions);
        }
      })
      .finally(() => {
        setLoading(false);
        // setDrawerVisible(false);
      });
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const formData = form?.getFieldsValue();
    const children = values?.children?.map((child) => ({
      id: child.id,
      itemType: child.itemtype,
      barcode: child.barcode,
      quantity: child.quantity,
      unitPrice: child.unitprice,
      discount: child.discount,
      discountEnd: child.discountend,
    }));

    const requestData = {
      id: values?.id,
      sameid: 0,
      isparent: true,
      categoryid: values?.categoryid,
      brandid: values?.brandid,
      delivery: values?.delivery,
      name: values?.name,
      barcode: values?.barcode,
      children: children,
      itemType: values?.children[0]?.itemtype,
      descbody: values?.descbody,
    };

    const res = await api.post(`/api/product/set_item`, requestData);

    if (res?.status === 200 && res?.data?.rettype === 0) {
      message.success("Амжилттай хадгаллаа.");
      setDrawerVisible(false);
      fetchData();
      const catId = res?.data?.retdata;

      if (values?.icon?.file || values?.bodyImage?.file) {
        customRequest(values?.icon?.fileList, catId);
        customRequest1(values?.bodyImage?.fileList1, catId);
        message.success("Icon and bodyImage were successfully uploaded");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const expandedRowRender = (record) => {
    const nestedColumns = [
      { title: "ID", dataIndex: "id", key: "id" },
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (text, record) =>
          record?.images && record?.images?.length > 0 ? (
            <div>
              <Image
                key={record?.images?.id} // Use the image id as the key
                src={`${getBaseURL()}/api/file/download?ID=${
                  record?.images[0]?.id
                }&type=10&size=50`}
                alt={`Image ${record?.images[0]?.id}`}
                style={{ maxWidth: "50px", marginRight: "5px" }}
              />
            </div>
          ) : null,
      },
      { title: "Item type", dataIndex: "itemtype", key: "itemtype" },
      { title: "Barcode", dataIndex: "barcode", key: "barcode" },
      { title: "Үлдэгдэл", dataIndex: "quantity", key: "quantity" },
      { title: "Зарагдсан тоо", dataIndex: "salecount", key: "salecount" },
      { title: "Дүн", dataIndex: "unitprice", key: "unitprice" },
    ];

    const nestedData = record?.children || [];

    return (
      <Table
        columns={nestedColumns}
        dataSource={nestedData}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortInfo.columnKey === "id" && sortInfo.order,
      ...getColumnSearchProps("id"),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) =>
        record?.images && record?.images?.length > 0 ? (
          <div>
            <Image
              key={record?.images?.id} // Use the image id as the key
              src={`${getBaseURL()}/api/file/download?ID=${
                record?.images[0]?.id
              }&type=10&size=50`}
              alt={`Image ${record?.images[0]?.id}`}
              style={{ maxWidth: "50px", marginRight: "5px" }}
            />
          </div>
        ) : null,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortInfo.columnKey === "name" && sortInfo.order,
      ...getColumnSearchProps("name"),
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => showDrawer(record)}
            style={{ textWrap: "pretty" }}
          >
            {text}
          </Button>
        </>
      ),
    },
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortInfo.columnKey === "categoryname" && sortInfo.order,
      ...getColumnSearchProps("categoryname"),
    },

    {
      title: "Description",
      dataIndex: "descbody",
      key: "descbody",
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortInfo.columnKey === "descbody" && sortInfo.order,
      ...getColumnSearchProps("descbody"),
    },

    {
      title: "brandname",
      dataIndex: "brandname",
      key: "brandname",
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortInfo.columnKey === "brandname" && sortInfo.order,
      ...getColumnSearchProps("brandname"),
    },

    {
      title: "delivery",
      dataIndex: "delivery",
      key: "delivery",
    },
  ];

  const newFormData = async () => {
    form.setFieldsValue({
      id: 0,
      categoryid: null,
      brandid: 0,
      delivery: 0,
      name: null,
      barcode: null,
      itemtype: null,
      descbody: null,
      isparent: true,
    });
    formDiscountPrice.setFieldsValue({
      id: 0,
      itemid: 0,
      begindate: null,
      enddate: null,
      discount: 0,
    });
    formPrice.setFieldsValue({
      id: 0,
      itemid: 0,
      begindate: null,
      enddate: null,
      price: 0,
    });

    setFileList(null);
    setFileList1(null);
    setDrawerVisible(true);
  };

  const showDrawer = (product) => {
    setSelectedProduct(product);
    setSelectedId(product?.id);
    const findCategoryById = (categoryId, categories) => {
      for (const category of categories) {
        if (category.id === categoryId) {
          return category;
        }

        if (category?.children && category?.children?.length > 0) {
          const foundInChild = findCategoryById(categoryId, category.children);
          if (foundInChild) {
            return foundInChild;
          }
        }
      }

      return null;
    };

    const selectedCategory = findCategoryById(
      product?.categoryid,
      gridCategory
    );
    form.setFieldsValue({
      id: product?.id,
      name: product?.name,
      categoryid: selectedCategory?.id,
      brandid: product?.brandid,
      itemtype: product.itemtype,
      children: product?.children || [],
      delivery: product?.delivery,
      barcode: product?.barcode,
      descbody: product?.descbody,
      isparent: true,
    });
    formPrice.setFieldsValue({
      price: product?.price?.price,
    });

    formDiscountPrice.setFieldsValue({
      discount: product?.price?.discount,
    });
    const iconFileList = (product?.images || [])
      .filter((image) => image.type === 1)
      .map((image) => ({
        uid: image.id,
        name: image.name,
        status: "done",
        url: `${getBaseURL()}/api/file/download?ID=${image.id}&size=150`,
      }));
    setFileList(iconFileList);

    // Set fileList state for bodyImage
    const bodyImageFileList = (product?.images || [])
      .filter((image) => image.type === 2)
      .map((image) => ({
        uid: image.id,
        name: image.name,
        status: "done",
        url: `${getBaseURL()}/api/file/download?ID=${image.id}&size=150`,
      }));
    setFileList1(bodyImageFileList);
    setDrawerVisible(true);
  };

  const customRequest = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post(
        `/api/file/upload?sourcetype=ecomitem&type=1&SourceIDs=${selectedId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success("Image uploaded successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error(
        "Error uploading image. Please check the console for details."
      );
    }
  };

  const customRequest1 = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post(
        `/api/file/upload?sourcetype=ecomitem&type=2&SourceIDs=${selectedId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success("Image uploaded successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error(
        "Error uploading image. Please check the console for details."
      );
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    // Update the state with the new fileList
    setFileList(newFileList);

    // Set preview information for the first file in the list
    if (newFileList?.length > 0) {
      setPreviewImage(newFileList[0].url || newFileList[0].thumbUrl);
    } else {
      setPreviewImage(null);
    }
  };
  const handleChange1 = ({ fileList: newFileList }) => {
    // Update the state with the new fileList
    setFileList1(newFileList);

    // Set preview information for the first file in the list
    if (newFileList?.length > 0) {
      setPreviewImage(newFileList[0].url || newFileList[0].thumbUrl);
    } else {
      setPreviewImage(null);
    }
  };

  const handleSetTags = async () => {
    try {
      // Make an API call to associate selected tags with selected items
      const response = await api.post("/api/Product/set_item_tag", {
        tagid: selectedRowKeys1,
        itemid: selectedRowKeys,
      });

      // Handle the response accordingly
      if (response?.status === 200 && response?.data?.rettype === 0) {
        message.success("Tags associated successfully");
      } else {
        message.error("Error associating tags:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error associating tags:", error);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectChange1 = (newSelectedRowKeys) => {
    setSelectedRowKeys1(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys?.length > 0;

  const renderOptions = (categories) => {
    return categories?.map((category) => (
      <Option key={category?.key} value={category?.key}>
        {category?.name}
      </Option>
    ));
  };

  const handleRemove = async (file) => {
    try {
      if (file && file.uid) {
        // Extract the file ID from the URL

        // Make an API request to delete the file
        await api.post(`/api/file/delete?ID=${file?.uid}`);

        // Update the fileList state by filtering out the removed file
        const updatedFileList = fileList.filter((f) => f.uid !== file.uid);
        setFileList(updatedFileList);

        message.success("File deleted successfully");
      }
    } catch (error) {
      console.error("File deletion failed:", error);
      message.error("Failed to delete file");
    }
  };

  const handleRemove1 = async (file) => {
    try {
      if (file && file.uid) {
        // Make an API request to delete the file
        await api.post(`/api/file/delete?ID=${file?.uid}`);

        const updatedFileList1 = fileList.filter((f) => f.uid !== file.uid);
        setFileList1(updatedFileList1);

        message.success("File deleted successfully");
      }
    } catch (error) {
      console.error("File deletion failed:", error);
      message.error("Failed to delete file");
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };


  return (
    <>
      <Button
        type="primary"
        onClick={(e) => newFormData()}
        style={{ marginBottom: 16 }}
      >
        Бараа нэмэх
      </Button>
      <span
        style={{
          marginLeft: 20,
        }}
      >
        {hasSelected ? (
          <>
            Selected {selectedRowKeys?.length} item
            <Select
              mode="tags"
              options={getTagList}
              placeholder="Please select"
              onChange={onSelectChange1}
              style={{
                width: "50%",
                marginLeft: 20,
              }}
            />
            <Button onClick={handleSetTags}>Бараа таг хоёр хамааруулах</Button>
          </>
        ) : (
          ""
        )}
      </span>

      <Drawer
        title="Add Product"
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width="90%"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            isparent: true,
            remember: true,
          }}
        >
          <Form.Item name="id" label="Дугаар" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="isparent" label="isparent" hidden={true}>
            <Input />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="categoryid"
                label="Category"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select a category"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {gridCategory?.map((category) => (
                    <React.Fragment key={category?.id}>
                      <Option key={category?.id} value={category?.id}>
                        {category?.name}
                      </Option>
                      {category?.children && renderOptions(category?.children)}
                    </React.Fragment>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="brandid"
                label="brandid"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>

            <div style={{ width: "48%" }}>
              <Form.Item
                name="barcode"
                label="barcode"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="delivery" label="delivery">
                <Input />
              </Form.Item>
              <Form.Item
                name="descbody"
                label="descbody"
                rules={[{ required: false }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </div>
          <Space>
            <Form.Item name="children" layout="horizontal">
              <h4>Ижил төстэй барааны мэдээлэл</h4>
              <Form.List name="children">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          label="ID"
                          {...restField}
                          name={[name, "id"]}
                          fieldKey={[fieldKey, "id"]}
                        >
                          <Input type="number" disabled />
                        </Form.Item>
                        <Form.Item
                          label="Item Type"
                          {...restField}
                          name={[name, "itemtype"]}
                          fieldKey={[fieldKey, "itemtype"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="barcode"
                          {...restField}
                          name={[name, "barcode"]}
                          fieldKey={[fieldKey, "barcode"]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          label="Quantity"
                          {...restField}
                          name={[name, "quantity"]}
                          fieldKey={[fieldKey, "quantity"]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          label="Unit Price"
                          {...restField}
                          name={[name, "unitprice"]}
                          fieldKey={[fieldKey, "unitprice"]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          label="Discount"
                          {...restField}
                          name={[name, "discount"]}
                          fieldKey={[fieldKey, "discount"]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          label="Discount End"
                          {...restField}
                          name={[name, "discountend"]}
                          fieldKey={[fieldKey, "discountend"]}
                        >
                          <Input type="datetime-local" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add Item
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Space>

          <Divider />
          <h4>Бүтээгдэхүүний slider зураг type=1</h4>
          <Space>
            <Form.Item label="Icon" name="icon">
              <Upload
                beforeUpload={(file) => {
                  if (fileList !== null) {
                    customRequest(file);
                    return false;
                  } else {
                    return true;
                  }
                }}
                listType="picture-card"
                multiple
                fileList={fileList}
                onRemove={(file) => handleRemove(file)}
                onChange={(info) => handleChange(info)}
                onPreview={(file) => handlePreview(file, "icon")}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item label="bodyImage" name="bodyImage">
              <Upload
                beforeUpload={(file) => {
                  if (fileList1 !== null) {
                    customRequest1(file);
                    return false;
                  } else {
                    return true;
                  }
                }}
                listType="picture-card"
                fileList={fileList1}
                onRemove={(file) => handleRemove1(file)}
                onChange={(info) => handleChange1(info)}
                onPreview={(file) => handlePreview(file, "bodyImage")}
                multiple
              >
                <Button icon={<UploadOutlined />}>Upload Icon</Button>
              </Upload>
            </Form.Item>
          </Space>
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" block loading={loading}>
              БАРАА ХАДГАЛАХ
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <Modal
        visible={previewVisible}
        title="Image Preview"
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <Table
        dataSource={griddata}
        columns={columns}
        rowKey="id"
        loading={loading}
        childrenColumnName="s"
        onChange={(pagination, filters, sorter) => setSortInfo(sorter)}
        pagination={{ pageSize: 50 }}
        expandable={{
          expandedRowRender: expandedRowRender,
          rowExpandable: (record) =>
            record.children && record.children.length > 0,
        }}
      />
    </>
  );
}
