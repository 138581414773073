import React from "react";
import { Collapse } from "antd";
const TermsOfService = () => {
  const items = [
    {
      key: "1",
      label: "Хэрэглэгчийн үйл ажиллагаа",
      children: (
        <ul>
          <li>
            Та зөвхөн энэ үйлчилгээг зөвшөөрөлтөөр ашиглах, эдгээр нөхцөлүүдийг
            баримтлан хэрэглэхийг зөвшөөрч байна.
          </li>
          <li>
            Та үйл ажиллагааны ажиллагааг хүчингүй болгон, үйл ажиллагаагийн
            ажиллагааг дутууруулахаар гарч ирэхээс зөвшөөрөхгүй.
          </li>
        </ul>
      ),
    },
    {
      key: "2",
      label: "Хувийн Бие Хамгаалалт",
      children: (
        <ul>
          <li>
            Вэбсайтын агуулга, материалууд (текст, график, лого, зурган зэрэг),
            uju.mn-д байршсан, эсвэл худалдаалагдаж буйг хамгаалдаг ба эдгээрээр
            авторматжуулагдсан зохиогч эсвэл гэрчилгээтэй байна.
          </li>
          <li>
            Бид танийг хариуцлага гаргаж, сайжруулах, таалагдсан агуулгыг
            ашиглахыг манай сайтад зөвшөөрдөггүй.
          </li>
        </ul>
      ),
    },
    {
      key: "3",
      label: "Хязгаарлалтын Ашиглалт",
      children: (
        <ul>
          <li>
            uju.mn үйлчилгээг ашиглах эсвэл түүнээс үнэмшилчид нь биелүүлэх,
            толилуулах эсвэл худалдан авч болно.
          </li>
          <li>
            Бид нь үйлчилгээгийн агуулгын бодлого, бүтэц, эсвэл худалдан авалтын
            хүсэлтийг баримталж, таны байгууллагад хамгаалалтыг хангаж байгаа
            эсэхийг шалгах эсэхийг зөвшөөрнө.
          </li>
        </ul>
      ),
    },
    {
      key: "4",
      label: "Нөхцөлүүдийг Шинэчлэх",
      children: (
        <ul>
          <li>
            Бид үйлчилгээний нөхцөлүүдийг өөрчлөх эсвэл шинэчлэхийн эрэмбээр энэ
            нөхцөлүүдийг яг хүлээн авч ажиллаж байна.
          </li>
          <li>
            Нөхцөл бүртээ аль хэдийн өөрчлөгдөж байгаа үед, үйлчилгээг ашиглаж
            үргэлжлүүлэхийг зөвшөөрнө.
          </li>
        </ul>
      ),
    },
    {
      key: "5",
      label: "Тодорхойлсон Харилцаа",
      children: (
        <ul>
          <li>
            Энэ нөхцөлүүдийн талаар асуудал, асуудал гарвал, бидний холбоо барих
            хаяг руу info@uju.mn хаяг руу холбогдоно уу.
          </li>
        </ul>
      ),
    },
  ];
  return (
    <div>
      <div style={{ maxWidth: 1200, margin: "0 auto" }}>
        <h2>Үйлчилгээний нөхцөл</h2>
        <Collapse items={items} defaultActiveKey={["1"]} />;
      </div>
    </div>
  );
};

export default TermsOfService;
