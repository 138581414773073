// src/pages/NotFound.js
import React from "react";
import { Result, Button } from "antd";

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Уучлаарай, Ийм хуудас байхгүй тул та нүүр хуудас руу буцах товчлуур дээр дарна уу."
      extra={
        <Button type="primary" href="/">
          Нүүр хуудас руу буцах
        </Button>
      }
    />
  );
};

export default NotFound;
