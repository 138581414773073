import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import "./style.css";
import ProductList from "../ProductList";
import CategoryList from ".";

const { Sider, Content } = Layout;

const CategoryAndProductLayout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <Layout style={{ maxWidth: 1200, margin: "0 auto" }}>
      {!isMobile ? (
        <Sider width={200} theme="light">
          <CategoryList />
        </Sider>
      ) : null}
      <Layout>
        <Content
          style={{
            paddingLeft: !isMobile ? "100px" : "0px",
            background: "white",
          }}
        >
          <ProductList />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CategoryAndProductLayout;
