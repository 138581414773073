import React, { useState, useEffect, useCallback } from "react";
import {
  Menu,
  Tabs,
  Card,
  Space,
  Upload,
  message,
  Avatar,
  Row,
  Col,
  Spin,
} from "antd";
import "./style.css";
import { api } from "../../system/api";
import { getBaseURL } from "../../system/apiconfig";
import {
  UserOutlined,
  SettingOutlined,
  UploadOutlined,
  ShareAltOutlined,
  SmileTwoTone,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import useToken from "../../system/useToken";
import { useAppContext } from "../../Context/index";
import Order from "./Order";
import MemberInfo from "./MemberInfo";
import UpdatePassword from "./updatePassword";
import FavoriteItemsTab from "./FavoriteItems";
const { TabPane } = Tabs;

export default function Users() {
  const { state } = useAppContext();
  const { token } = useToken();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [griddata, setGridData] = useState();

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (state?.userid === undefined) window.location.reload();
    if (state?.userid > 0) {
      await api
        .get(`/api/systems/User/get_user?userid=${state?.userid}`)
        .then((res) => {
          if (res?.status === 200 && res?.data?.rettype === 0) {
            setGridData(res?.data?.retdata);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [state?.userid]);

  useEffect(() => {
    token ? fetchData() : window.location.assign("/");
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    const { pathname } = location;
    setLoading(false);

    if (pathname.includes("/account/profile")) {
      setSelectedMenuItem("1");
    } else if (pathname.includes("/account/config")) {
      setSelectedMenuItem("2");
    } else if (pathname.includes("/account/order")) {
      setSelectedMenuItem("3");
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location, state, token, fetchData]);

  const handleMenuClick = ({ key }) => {
    setSelectedMenuItem(key);
  };
  const onUploadChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <div>
      {isMobile ? (
        <Menu
          mode="horizontal"
          theme="light"
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedMenuItem]}
          onSelect={handleMenuClick}
          style={{
            width: "100%",
            justifyContent: "center",
            lineHeight: 4.5, // Resetting lineHeight to normal
          }}
          className="sp-menu"
        >
          <Menu.Item icon={<UserOutlined />} key="1" className="f11">
            Профайл
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />} key="3" className="f11">
            Миний захиалга
          </Menu.Item>
          <Menu.Item icon={<SettingOutlined />} key="2" className="f11">
            Тохиргоо
          </Menu.Item>
        </Menu>
      ) : (
        <Menu
          mode="horizontal"
          theme="light"
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedMenuItem]}
          onSelect={handleMenuClick}
          style={{ width: "100%", justifyContent: "center", lineHeight: 4.5 }}
          className="sp-menu"
          size="small"
        >
          <Menu.Item icon={<UserOutlined />} key="1">
            Профайл
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />} key="3">
            Миний захиалга
          </Menu.Item>
          <Menu.Item icon={<SettingOutlined />} key="2">
            Тохиргоо
          </Menu.Item>
        </Menu>
      )}
      <div>
        <div className="menu-content">
          {selectedMenuItem === "1" && (
            <Tabs className="st-menu" style={{ lineHeight: 2.5 }}>
              <TabPane tab="Үндсэн" key="1" className="tab-title">
                <div style={{ maxWidth: 1200, margin: "0 auto" }}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Card
                        className="user-profile"
                        style={{ width: 300, padding: 10 }}
                        actions={[
                          <Space>
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 512.000000 512.000000"
                              preserveAspectRatio="xMidYMid meet"
                              fill="#FA541B"
                            >
                              <g
                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                fill="#FA541B"
                                stroke="none"
                              >
                                <path
                                  fill="#FA541B"
                                  d="M1253 4690 c-212 -56 -371 -221 -418 -435 -14 -62 -15 -272 -13
-1820 l3 -1750 29 -58 c83 -163 281 -238 436 -166 30 15 93 60 140 101 96 85
546 483 843 747 109 97 213 184 230 194 38 21 82 22 119 3 15 -8 277 -236 583
-508 629 -559 636 -563 764 -562 134 1 243 70 303 192 l33 67 3 1730 c2 1192
-1 1750 -8 1795 -38 235 -220 427 -447 475 -57 12 -265 15 -1298 14 -1170 0
-1233 -1 -1302 -19z m2596 -207 c111 -45 180 -114 223 -221 l23 -57 3 -1713
c1 -1123 -1 -1726 -8 -1751 -14 -53 -59 -92 -113 -98 -24 -3 -54 0 -66 6 -12
7 -276 237 -588 513 -424 375 -581 507 -621 526 -105 48 -236 38 -329 -25 -22
-15 -287 -246 -589 -513 -301 -267 -557 -490 -568 -497 -12 -7 -40 -12 -63
-13 -53 0 -108 44 -123 98 -14 51 -14 3379 0 3452 31 162 151 283 308 310 26
4 589 7 1252 6 l1205 -1 54 -22z"
                                />
                              </g>
                            </svg>
                            <span>
                              <b> {state?.favoriteItems?.length}</b> бараа
                            </span>
                          </Space>,
                          <Space>
                            <SmileTwoTone style={{ fontSize: "25px" }} />
                            <span>
                              <b>
                                {state?.userpoint?.length === 0 ||
                                state?.userpoint === undefined
                                  ? 0
                                  : state?.userpoint}
                              </b>{" "}
                              оноо
                            </span>
                          </Space>,
                        ]}
                      >
                        <ShareAltOutlined
                          style={{
                            fontSize: 24,
                            color: "gray",
                            position: "absolute",
                            top: 10,
                            right: 10,
                          }}
                        />
                        <div className="avatar-img">
                          <Avatar
                            size={120}
                            icon={
                              griddata?.images === null ||
                              griddata?.images === undefined ? (
                                <UserOutlined />
                              ) : null
                            }
                            src={
                              griddata?.images && griddata?.images?.length > 0
                                ? `${getBaseURL()}/api/file/download?ID=${
                                    griddata?.images[
                                      griddata?.images?.length - 1
                                    ]
                                  }&size=100`
                                : null
                            }
                            style={
                              griddata?.images === null ||
                              griddata?.images === undefined
                                ? { background: "lightgray" }
                                : { background: "white" }
                            }
                          />
                          <h2 style={{ marginBottom: 30 }}>
                            {griddata?.username}
                          </h2>
                        </div>
                      </Card>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={16}
                      lg={16}
                      xl={16}
                      className="upload-card"
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                      <h4 style={{ margin: 0, padding: 0 }}>Зураг ( 0 )</h4>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        action="/upload"
                        onChange={onUploadChange}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <UploadOutlined style={{ fontSize: "32px" }} />
                        </div>
                      </Upload>
                    </Col>
                  </Row>
                </div>
              </TabPane>

              <TabPane tab="Таалагдсан бараа" key="3" className="tab-title">
                <>
                  <Spin spinning={loading}>
                    <FavoriteItemsTab />
                  </Spin>
                </>
              </TabPane>
            </Tabs>
          )}
          {selectedMenuItem === "2" && (
            <Tabs className="st-menu" style={{ lineHeight: 3 }}>
              <TabPane tab="Гишүүний мэдээлэл засах" key="4">
                <Row gutter={16}>
                  <MemberInfo griddata={griddata} userid={state?.userid} />
                </Row>
              </TabPane>
              <TabPane tab="Нууц үг солих" key="5">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <UpdatePassword griddata={griddata} userid={state?.userid} />
                </Col>
              </TabPane>
            </Tabs>
          )}
          {selectedMenuItem === "3" && <Order />}
        </div>
      </div>
    </div>
  );
}
