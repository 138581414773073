import React, { useState, useEffect, useCallback } from "react";
import {
  Rate,
  Typography,
  List,
  Divider,
  Space,
  Card,
  Button,
  Pagination,
  Image,
  Modal,
  Input,
  Form,
  message,
  Switch,
  Upload,
  Collapse,
  Tag,
} from "antd";
import {
  FileImageOutlined,
  DollarCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import "./style.css";
import { getBaseURL } from "../../system/apiconfig";
import useUserInfo from "../../system/useUserInfo";
import { api } from "../../system/api";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

const { Dragger } = Upload;
const { Text, Paragraph } = Typography;

export default function ReviewSection({ data }) {
  const { id } = useParams();
  const [griddata, setGridData] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Number of comments per page
  const [commentFormVisible, setCommentFormVisible] = useState(false);
  const { userinfo } = useUserInfo();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);

    await api
      .get(`/api/Product/get_valuation_list?itemid=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.rettype === 0) {
          setGridData(res?.data?.retdata);
        }
      })
      .finally(() => {
        setLoading(false);
        // setDrawerVisible(false);
      });
  }, []);

  const groupedData = griddata?.reduce(
    (acc, obj) => {
      const { rate } = obj;
      if (!acc[rate]) {
        acc[rate] = [];
      }
      acc[rate]?.push(obj);
      return acc;
    },
    { 5: [], 4: [], 3: [], 2: [], 1: [] }
  );

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    fetchData();
  }, [id, fetchData]);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Step 1: Post data
      const res1 = await api.post(`/api/product/set_valuation`, values);

      if (res1?.status === 200 && res1?.data?.rettype === 0) {
        // Step 2: Post file if the data posting was successful
        const valuationId = res1?.data?.retdata?.id;

        if (values?.file) {
          const formData = new FormData();
          formData.append("file", values?.file?.file);
          const res2 = await api.post(
            `/api/file/upload?sourcetype=ecomvaluation&sourceId=${valuationId}`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          if (res2?.status === 200) {
            // File upload successful
            message.success("Үнэлгээ өгсөнд баярлалаа");
            setModalVisible(false);
          } else {
            // Handle file upload failure
            message.error("File upload failed");
          }
        }
      } else {
        // Handle data posting failure
        message.error("Failed to post data");
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error:", error);
      message.error("An error occurred");
    } finally {
      setLoading(false);
      message.success("Үнэлгээ өгсөнд баярлалаа");
      setModalVisible(false);
    }
  };

  const newFormData = async () => {
    if (userinfo?.userid > 0) {
      form.setFieldsValue({
        id: 0,
        userid: userinfo.userid,
        itemid: data?.id,
        comment: null,
        rate: 0,
        file: null,
      });
      setModalVisible(true);
    } else {
      alert("Уучлаарай та эхлээд нэвтрэнэ");
    }
  };

  return (
    <>
      <div className="rev-body">
        <Space className="fs">
          <h3>Үнэлгээ</h3>
          <Button
            type="dashed"
            size="small"
            icon={<FormOutlined />}
            onClick={() => newFormData()}
          >
            Үнэлгээ өгөх
          </Button>
        </Space>
        <Card style={{ background: "#F7F8FA", paddingLeft: 20 }} size="small">
          {!isMobile ? (
            <Space className="fa">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Rate
                  allowHalf
                  defaultValue={Math.round((data?.valuationdundaj ?? 0) + 5)}
                  disabled
                />
                <Text style={{ marginLeft: 8, fontSize: 50 }}>
                  {data?.valuationdundaj === null
                    ? Math.round((data?.valuationdundaj ?? 0) + 5)
                    : data?.valuationdundaj?.toFixed(1)}
                </Text>
              </div>
              <div style={{ textAlign: "center", paddingRight: 10 }}>
                {groupedData &&
                  Object?.keys(groupedData)
                    ?.sort((a, b) => parseFloat(b) - parseFloat(a)) // Sort keys in descending order
                    ?.map((rate) => {
                      // Calculate the count of items for this group
                      const count = groupedData[rate]?.length;
                      return (
                        <List.Item key={rate}>
                          <div>
                            {rate} оноо
                            <Rate
                              allowHalf
                              defaultValue={parseFloat(rate)}
                              disabled
                              className="f12"
                            />
                            <span>({count})</span>
                          </div>
                        </List.Item>
                      );
                    })}
              </div>
            </Space>
          ) : (
            <Space direction="horizontal" className="fa">
              <Space direction="vertical" align="center">
                <Text style={{ marginLeft: 8, fontSize: 30 }}>
                  {data?.valuationdundaj === null
                    ? Math.round((data?.valuationdundaj ?? 0) + 5)
                    : data?.valuationdundaj?.toFixed(1)}
                </Text>
                <Rate
                  allowHalf
                  defaultValue={Math.round((data?.valuationdundaj ?? 0) + 5)}
                  disabled
                  className="f12"
                />
              </Space>
              <div style={{ textAlign: "center", paddingRight: 10 }}>
                {groupedData &&
                  Object?.keys(groupedData)
                    ?.sort((a, b) => parseFloat(b) - parseFloat(a)) // Sort keys in descending order
                    ?.map((rate) => {
                      // Calculate the count of items for this group
                      const count = groupedData[rate]?.length;
                      return (
                        <List.Item key={rate}>
                          <div>
                            {rate} оноо
                            <Rate
                              allowHalf
                              defaultValue={parseFloat(rate)}
                              disabled
                              className="f12"
                            />
                            <span>({count})</span>
                          </div>
                        </List.Item>
                      );
                    })}
              </div>
            </Space>
          )}
        </Card>
        <Divider />
        <List
          header={<div>Үнэлгээний сэтгэгдэл</div>}
          className="rev-comments"
          itemLayout="vertical"
          dataSource={griddata?.slice(startIndex, endIndex)}
          renderItem={(comment, index) => (
            <List.Item>
              <Card>
                <List.Item.Meta
                  style={{ padding: "5px 10px 0 10px" }}
                  // avatar={<Avatar src={comment.img} alt="Profile Image" />}
                  title={
                    <div>
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          <b style={{ paddingRight: 10 }}>{index + 1}</b>
                          <Rate
                            allowHalf
                            value={comment.rate}
                            disabled
                            style={{ fontSize: 13 }}
                          />
                        </span>
                        <Text>
                          {moment(comment.updated).format("DD/MM/YYYY HH:mm")}
                        </Text>
                      </Space>
                    </div>
                  }
                  description={
                    <div>
                      <Image
                        src={`${getBaseURL()}/api/file/download?ID=${
                          comment?.id
                        }`}
                        width={50}
                      />

                      <Paragraph>
                        <blockquote>{comment.comment}</blockquote>
                      </Paragraph>
                    </div>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Pagination
            size="small"
            current={currentPage}
            total={griddata?.length}
            pageSize={pageSize}
            onChange={handleChangePage}
          />
        </div>
      </div>
      <Modal
        centered={!isMobile && "true"}
        title={<h3 className="text-center">Үнэлгээ өгөх</h3>}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={!isMobile ? "50%" : "100%"}
        className="modal-review"
        footer={null}
      >
        <Space className="pb">
          <Tag
            icon={<DollarCircleOutlined />}
            color="processing"
            style={!isMobile ? { fontSize: 14 } : { fontSize: 10 }}
          >
            Зураг хавсаргасан үнэлгээ 250P оноо, Ерөнхий тойм 0P оноо
          </Tag>
        </Space>

        <Space>
          {data?.images && data?.images?.length > 0 ? (
            <Image
              src={`${getBaseURL()}/api/file/download?ID=${
                data?.images[0]?.id
              }`}
              preview={false}
              width={100}
            ></Image>
          ) : (
            ""
          )}
          <h4>{data?.name}</h4>
        </Space>
        <Divider />
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={{
            userid: userinfo?.userid,
            itemid: data?.id,
            isapprove: false,
          }}
        >
          <Form.Item
            name="itemid"
            label="itemid"
            hidden={true}
            value={data?.id}
          >
            <Input />
          </Form.Item>

          <Form.Item name="userid" label="userid" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item label="Үнэлгээ" name="rate">
            <Rate />
          </Form.Item>
          <Form.Item
            name="isapprove"
            label="isapprove"
            hidden={true}
            value={false}
          >
            <Switch />
          </Form.Item>
          <Form.Item label="Сэтгэгдэл" name="comment">
            <Input.TextArea placeholder="Мэдээлэл бичих" rows={4} />
          </Form.Item>
          <Form.Item label="Зураг хавсаргах (заавал биш)" name="file">
            <Dragger maxCount={1} beforeUpload={() => false}>
              <p className="ant-upload-drag-icon">
                <FileImageOutlined
                  style={!isMobile ? { fontSize: 30 } : { fontSize: 15 }}
                />
              </p>
              <p className="ant-upload-text">
                Дар эсвэл зурагаа чирж оруулах боломжтой
              </p>
              <p className="ant-upload-hint">
                Зургийн дээд хэмжээ 1мб /1 зураг оруулах боломжтой/
              </p>
            </Dragger>
          </Form.Item>
          <Form.Item>
            <Button type="primary" block htmlType="submit">
              Илгээх
            </Button>
          </Form.Item>
        </Form>
        <Collapse
          defaultActiveKey={isMobile && 1}
          expandIconPosition={"start"}
          items={[
            {
              key: "1",
              label: <b>Үнэлгээ өгөх бодлого</b>,
              children: (
                <>
                  <Text>
                    Таны илгээсэн үнэлгээг <b>бодит, үнэн зөв</b> байх үүднээс
                    манай байгууллагын хамт олон үнэлгээг шалгаж үзэж байгаад
                    оруулах болон устгахыг шийдвэрлэх болно.
                  </Text>
                  <ul>
                    <li>
                      Та тухайн бүтээгдэхүүнийг өөрөө ашигласан тохиолдолд л
                      шүүмж бичих боломжтой.
                    </li>
                    <li>
                      Худалдан авалтын бус бүтээгдэхүүний үнэлгээний оноог
                      хянасны дараа олгодог. (ажлын 2-3 өдөр шаардагдана)
                    </li>
                    <li>Эхний бичсэн шүүмж дээр үндэслэн оноо өгдөг.</li>
                    <li>
                      Гэрэл зургийг хавсаргахдаа авсан,{" "}
                      <b>
                        хулгайлсан, ижил төрлийн бүтээгдэхүүнтэй хамт зургаа
                        авахуулсан, ижил бүтээгдэхүүн мөн эсэхийг тогтоох
                        боломжгүй тохиолдолд{" "}
                      </b>
                      бүртгэлээс татгалзаж, шалтгааныг нь хэлэхгүй.
                    </li>
                    <li>
                      Бүтээгдэхүүнтэй холбоогүй контент, зураг эсвэл ижил
                      текстийг давтах зэрэг зохисгүй шүүмжийг урьдчилан
                      анхааруулахгүйгээр устгаж, оноог хасаж болно.
                    </li>
                  </ul>
                </>
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
}
