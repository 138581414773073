import React, { useEffect, useState } from "react";
import { Card, Space, Divider, Button, message, Flex } from "antd"; // Make sure to import the necessary Ant Design components
import { useAppContext } from "../../Context/index";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import { useNavigate } from "react-router-dom";

export default function CartSummary() {
  const { state, loadShoppingCart } = useAppContext();
  const { userinfo } = useUserInfo();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [userAddress, setUserAddress] = useState(
    state?.orderState?.userAddress
  );
  const navigate = useNavigate();
  useEffect(() => {
    // Load shopping cart data when the component mounts
    loadShoppingCart();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setUserAddress(state.orderState.userAddress);
  }, [state.orderState.userAddress]);

  const saveOrder = async () => {

    try {
      if (
        state?.orderState?.userAddress?.address !== undefined &&
        state?.orderState?.userAddress?.committee !== undefined &&
        state?.orderState?.userAddress?.district !== undefined &&
        state?.orderState?.userAddress?.firstname !== undefined &&
        state?.orderState?.userAddress?.lastname !== undefined &&
        state?.orderState?.userAddress?.phone !== undefined &&
        state?.orderState?.userAddress?.regno !== undefined &&
        state?.orderState?.userAddress?.committee !== undefined
      ) {
        const res = await api.post("/api/product/set_order", {
          userid: userinfo?.userid,
          totalprice: state?.orderState?.totalprice,
          status: 0,
          orderDetails: state?.orderState?.orderDetails,
          userAddress: state?.orderState?.userAddress,
          regno: state?.orderState?.userAddress?.regno,
          ebarimt_receiver_type:
            state?.orderState?.userAddress?.regno === null
              ? "CITIZEN"
              : "ORGANIZATION",
          orderStatus: [
            {
              id: 0,
              orderid: 0,
              status: 0,
              statusdate: "2024-02-12T13:02:59.538Z",
              ownerUserid: 0,
            },
          ],
        });

        if (res?.status === 200 && res?.data?.rettype === 0) {
          message.success("Захиалга амжилттай үүслээ!");
          navigate(`/account/order?key=${res?.data?.retdata?.id}`);
        } else {
          message.error("Бүх талбаруудыг бөглөнө үү!");
        }
      } else {
        // Display error message if userAddress is null
        message.error("Бүх талбаруудыг бөглөнө үү!");
      }
    } catch (error) {
      // Handle error if the API call fails
      console.error("Error saving order:", error);
      message.error("Failed to save order.");
    }
  };


  return (
    <>
      <Card
        className="cd-r"
        bordered
        title={<b>ЗАХИАЛГЫН ДҮН</b>}
        style={{ padding: 20, background: "#fbfbfb" }}
      >
        <Space direction="horizontal" className="cs-item">
          <p>Дүн:</p>
          <h3 className="pm0">
            {(state?.orderState?.totalprice).toLocaleString()} ₮
          </h3>
        </Space>
        <Space direction="horizontal" className="cs-item">
          <p>Хүргэлтийн дүн:</p>
          <h3 className="pm0">0₮</h3>
        </Space>
        <Space direction="horizontal" className="cs-item">
          <p>Хөнгөлөлт:</p>
          <h3 className="pm0">0 ₮</h3>
        </Space>
        <Divider />
        <Space direction="horizontal" className="cs-item">
          <h3 className="pm0">Нийт дүн:</h3>
          <h2 style={{ margin: isMobile && 10 }}>
            {(state?.orderState?.totalprice).toLocaleString()} ₮
          </h2>
        </Space>

        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <b>{state?.orderState?.totalprice * 0.01}P</b>оноо цуглуулах
          боломжтой.
        </Space>
      </Card>
      {state?.orderState?.userAddress?.address &&
        state?.orderState?.userAddress?.committee &&
        state?.orderState?.userAddress?.district &&
        state?.orderState?.userAddress?.firstname &&
        state?.orderState?.userAddress?.lastname &&
        state?.orderState?.userAddress?.phone &&
        state?.orderState?.userAddress?.committee && (
          <Button
            type="primary"
            className="checkout-button"
            style={{
              width: "100%",
              marginTop: 20,
              marginBottom: 50,
            }}
            size={isMobile ? "medium" : "large"}
            onClick={saveOrder}
          >
            {state?.orderState?.orderDetails?.length} төрлийн бараа худалдан
            авах
          </Button>
        )}
    </>
  );
}
