import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Drawer,
  Space,
  Flex,
  Tag,
  AutoComplete,
  Button,
  Input,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import "./style.css"; // Import the CSS file
import useUserInfo from "../../system/useUserInfo";

import {
  HomeOutlined,
  SearchOutlined,
  UserOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { api } from "../../system/api";

import { Link } from "react-router-dom";

const { Footer } = Layout;
const { Item } = Menu;
const { Search } = Input;
export default function FooterMenu() {
  const navigate = useNavigate();
  const { userinfo } = useUserInfo();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const [expanded, setExpanded] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // Get the current location
  const location = useLocation();

  useEffect(() => {
    let selectedItems = JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSearchHistory(selectedItems);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]);

  // Function to get the key of the active menu item based on the current URL
  const getActiveMenuItem = () => {
    const { pathname } = location;
    if (pathname === "/") return "home";
    if (pathname === "/search") return "search";
    if (pathname === "/account/order") return "order";
    if (pathname === "/account/profile") return "profile";
    if (pathname === "/account/") return "account";
    if (pathname === "/account/config") return "config";
    return ""; // Return empty string if no match
  };

  const handleSearchClick = () => {
    setExpanded(!expanded);
  };
  const renderTitle = (title, count) => (
    <Link to={`/category/${title?.categoryid}`} key={`category-${count}`}>
      Ангилал:<b>{title?.categoryname}</b>
    </Link>
  );

  const renderItem = (title, count, clear) => ({
    value: title?.name,
    label: (
      <Link
        to={`/product/${title?.id}`}
        key={`product-${count}`}
        onClick={clear}
      >
        <Space key={`space-${count}`}>
          <SearchOutlined />
          {title?.name?.length > 45
            ? title?.name?.substring(0, 45) + "..."
            : title?.name}
        </Space>
      </Link>
    ),
  });

  const handleSearch = async (value) => {
    if (value?.length >= 2) {
      setSearchValue(value);
      const response = await api.get(
        `/api/Product/get_Item_search?search=${encodeURIComponent(value)}`
      );

      if (
        response?.status === 200 &&
        response?.data &&
        response?.data.rettype === 0
      ) {
        setSearchResults(response?.data?.retdata);
      } else {
        setSearchResults([]);
      }
    } else {
      // Clear search results if the input length is less than 3
      setSearchResults([]);
    }
  };

  const handleSelect = (value) => {
    let selectedItems = JSON.parse(localStorage.getItem("searchHistory")) || [];

    if (!selectedItems.includes(value)) {
      selectedItems.unshift(value);

      if (selectedItems.length > 10) {
        selectedItems.pop();
      }

      localStorage.setItem("searchHistory", JSON.stringify(selectedItems));
      setSearchHistory(selectedItems);

      handleSearchClick();
    }
  };

  const handleClose = (removedTag) => {
    const newTags = searchHistory?.filter((tag) => tag !== removedTag);
    setSearchHistory(newTags);
    localStorage.setItem("searchHistory", JSON.stringify(newTags));
  };

  const options = searchResults?.map((result, index) => ({
    key: index,
    value: result?.name,
    label: renderTitle(result),
    options: [renderItem(result, index, () => setSearchValue([]))],
  }));

  const handleSearchEnter = (value) => {
    if (value) {
      handleSearch(
        value,
        navigate(`/search?query=${searchValue}&page=1&pagesize=10`)
      );
    }
    handleSearchClick();
  };
  return (
    <Footer style={{ position: "fixed", bottom: 0, width: "100%" }}>
      <Menu
        mode="horizontal"
        className="ft-menu"
        style={{
          textAlign: "center",
          borderTop: "1px solid rgba(0,0,0,0.1)",
          boxShadow: "0px 5px 15px rgba(0,0,0, 0.2)",
        }}
        selectedKeys={[getActiveMenuItem()]} // Set the selectedKeys based on the active menu item
      >
        <Item key="home" icon={<HomeOutlined />} onClick={() => window.location.assign("/")}>
          НҮҮР
        </Item>
        <Item
          key="search"
          icon={<SearchOutlined />}
          onClick={handleSearchClick}
        >
          ХАЙХ
        </Item>
        <Item
          key="order"
          icon={<ShoppingOutlined />}
          onClick={() =>
            userinfo?.userid
              ? (window.location.href = "/account/order")
              : (window.location.href = "/login")
          }
        >
          ЗАХИАЛГА
        </Item>
        <Item
          key="profile"
          icon={<UserOutlined />}
          onClick={() =>
            userinfo?.userid
              ? (window.location.href = "/account/profile")
              : (window.location.href = "/login")
          }
        >
          ПРОФАЙЛ
        </Item>
      </Menu>
      {isMobile && expanded && (
        <Drawer
          title="Бараа хайх"
          placement="right"
          onClose={handleSearchClick}
          open={expanded}
          width={"100%"}
        >
          <AutoComplete
            options={options}
            onSelect={handleSelect}
            style={{ width: "100%", padding: 10 }}
          >
            <Search
              placeholder="Хайх"
              value={searchValue}
              allowClear
              size="large"
              onChange={(e) => handleSearch(e.target.value)}
              onSearch={handleSearchEnter}
            />
          </AutoComplete>
          <Space direction="vertical" className="p10" style={{ width: "95%" }}>
            <Flex justify="space-between" align="center">
              <h5>Сүүлийн хайлтууд</h5>
              <Button
                type="link"
                size="small"
                style={{ fontSize: 10 }}
                onClick={() => {
                  localStorage.removeItem("searchHistory");
                  setSearchHistory([]);
                }}
              >
                Бүгдийг устгах
              </Button>
            </Flex>
            {searchHistory?.map((item, index) => (
              <Tag
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleClose(item);
                }}
                key={index}
              >
                {item}
              </Tag>
            ))}
          </Space>
        </Drawer>
      )}
    </Footer>
  );
}
