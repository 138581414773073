import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Button,
  Space,
  Empty,
  Row,
  Col,
  Modal,
  Menu,
  Dropdown,
  Divider,
  Image,
  message,
} from "antd";
import "./style.css";
import {
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import { getBaseURL } from "../../system/apiconfig";
import { useAppContext } from "../../Context/index";
import { Link, useNavigate } from "react-router-dom";
export default function CartPage() {
  const [cartItems, setCartItems] = useState();
  const [loading, setLoading] = useState(true);
  const { userinfo } = useUserInfo();
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState({});
  const { state, setShoppingCart, updateShoppingCart, removeFromCart } =
    useAppContext();
  const navigate = useNavigate();

  const fetchCartData = useCallback(async () => {
    if (userinfo?.userid) {
      try {
        setLoading(true);
        const response = await api.get(
          `/api/product/get_shoppingcart?userid=${userinfo?.userid}`
        );
        if (response?.status === 200 && response?.data) {
          setCartItems(response?.data?.retdata);
        } else {
          console.error("Failed to fetch cart data:", response);
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      } finally {
        setLoading(false); // Set loading to false whether the request succeeds or fails
      }
    }
  }, []);

  useEffect(() => {
    fetchCartData();
  }, [userinfo, state]);

  const removeFromCart1 = async (itemIds) => {
    removeFromCart(itemIds);
  };

  const increaseQuantity = (itemId) => {
    const updatedCart = cartItems.map((cartItem) => {
      if (cartItem.children && Array.isArray(cartItem.children)) {
        const updatedItemTypes = cartItem.children.map((itemType) =>
          itemType.itemid === itemId
            ? { ...itemType, quantity: itemType.quantity + 1 }
            : itemType
        );
        return { ...cartItem, children: updatedItemTypes };
      }
      return cartItem;
    });

    setCartItems(updatedCart);
  };

  const decreaseQuantity = (itemId) => {
    const updatedCart = cartItems.map((cartItem) => {
      if (cartItem.children && Array.isArray(cartItem.children)) {
        const updatedItemTypes = cartItem.children.map((itemType) =>
          itemType.itemid === itemId
            ? { ...itemType, quantity: Math.max(itemType.quantity - 1, 1) }
            : itemType
        );
        return { ...cartItem, children: updatedItemTypes };
      }
      return cartItem;
    });

    setCartItems(updatedCart);
  };

  const calculateTotal = () => {
    if (!cartItems || !Array.isArray(cartItems)) {
      return 0;
    }

    // Flatten the nested arrays (children) and calculate the total
    const flatItems = cartItems.reduce((accumulator, item) => {
      if (item.children && Array.isArray(item.children)) {
        const itemTotal = item.children.reduce(
          (typeTotal, itemType) =>
            typeTotal + itemType.price.calcprice * itemType.quantity,
          0
        );
        return accumulator + itemTotal;
      }

      return accumulator + item.price.calcprice * item.quantity;
    }, 0);

    return flatItems.toLocaleString();
  };

  const calculateChildrenTotal = (children) => {
    if (!children || !Array.isArray(children)) {
      return 0;
    }

    // Calculate the total price for children items
    const childrenTotal = children.reduce(
      (accumulator, child) =>
        accumulator + child.price.calcprice * child.quantity,
      0
    );

    return childrenTotal.toLocaleString();
  };

  const openModal = async (itemId) => {
    try {
      const response = await api.get(`/api/product/get_item?id=${itemId}`);
      if (response?.status === 200 && response?.data) {
        setSelectedItem(response?.data?.retdata);
      } else {
        console.error("Failed to fetch item details:", response);
      }
    } catch (error) {
      console.error("Error fetching item details:", error);
    }

    setModalVisible((prev) => ({
      ...prev,
      [itemId]: true,
    }));
  };

  const closeModal = (itemId) => {
    setModalVisible((prev) => ({
      ...prev,
      [itemId]: false,
    }));
  };
  const handleOk = () => {
    setModalVisible((prev) => ({
      ...prev,
      [selectedItem?.id]: false,
    }));
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const showRemoveConfirmation = (itemId, items) => {
    Modal.confirm({
      title: "Сагснаас энэ бүтээгдэхүүнийг устгахдаа итгэлтэй байна уу?",
      icon: <ExclamationCircleOutlined />,
      okText: "Тийм",
      okType: "danger",
      cancelText: "Үгүй",
      onOk() {
        removeFromCart1(itemId || items);
      },
      onCancel() {},
    });
  };

  const addToCart = async (selectedType) => {
    if (!selectedType) {
      alert("Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү.");
      return;
    }

    const newItem = [
      {
        itemid: selectedType?.id,
        userid: userinfo?.userid,
        quantity: 1,
      },
    ];

    await setShoppingCart(newItem);
  };

  const menu = (
    <Menu style={{ width: "100%", padding: "10px" }} key={2}>
      {selectedItem?.children
        ?.filter((selectedType) => selectedType.quantity > 1) // Filter items with quantity > 1
        .map((selectedType) => {
          const isTypeInCart = cartItems[0]?.children?.some(
            (cartType) => cartType.itemid === selectedType.id
          );
          if (!isTypeInCart) {
            return (
              <Menu.Item
                key={selectedType?.id}
                onClick={() => addToCart(selectedType)}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {selectedType.itemtype} ({selectedType?.price?.price}₮)
                  </span>
                </div>
              </Menu.Item>
            );
          }

          return null; // If the type is in cartItems.children, don't render it in the menu
        })}
    </Menu>
  );

  const handleCheckout = async () => {
    if (cartItems.length > 0) {
      await updateShoppingCart(cartItems);
      navigate(`/checkout`);
    } else {
      message.info("Сагс хоосон байна.");
    }
  };

  return (
    <>
      <div className="container cart-page">
        {cartItems === null ? (
          <Empty
            description="Таны сагс хоосон байна!"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={16} md={16} lg={16} xl={16}>
              {cartItems?.map((item) => (
                <Card
                  className="cart-item-c"
                  bordered={false}
                  key={item.id}
                  loading={loading}
                  actions={[
                    <Space direction="horizontal" className="tpS">
                      <Button
                        type="text"
                        size="small"
                        onClick={() => openModal(item?.id)}
                        className="f12"
                      >
                        Сонголт өөрчлөх
                      </Button>
                      <h2>{calculateChildrenTotal(item?.children)}₮</h2>
                    </Space>,
                  ]}
                >
                  <div className="space-align-container">
                    <div className="space-align-block">
                      <div className="image-and-text">
                        <Image
                          src={`${getBaseURL()}/api/file/download?ID=${
                            item?.images[0]?.id
                          }&size=70`}
                          width={70}
                          preview={true}
                        />
                        <div style={{ paddingLeft: 20 }}>
                          <Link to={`/product/${item?.id}`}>
                            <h3 className="ptext-wrap pm5">{item?.name}</h3>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <Button
                      type="text"
                      onClick={() => showRemoveConfirmation(item?.children)}
                      className="close-button"
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                  {item?.children !== undefined ||
                  item?.children?.length > 0 ? (
                    <>
                      {item?.children?.map((itemType, index) => (
                        <Card
                          className="cart-item2-c"
                          bordered={false}
                          key={index}
                        >
                          <Button
                            type="text"
                            onClick={() =>
                              showRemoveConfirmation(itemType?.shoppingcartid)
                            }
                            className="close-button"
                          >
                            <CloseOutlined />
                          </Button>
                          <div className="color-and-close">
                            <p className="color-text">
                              <b>{itemType?.itemtype}</b>
                            </p>
                          </div>
                          <Space
                            className="item-details"
                            direction="horizontal"
                            align="center"
                          >
                            <div className="quantity-controls">
                              <Button
                                type="default"
                                onClick={() =>
                                  decreaseQuantity(itemType?.itemid)
                                }
                              >
                                <MinusOutlined />
                              </Button>
                              <span>{itemType?.quantity}</span>
                              <Button
                                type="default"
                                onClick={() =>
                                  increaseQuantity(itemType?.itemid)
                                }
                              >
                                <PlusOutlined />
                              </Button>
                            </div>
                            <h3 className="product-price">
                              {(
                                itemType?.price?.calcprice * itemType?.quantity
                              ).toLocaleString()}
                              ₮
                            </h3>
                          </Space>
                        </Card>
                      ))}
                    </>
                  ) : (
                    ""
                  )}

                  <Modal
                    centered
                    title="Барааны сонголт өөрчлөх"
                    open={modalVisible[item?.id]}
                    onCancel={() => closeModal(item?.id)}
                    footer={[
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          type="default"
                          size="large"
                          onClick={handleCancel}
                          block
                        >
                          Хаах
                        </Button>
                      </div>,
                    ]}
                  >
                    <Card className="cart-item3" bordered={false}>
                      <div className="space-align-container1">
                        <div className="space-align-block">
                          <div className="image-and-text">
                            <Image
                              src={`${getBaseURL()}/api/file/download?ID=${
                                item?.images[0]?.id
                              }&size=180`}
                              width={70}
                              preview={true}
                            />
                            <p className="ptext-wrap">{item?.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="spdrop">
                        <Dropdown
                          overlay={menu}
                          menu={menu}
                          placement="bottomLeft"
                          trigger={["click"]}
                        >
                          <Button
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              justifyContent: "space-between",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Сонголт <DownOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                      {item?.children?.map((it, index) => (
                        <Card className="cart-item2" bordered={false}>
                          <div className="color-and-close">
                            <p className="color-text">{it?.itemtype}</p>
                          </div>
                          <Space
                            className="item-details"
                            direction="horizontal"
                            align="center"
                          >
                            <div className="quantity-controls">
                              <Button
                                type="default"
                                onClick={() => decreaseQuantity(it?.itemid)}
                              >
                                <MinusOutlined />
                              </Button>
                              <span>{it?.quantity}</span>
                              <Button
                                type="default"
                                onClick={() => increaseQuantity(it.itemid)}
                              >
                                <PlusOutlined />
                              </Button>
                            </div>
                            <p className="product-price">
                              Үнэ:
                              {(
                                it?.price?.calcprice * it?.quantity
                              ).toLocaleString()}
                              ₮
                            </p>
                          </Space>
                        </Card>
                      ))}
                      <Divider className="mp2" />
                      <div className="mp2">
                        <p className="tp">Захиалгын дүн:</p>
                        <h3>{calculateTotal()?.toLocaleString()} ₮</h3>
                      </div>
                    </Card>
                  </Modal>
                </Card>
              ))}
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Card className="cart-item-c" bordered={false}>
                <Space direction="horizontal" className="cs-item">
                  <p className="tp">Дүн:</p>
                  <h3>{calculateTotal()?.toLocaleString()} ₮</h3>
                </Space>
                <Space direction="horizontal" className="cs-item">
                  <p className="tp">Хүргэлтийн дүн:</p>
                  <h3>0₮</h3>
                </Space>
                <Space direction="horizontal" className="cs-item">
                  <p className="tp">Хөнгөлөлт:</p>
                  <h3>0 ₮</h3>
                </Space>
                <Space direction="horizontal" className="cs-item">
                  <h3 className="tp">Нийт дүн:</h3>
                  <h2>{calculateTotal()?.toLocaleString()} ₮</h2>
                </Space>
              </Card>
              <Space
                direction="vertical"
                style={{ width: "100%", marginTop: 20 }}
              >
                <Button
                  type="primary"
                  className="checkout-button"
                  style={{ width: "100%", height: "70px" }}
                  onClick={() => handleCheckout()}
                >
                  <Space
                    direction="horizontal"
                    style={{ justifyContent: "center" }}
                  >
                    <h3>{cartItems?.length}</h3>
                    <p>бүтээгдэхүүн худалдан авах</p>
                  </Space>
                </Button>
              </Space>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
