import axios from "axios";
import { message } from "antd";
import { getBaseURL } from "./apiconfig";

let errorMessage = "Амжилтгүй боллоо. ";

const auth = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "Access-Control-Allow-Credentials": true,
};

const api = axios.create({
  baseURL: getBaseURL(),
  withCredentials: false,
  timeout: 240000,
  headers: auth,
  mode: "cors",
});

api.interceptors.request.use(
  function (config) {
    const userToken = localStorage.getItem("access_token");

    let header = config.headers;
    if (userToken) {
      header = {
        ...header,
        Authorization: `Bearer ${userToken}`,
      };
    }

    config.headers = header;
    return config;
  },
  function (error) {
    message.error(errorMessage + error.message);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    if (response?.status !== 200) {
      message.error(errorMessage + response.message);
    }

    if (
      !response?.config?.ignoreRetType &&
      response?.data?.rettype !== undefined &&
      response?.data?.rettype !== 0
    ) {
      message.error(errorMessage + response?.data?.retmsg);
    }

    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!error.request.responseURL.endsWith("/login")) {
        try {
          const response = await axios
            .create({
              baseURL: getBaseURL(),
              withCredentials: false,
              timeout: 240000,
              headers: auth,
              mode: "cors",
            })
            .post("/api/systems/refresh-token", {
              access_token: localStorage.getItem("access_token"),
              refresh_token: localStorage.getItem("refresh_token"),
            });

          let userToken = response.data.retdata;
          if (userToken) {
            localStorage.setItem("token", JSON.stringify(userToken));
            localStorage.setItem("access_token", userToken?.access_token);
            localStorage.setItem("refresh_token", userToken?.refresh_token);
            localStorage.setItem(
              "refresh_token_expires_in",
              userToken?.refresh_token_expires_in
            );

            originalRequest.headers.Authorization = `Bearer ${userToken?.access_token}`;
            return axios(originalRequest);
          }
        } catch (refreshError) {
          console.error("Error api:", refreshError);
          // Handle refresh token error or redirect to login
        }
      }
    } else {
      message.error(errorMessage + error.message);
    }

    return Promise.reject(error);
  }
);

const uploadImage = async (file, sourceType, sourceId, type) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await api.post(
      `/api/file/upload?SourceType=${sourceType}&SourceId=${sourceId}&type=${type}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data?.retdata[0]?.id; // Assuming the API returns the image ID
  } catch (error) {
    console.error("Error uploading file:", error);
    console.log("Server Response:", error.response);
    throw error; // Re-throw the error for the calling code to handle
  }
};

const fetchImageById = async (imageId) => {
  try {
    if (!imageId) {
      throw new Error("Image ID is required.");
    }

    const response = await api.get(`/api/file/download?ID=${imageId}`, {
      responseType: "arraybuffer", // Ensure binary data is handled correctly
    });

    const imageUrl = URL.createObjectURL(
      new Blob([response.data], { type: "image/*" })
    );
    return imageUrl;
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error;
  }
};

const getEbarimtMerchantInfo = async (regno) => {
  try {
    // Fetch data from the first API
    const response1 = await axios.get(
      `https://api.ebarimt.mn/api/info/check/getTinInfo?regNo=${regno}`,
      {
        withCredentials: false,
        timeout: 240000,
        header: "Access-Control-Allow-Origin",
      }
    );
    // Extract the tin from the response data
    let tin;
    if (response1?.data?.data !== null) {
      tin = response1?.data?.data;
    } else {
      throw new message.error(response1?.data?.msg); // Throw an error with the message from response1
    }

    // Use the extracted tin to construct the URL for the second API
    const response2 = await axios.get(
      `https://api.ebarimt.mn/api/info/check/getInfo?tin=${tin}`,
      {
        withCredentials: false,
        timeout: 240000,
        header: "Access-Control-Allow-Origin",
      }
    );

    // Assuming the response contains the merchant information
    return response2.data?.data?.name; // Access the 'data' property directly
  } catch (error) {
    console.error("Error fetching ebarimt merchant info:", error);
    throw error;
  }
};

export { api, uploadImage, fetchImageById, getEbarimtMerchantInfo };
