import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Select,
  Form,
  Col,
  Row,
  Divider,
  Checkbox,
  Card,
  Flex,
  Typography,
  Alert,
} from "antd";
import { useAppContext } from "../../Context/index";
import useUserInfo from "../../system/useUserInfo";
import { getEbarimtMerchantInfo } from "../../system/api";

const { Option } = Select;
const { Text } = Typography;
const province = {
  Улаанбаатар: {
    Баянгол: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23,
    ],
    Баянзүрх: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28,
    ],
    Чингэлтэй: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    ],
    "Хан-Уул": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    Налайх: [1, 2, 3, 4, 5, 6, 7],
    Сонгинохайрхан: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
    ],
    Сүхбаатар: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    Багахангай: [1, 2],
    Багануур: [1, 2, 3, 4, 5],
  },
  "Орон нутаг": {
    Архангай: ["Цэцэрлэг"],
    "Баян-Өлгий": ["Өлгий"],
    Баянхонгор: ["Баянхонгор"],
    Булган: ["Булган"],
    "Говь-Алтай": ["Алтай"],
    Говьсүмбэр: ["Чойр"],
    "Дархан-Уул": ["Дархан"],
    Дорноговь: ["Сайншанд"],
    Дорнод: ["Чойбалсан"],
    Дундговь: ["Мандалговь"],
    Завхан: ["Улиастай"],
    Орхон: ["Эрдэнэт"],
    Өвөрхангай: ["Арвайхээр"],
    Өмнөговь: ["Даланзадгад"],
    Сүхбаатар: ["Баруун-Урт"],
    Сэлэнгэ: ["Сүхбаатар"],
    Төв: ["Зуунмод"],
    Увс: ["Улаангом"],
    Ховд: ["Ховд"],
    Хөвсгөл: ["Мөрөн"],
    Хэнтий: ["Чингис"],
  },
};

export default function Address({ updateUserAddress, onFormFilled }) {
  const { state } = useAppContext();
  const { userinfo } = useUserInfo();
  const [showOrg, setShowOrg] = useState(false);
  const [showPer, setShowPer] = useState(false);
  const [checkPerson, setCheckPerson] = useState(false);
  const [checkedValue, setCheckedValue] = useState(null);
  const [orgName, setOrgName] = useState("");
  const [orgNo, setOrgNo] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const scrollableContainerRef = useRef(null);

  const [userAddress, setUserAddress] = useState(
    state?.orderState?.userAddress
  );

  const handleChange = async (field, value) => {
    if (field === "orgRegNo") {
      if (!value || value.length < 7) {
        setOrgName("");
      } else {
        try {
          setOrgNo(value);
          const merchantInfo = await getEbarimtMerchantInfo(value);
          setOrgName(merchantInfo);
          updateUserAddress({ ["regno"]: value });
        } catch (error) {
          // Handle errors
          console.error("Error handling ebarimt merchant info:", error);
        }
      }
    }
    updateUserAddress({ [field]: value });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  const isFormFilled = () => {
    // Check if all required fields are filled
    return (
      userAddress.firstname &&
      userAddress.lastname &&
      userAddress.phone &&
      userAddress.phone2 &&
      userAddress.province &&
      userAddress.district &&
      userAddress.committee &&
      userAddress.address
    );
  };

  const handleProvinceChange = (value) => {
    const selectedProvince = province[value];
    setUserAddress((prevAddress) => ({
      ...prevAddress,
      province: value,
    }));
    updateUserAddress({ province: value });
  };

  const handleDistrictChange = (value) => {
    setUserAddress((prevAddress) => ({
      ...prevAddress,
      district: value,
    }));
    updateUserAddress({ district: value });
  };

  const handleCommitteeChange = (value) => {
    setUserAddress((prevAddress) => ({
      ...prevAddress,
      committee: value,
    }));
    updateUserAddress({ committee: value });
  };

  // useEffect to update userAddress when it changes in the context
  useEffect(() => {
    setUserAddress(state.orderState.userAddress);
  }, [state.orderState.userAddress]);

  useEffect(() => {
    if (isFormFilled()) {
      onFormFilled(true);
    } else {
      onFormFilled(false);
    }
  }, [userAddress]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (value === "checkPerson" && checked) {
      setShowOrg(false);
      setCheckPerson(true);
      setShowPer(false);
      setCheckedValue(value);
      // Set value to null for checkPerson
      updateUserAddress({ regno: null });
    } else if (value === "checkOrg" && checked) {
      setShowOrg(true);
      setCheckPerson(false);
      setShowPer(false);
      setCheckedValue(value);
      // Pass value of input for checkOrg
      updateUserAddress({ regno: orgNo });
    } else if (value === "checkPer" && checked) {
      setShowOrg(false);
      setCheckPerson(false);
      setShowPer(true);
      setCheckedValue(value);
      // Set value to null for checkPer
      updateUserAddress({ regno: userAddress?.regno });
    } else {
      setShowOrg(false);
      setCheckPerson(false);
      setShowPer(false);
      setCheckedValue(null);
      // Set value to null for all other cases
      updateUserAddress({ regno: null });
    }
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <h3>ЗАХИАЛАГЧИЙН МЭДЭЭЛЭЛ</h3>
      <Divider />
      <Form
        layout="vertical"
        initialValues={{
          id: 0,
          userid: userinfo?.userid,
          firstname: userAddress?.firstname, // Add the default values you want for the userAddress
          lastname: userAddress?.lastname,
          phone: userAddress?.phone,
          phone2: userAddress?.phone2,
          regno: userAddress?.regno,
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Овог"
              name="firstname"
              rules={[{ required: true, message: "Овог оруулна уу" }]}
            >
              <Input
                placeholder="Овог"
                value={state?.firstname}
                onChange={(e) => handleChange("firstname", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Нэр"
              name="lastname"
              rules={[{ required: true, message: "Нэр оруулна уу" }]}
            >
              <Input
                placeholder="Нэр"
                value={userAddress.lastname}
                onChange={(e) => handleChange("lastname", e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Холбогдох дугаар 1"
              name="phone"
              rules={[
                { required: true, message: "Дугаар оруулна уу" },
                {
                  pattern: /^(96|99|88|89|85|91|60|66|)\d{8}$/,
                  message: "Монгол утасны дугаар оруулна уу",
                },
              ]}
            >
              <Input
                type="tel"
                maxLength={8}
                showCount
                placeholder="Утасны дугаар"
                value={userAddress.phone}
                onChange={(e) => handleChange("phone", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Холбогдох дугаар 2"
              name="phone2"
              rules={[
                { required: true, message: "Дугаар 2 оруулна уу" },
                {
                  pattern: /^(96|99|88|89|85|91|60|66|)\d{8}$/,
                  message: "Монгол утасны дугаар оруулна уу",
                },
              ]}
            >
              <Input
                type="tel"
                showCount
                maxLength={8}
                placeholder="Утасны дугаар"
                value={userAddress?.phone}
                onChange={(e) => handleChange("phone2", e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Card
          // style={{ backgroundColor: "#FBFBFB" }}
          title="И-БАРИМТЫН МЭДЭЭЛЭЛ"
          type="inner"
          style={{ marginBottom: 20 }}
        >
          <div style={{ padding: 10 }}>
            <Flex gap="large">
              <Checkbox
                value="checkPerson"
                onChange={handleCheckboxChange}
                checked={checkedValue === "checkPerson"}
              >
                Хувь хүн
              </Checkbox>
              <Checkbox
                value="checkOrg"
                onChange={handleCheckboxChange}
                checked={checkedValue === "checkOrg"}
              >
                Байгууллага
              </Checkbox>
            </Flex>
            {showOrg && (
              <Flex vertical gap="large" style={{ paddingTop: 10 }}>
                <Alert
                  type="warning"
                  message="Та байгууллагын регистрийн дугаараа зөв бичнэ үү. Төлбөр
                  төлөгдсөн тохиолдолд регистрийн дугаараа солих боломжгүйг
                  анхаарна уу!"
                  showIcon
                />
                <Flex
                  gap="large"
                  justify="space-arround"
                  vertical={isMobile ? true : false}
                >
                  <Form.Item
                    name="regno"
                    label="Байгууллагын регистрийн дугаар:"
                    style={{ width: isMobile ? "100%" : "50%" }}
                  >
                    <Input
                      showCount
                      maxLength={7}
                      placeholder="Байгууллагын регистрийн дугаар"
                      onChange={(e) => handleChange("orgRegNo", e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Байгууллагын нэр:"
                    style={{ width: isMobile ? "100%" : "50%" }}
                  >
                    <Input
                      placeholder="Байгууллагын нэр"
                      value={orgName}
                      disabled
                    />
                  </Form.Item>
                </Flex>
              </Flex>
            )}
            {showPer && (
              <Flex vertical gap="large" style={{ paddingTop: 10 }}>
                <Alert
                  type="warning"
                  message={`Та өөрийн регистрийн дугаараа криллээр зөв бичнэ үү. Төлбөр төлөгдсөн
                  тохиолдолд регистрийн дугаараа солих боломжгүйг анхаарна уу!`}
                  showIcon
                />
                <Flex gap="large" justify="space-arround">
                  <Form.Item
                    name="regno1"
                    label="Регистрийн дугаар:"
                    style={{ width: "70%" }}
                  >
                    <Input
                      placeholder="Регистрийн дугаар"
                      value={userAddress?.regno}
                      onChange={(e) => handleChange("regno", e.target.value)}
                    />
                  </Form.Item>
                </Flex>
              </Flex>
            )}
          </div>
        </Card>
      </Form>

      <h3>ХҮРГЭЛТИЙН МЭДЭЭЛЭЛ</h3>
      <Divider />
      <Form
        layout="vertical"
        initialValues={{
          id: 0,
          province: userAddress?.province,
          district: userAddress?.district,
          committee: userAddress?.committee,
          address: userAddress?.address,
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Нийслэл/Орон нутаг"
              name="province"
              rules={[
                { required: true, message: "Нийслэл/Орон нутаг сонгоно уу" },
              ]}
            >
              <Select
                placeholder="Нийслэл/Орон нутаг"
                value={userAddress?.province}
                onChange={handleProvinceChange}
              >
                {Object.keys(province).map((provinceName) => (
                  <Option key={provinceName} value={provinceName}>
                    {provinceName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Дүүрэг/Аймаг"
              name="district"
              rules={[{ required: true, message: "Дүүрэг/Аймаг сонгоно уу" }]}
            >
              <Select
                placeholder="Дүүрэг/Аймаг"
                value={userAddress?.district}
                onChange={handleDistrictChange}
              >
                {Object.keys(province[userAddress?.province] || {}).map(
                  (district) => (
                    <Option key={district} value={district}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Хороо"
              name="committee"
              rules={[{ required: true, message: "Хороо сонгоно уу" }]}
            >
              <Select
                placeholder="Хороо"
                value={userAddress.committee}
                onChange={handleCommitteeChange}
              >
                {province[userAddress?.province]?.[userAddress?.district]?.map(
                  (committee) => (
                    <Option key={committee} value={committee}>
                      {committee}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Дэлгэрэнгүй мэдээлэл"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Хаягийн дэлгэрэнгүй мэдээлэл оруулна уу",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Хороололын нэр, Орц, давхар, орцны код, хүсвэл google map байршил оруулаарай."
                value={userAddress?.address}
                onChange={(e) => handleChange("address", e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
