import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Layout,
  Row,
  Col,
  Badge,
  Rate,
  Card,
  Drawer,
  Space,
  Button,
  Divider,
  Menu,
  Dropdown,
  Affix,
  Anchor,
  Image,
  Descriptions,
  message,
  Select,
  Popover,
} from "antd";
import {
  CarOutlined,
  DollarCircleOutlined,
  ShopOutlined,
  RightOutlined,
  DownOutlined,
  PlusOutlined,
  CloseOutlined,
  MinusOutlined,
  LinkOutlined,
  FacebookFilled,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { getBaseURL } from "../../system/apiconfig";
import { api } from "../../system/api";
import "./style.css"; // Create this CSS file for styling
import { Link, useNavigate } from "react-router-dom";
import ReviewSection from "../../components/Review";
import DeliverySection from "../../components/Delivery";
import RecommendProduct from "../../components/RecommendProduct";
import ImagePreviewContainer from "./ImagePreviewContainer";
import { useAppContext } from "../../Context/index";
import useUserInfo from "../../system/useUserInfo";
import { Helmet } from "react-helmet";

const { Content } = Layout;

export default function ImagePreview() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { userinfo } = useUserInfo();
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const scrollableContainerRef = useRef(null);
  const [curentAnchor, setCurrentAnchor] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { id } = useParams();
  const [griddata, setGridData] = useState();
  const [loading, setLoading] = useState(true);
  const [pimg, setPimg] = useState();
  const [pbody, setPbody] = useState();
  const [selectedImageSrc, setSelectedImageSrc] = useState();
  const navigate = useNavigate();
  const { Option } = Select;
  const { state, setFavorites, removeFromFavorites, setShoppingCart } =
    useAppContext();

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        setLoading(true);
        const res = await api.get(`/api/Product/get_Item?id=${id}`);
        if (res?.status === 200 && res?.data?.rettype === 0) {
          setGridData(res?.data?.retdata);
          setPimg(res?.data?.retdata?.images?.filter((img) => img?.type === 1));
          if (
            res?.data?.retdata?.images &&
            res?.data?.retdata?.images?.length > 0
          ) {
            setSelectedImage(res?.data?.retdata?.images[0]?.id);
          }
          setPbody(
            res?.data?.retdata?.images?.filter((img) => img?.type === 2)
          );
        }
      } catch (error) {
        console.error("Error fetching item:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(id);
  }, [id]);


  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.addEventListener("scroll", handleScroll);
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
  }, []); // Dependency: only re-run when 'scrollableContainerRef' changes

  useEffect(() => {
    calculateTotalPrice();
  }, [cartItems]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const removeFromCart = (itemId) => {
    const updatedCart = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCart);
  };

  const increaseQuantity = (itemId) => {
    const updatedCart = cartItems?.map((item) => {
      if (item.id === itemId) {
        const gridDataItem = griddata?.children?.find(
          (child) => child.id === itemId
        );
        if (gridDataItem) {
          const maxQuantity = gridDataItem?.quantity;
          const updatedQuantity = item?.quantity + 1;
          if (updatedQuantity > maxQuantity) {
            alert(
              `${item?.name} - нэртэй барааны үлдэгдэл ${maxQuantity} ширхэг байна.`
            );
            return item;
          } else {
            return {
              ...item,
              quantity: updatedQuantity,
              maxQuantity: maxQuantity,
            };
          }
        } else {
          console.error("Item not found in griddata children.");
          return item;
        }
      } else {
        return item;
      }
    });
    setCartItems(updatedCart);
  };

  const decreaseQuantity = (itemId) => {
    const updatedCart = cartItems.map((item) =>
      item.id === itemId && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCartItems(updatedCart);
  };

  const handleImageMouseEnter = async (imageId) => {
    const imageIndex = pimg.findIndex((image) => image?.id === imageId);
    if (imageIndex !== -1) {
      setSelectedImage(imageId);
      setSelectedProduct(pimg);
      setSelectedProductOption(pimg[imageIndex]);

      const imageSrc = await getImageSrc(imageId);
      setSelectedImageSrc(imageSrc);
    }
  };

  const getImageSrc = async (imageId) => {
    // Fetch image source based on the ID from your API
    const response = await fetch(
      `${getBaseURL()}/api/file/download?ID=${imageId}&size=500`
    );
    const data = await response?.data; // Assuming your API returns JSON with the image source
    return data;
  };

  const formatNumberWithCommas = (number) => {
    return number?.toLocaleString();
  };

  const addToCart = (product) => {
    if (product?.length === 0) {
      alert("Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү.");
      return;
    }
    if (product) {
      const newItem = {
        id: product?.id,
        name: product?.itemtype,
        price: product?.price?.calcprice,
        quantity: 1,
        image: `${getBaseURL()}/api/file/download?ID=${
          product?.images?.length > 0 ? product?.images[0]?.id : ""
        }&size=50&type=1`,
        userid: userinfo?.userid,
      };

      // Check if the item is already in the cart
      const itemExists = cartItems.some((item) => item?.id === newItem?.id);

      if (itemExists) {
        alert("Энэ бүтээгдэхүүн аль хэдийн нэмэгдсэн байна");
      } else {
        setCartItems([newItem, ...cartItems]);
      }
    } else {
      alert("Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү.");
    }
  };

  const menus = (
    <Menu
      className="custom-dropdown-menu"
      style={{
        maxHeight: "300px",
        overflowY: "auto",
      }}
    >
      {griddata?.children?.map((item, index) => (
        <Menu.Item
          key={index}
          className="p-menu-item"
          onClick={() => addToCart(item)}
          style={{
            borderBottom: "1px solid rgba(0,0,0,0, 0.1",
            opacity: item?.quantity === 0 ? 0.5 : 1,
            pointerEvents: item?.quantity === 0 ? "none" : "auto",
          }}
        >
          {!isMobile ? (
            <div className="p-info">
              <div>
                <h3>{index + 1}</h3>
              </div>
              <div className="p-image">
                <Image
                  key={item?.id}
                  src={`${getBaseURL()}/api/file/download?ID=${
                    item?.images?.length > 0 ? item?.images[0]?.id : ""
                  }&size=50&type=1`}
                  alt="Image"
                  style={{ width: 50 }}
                  preview={false}
                />
              </div>
              <div className="p-details">
                <p className="lh">{item?.itemtype}</p>

                <div className="fs">
                  <div className="p-price">
                    {item?.price?.discount !== 0 && (
                      <span className="fa mr10 p-percent">
                        {item?.price?.discount}%
                      </span>
                    )}
                    <span className="p-price">
                      {(item?.price?.calcprice).toLocaleString()} ₮
                    </span>
                  </div>
                  <div>
                    {item?.quantity < 5 && item?.quantity > 0 ? (
                      <p className="light-red">Үлдэгдэл: {item?.quantity}</p>
                    ) : item?.quantity >= 5 ? (
                      <p className="light-green">Бэлэн бараа</p>
                    ) : (
                      item?.quantity < 1 && <p className="light-red">Дууссан</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-info">
              <div>
                <h3>{item?.id}</h3>
              </div>
              <div className="p-image">
                <Image
                  src={`${getBaseURL()}/api/file/download?ID=${
                    item?.images?.length > 0 ? item?.images[0]?.id : ""
                  }&size=50&type=1`}
                  alt="Image"
                  style={{ width: 50 }}
                  preview={false}
                />
              </div>
              <div className="p-details">
                <p className="lh">{item?.itemtype}</p>
                <div className="fs">
                  <div className="p-price">
                    {item?.price?.discount !== 0 && (
                      <span className="fa mr10 p-percent">
                        {item?.price?.discount}%
                      </span>
                    )}
                    <span className="p-price">
                      {(item?.price?.calcprice).toLocaleString()} ₮
                    </span>
                  </div>
                  <div>
                    {item?.quantity < 5 && item?.quantity > 0 ? (
                      <p className="light-red">Үлдэгдэл: {item?.quantity}</p>
                    ) : item?.quantity >= 5 ? (
                      <p className="light-green">Бэлэн бараа</p>
                    ) : (
                      item?.quantity < 1 && <p className="light-red">Дууссан</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleScroll = () => {
    const anchors = document.querySelectorAll(".scrollable-container [id]");
    const scrollY = scrollableContainerRef?.current?.scrollTop;

    let current = "";
    for (let i = anchors?.length - 1; i >= 0; i--) {
      const anchor = anchors[i];
      const offsetTop = anchor.getBoundingClientRect().top + scrollY;
      if (scrollY >= offsetTop - 150) {
        current = anchor.id;
        break;
      }
    }

    setCurrentAnchor(current);
  };
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderOptions = (options) => {
    return options?.map((option) => (
      <Option key={option?.key} value={option.key}>
        {option?.text} {`(${option?.price} ₮)`} {option?.child?.type}{" "}
        {option?.type} {option?.child?.data[0]?.text}
        {option?.child && renderOptions(option?.child?.data)}
      </Option>
    ));
  };

  const calculateTotalPrice = useCallback(() => {
    const newTotalPrice = cartItems.reduce(
      (total, item) => total + item?.price * item?.quantity,
      0
    );
    setTotalPrice(newTotalPrice);
  }, [cartItems]);

  const handleClick = async (item) => {
    const isFavorite = state?.favoriteItems?.some(
      (favorite) => favorite?.itemid === item?.id
    );
    try {
      if (isFavorite) {
        const favoriteItem = state?.favoriteItems?.find(
          (favorite) => favorite?.itemid === item?.id
        );

        // Check if favoriteItem exists before trying to access its properties
        if (favoriteItem) {
          await removeFromFavorites(favoriteItem?.id);
        } else {
          console.error("Favorite item not found for item:", item);
        }
      } else {
        const favoriteItems = {
          itemid: item.id,
          userid: userinfo.userid,
        };

        await setFavorites(favoriteItems);
      }
    } catch (error) {
      console.error("Error in handleClick:", error);
    }
  };

  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleHoverChange = (open) => {
    setHovered(open);
    setClicked(false);
  };

  const handleClickChange = (open) => {
    setHovered(false);
    setClicked(open);
  };

  const handleCopyLink = () => {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    message.success("Барааны холбоосыг амжилттай хууллаа.");
  };

  const handleFacebookShare = () => {
    const facebookShareUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      encodeURIComponent(window.location.href);
    window.open(facebookShareUrl, "_blank");
  };

  const popoverContent = (
    <Space>
      <Button
        icon={<LinkOutlined />}
        onClick={handleCopyLink}
        size="large"
      ></Button>
      <Button
        icon={<FacebookFilled />}
        onClick={handleFacebookShare}
        size="large"
      ></Button>
    </Space>
  );

  return (
    <>

      {/* <Helmet>
        <title>{`uju.mn | ${griddata?.name}`}</title>
        <meta property="og:title" content={griddata?.name} />
        <meta
          property="og:description"
          content={`${griddata?.price?.calcprice?.toLocaleString()}₮`}
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/assets/images/uju4.png"
        /> 
        <meta name="description" content="Basic description1" />
        <meta name="title" content="Basic title1" />
        <meta name="image" content="%PUBLIC_URL%/assets/images/uju4.png" />
        <meta name="og:description" content="Basic description" />
        <meta name="og:title" content="Basic title" />
        <meta name="og:image" content="%PUBLIC_URL%/assets/images/uju4.png" />
      </Helmet> */}
      <Content style={{ maxWidth: 1200, margin: "0 auto", paddingTop: 30 }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <ImagePreviewContainer
              pimg={pimg}
              selectedImage={selectedImage}
              handleImageMouseEnter={handleImageMouseEnter}
              isMobile={isMobile}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <div className="product-details">
              <Space
                style={{ justifyContent: "space-between", display: "flex" }}
              >
                <h3 className="product-title">{griddata?.name}</h3>
                <div className="button-container">
                  <div className="button-badge">
                    <Badge>
                      <Link
                        onClick={() => handleClick(id)}
                        className="button-link"
                      >
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 512.000000 512.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M1253 4690 c-212 -56 -371 -221 -418 -435 -14 -62 -15 -272 -13
  -1820 l3 -1750 29 -58 c83 -163 281 -238 436 -166 30 15 93 60 140 101 96 85
  546 483 843 747 109 97 213 184 230 194 38 21 82 22 119 3 15 -8 277 -236 583
  -508 629 -559 636 -563 764 -562 134 1 243 70 303 192 l33 67 3 1730 c2 1192
  -1 1750 -8 1795 -38 235 -220 427 -447 475 -57 12 -265 15 -1298 14 -1170 0
  -1233 -1 -1302 -19z m2596 -207 c111 -45 180 -114 223 -221 l23 -57 3 -1713
  c1 -1123 -1 -1726 -8 -1751 -14 -53 -59 -92 -113 -98 -24 -3 -54 0 -66 6 -12
  7 -276 237 -588 513 -424 375 -581 507 -621 526 -105 48 -236 38 -329 -25 -22
  -15 -287 -246 -589 -513 -301 -267 -557 -490 -568 -497 -12 -7 -40 -12 -63
  -13 -53 0 -108 44 -123 98 -14 51 -14 3379 0 3452 31 162 151 283 308 310 26
  4 589 7 1252 6 l1205 -1 54 -22z"
                            />
                          </g>
                        </svg>
                      </Link>
                    </Badge>
                  </div>
                  <div className="button-badge">
                    <Popover
                      placement="bottom"
                      content={popoverContent}
                      trigger="hover"
                      visible={hovered}
                      onVisibleChange={handleHoverChange}
                    >
                      <Badge>
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 512.000000 512.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M3881 4730 c-177 -38 -331 -131 -441 -269 -154 -193 -196 -425 -125
-696 5 -18 -69 -60 -597 -345 -331 -179 -607 -326 -614 -328 -7 -2 -19 7 -28
20 -35 50 -163 170 -229 214 -79 53 -190 103 -292 130 -105 28 -341 26 -450
-4 -327 -90 -570 -335 -661 -667 -27 -98 -27 -362 -1 -450 84 -282 248 -483
492 -601 142 -69 224 -87 395 -88 112 -1 162 3 220 18 201 51 359 150 495 309
33 37 55 55 65 52 8 -3 284 -151 613 -328 526 -283 597 -324 592 -342 -45
-162 -45 -316 -1 -450 100 -308 398 -512 721 -492 305 19 553 217 647 517 30
97 32 279 4 380 -56 202 -196 371 -381 460 -113 54 -212 74 -344 68 -189 -9
-333 -73 -469 -206 -70 -69 -77 -73 -100 -62 -20 8 -1188 637 -1199 645 -2 0
6 25 17 53 26 68 50 208 50 292 0 84 -24 224 -50 292 -11 28 -19 52 -17 53 15
11 1198 646 1210 651 14 5 31 -7 69 -50 93 -104 235 -188 376 -223 90 -22 257
-21 342 2 248 65 436 233 520 464 144 394 -64 822 -465 957 -65 22 -102 27
-200 30 -66 2 -140 -1 -164 -6z m274 -252 c227 -75 374 -308 344 -549 -28
-228 -205 -408 -436 -441 -330 -47 -622 244 -575 573 46 320 362 517 667 417z
m-2648 -1223 c32 -8 101 -36 153 -62 260 -130 419 -428 382 -715 -39 -301
-247 -537 -542 -614 -97 -26 -254 -23 -355 5 -207 58 -373 197 -461 388 -46
97 -66 190 -66 303 0 361 254 653 617 711 64 10 199 2 272 -16z m2578 -1626
c333 -61 515 -422 365 -725 -141 -287 -503 -377 -759 -189 -251 185 -278 549
-58 770 123 122 286 175 452 144z"
                            />
                          </g>
                        </svg>
                      </Badge>
                    </Popover>
                  </div>
                </div>
              </Space>
              <Space
                direction="horizontal"
                style={{
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <div className="star-ratings">
                  {griddata?.valuationdundaj === null ? (
                    <Rate
                      allowHalf
                      defaultValue={5}
                      disabled
                      style={isMobile && { fontSize: 12 }}
                    />
                  ) : (
                    <Rate
                      allowHalf
                      defaultValue={Math.round(
                        griddata?.valuationdundaj?.toFixed(1)
                      )}
                      disabled
                      style={isMobile && { fontSize: 12 }}
                    />
                  )}

                  <div className="star-rating">
                    {griddata?.valuationdundaj === null
                      ? Math.round((griddata?.valuationdundaj ?? 0) + 5)
                      : griddata?.valuationdundaj?.toFixed(1)}
                  </div>
                </div>
                <div className="order-number" type="secondary">
                  Үнэлгээ: (
                  {formatNumberWithCommas(
                    Math.round((griddata?.valuation?.length ?? 0) + 1)
                  )}
                  )
                </div>
              </Space>
              {griddata?.price?.discount !== 0 && (
                <div className="product-info1">
                  <div className="discount">
                    <span className="discount-percent1">
                      {griddata?.price?.discount}%
                    </span>
                    <span className="original-price">
                      {formatNumberWithCommas(griddata?.price?.price)}₮
                    </span>
                  </div>
                </div>
              )}
              <div className="product-info1">
                <div className="price1">
                  {formatNumberWithCommas(griddata?.price?.calcprice)}₮
                </div>
              </div>
              <div className="delivery">
                <Descriptions column={1} size="small" bordered>
                  <Descriptions.Item
                    style={isMobile && { fontSize: "11px" }}
                    label={
                      <Space>
                        <DollarCircleOutlined
                          style={{ fontSize: "20px", color: "#828c94" }}
                        />
                        <span className="light">Оноо</span>
                      </Space>
                    }
                  >
                    <span>
                      <b>{griddata?.price?.price / 1000}P</b> оноо цуглуулах
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item
                    style={isMobile && { fontSize: "11px" }}
                    label={
                      <Space>
                        <CarOutlined
                          style={{ fontSize: "20px", color: "#828c94" }}
                        />
                        <span className="light">Хүргэлт</span>
                      </Space>
                    }
                  >
                    <span>
                      <b>
                        {selectedProduct?.delivery === 1
                          ? "Хүргэлт үнэгүй"
                          : "Хүргэлтийн хөлс 5,000 төгрөг"}
                      </b>
                    </span>
                    <br />
                    <span className="light">
                      Хөдөө орон нутаг руу хүргэх үнэ тусдаа бодогдоно
                    </span>
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className="brand-box">
              <Divider />
              {griddata?.brandname !== null ? (
                <div className="brand-container">
                  <Space className="fs">
                    <span>
                      <h4 className="h4s w7">
                        <ShopOutlined
                          style={{ fontSize: "20px" }}
                          className="w7"
                        ></ShopOutlined>
                        {griddata?.brandname}
                      </h4>
                    </span>
                    <Button type="dashed" className="w7" size="medium">
                      Бренд үзэх <RightOutlined />
                    </Button>
                  </Space>
                </div>
              ) : null}
            </div>
            {!isMobile && (
              <>
                <div className="product-options">
                  <Dropdown
                    overlay={menus}
                    menu={menus}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <a
                      href="#"
                      className="ant-dropdown-link fss"
                      onClick={(e) => e.preventDefault()}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <b>Барааны сонголт</b>
                      {cartItems?.length > 0 ? (
                        <span>
                          <b style={{ color: "blue", fontSize: "small" }}>
                            {cartItems?.length} бараа сонгогдсон{" "}
                          </b>
                          <DownOutlined />
                        </span>
                      ) : (
                        <DownOutlined />
                      )}
                    </a>
                  </Dropdown>
                </div>
                <div className="product--item">
                  {cartItems?.map((item) => (
                    <Card className="cart-item" bordered={false} key={item.id}>
                      <div className="space-align-container">
                        <div className="space-align-block">
                          <div className="image-and-text">
                            <img src={item?.image} width={50} alt="" />
                            <p className="ptext-wrap">{item?.name}</p>
                          </div>
                        </div>
                        <Button
                          type="text"
                          onClick={() => removeFromCart(item?.id)}
                          className="close-button"
                        >
                          <CloseOutlined />
                        </Button>
                      </div>
                      <Card className="cart-item2" bordered={false}>
                        <Space
                          className="item-details"
                          direction="horizontal"
                          align="center"
                        >
                          <div className="quantity-controls">
                            <Button
                              type="default"
                              onClick={() => decreaseQuantity(item?.id)}
                            >
                              <MinusOutlined />
                            </Button>
                            <span>{item?.quantity}</span>
                            <Button
                              type="default"
                              onClick={() => increaseQuantity(item?.id)}
                            >
                              <PlusOutlined />
                            </Button>
                          </div>
                          <p className="product-price">
                            <b>{item?.price?.toLocaleString()} ₮</b>
                          </p>
                        </Space>
                      </Card>
                    </Card>
                  ))}
                  <Divider />
                </div>
                <div className="total-price">
                  <Space className="fs">
                    <h4>Захиалгын дүн:</h4>
                    <h2> {totalPrice?.toLocaleString()} ₮</h2>
                  </Space>
                </div>
                <div className="order-buttons">
                  <Button
                    type="default"
                    block
                    icon={
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 512.000000 512.000000"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                          fill="#000000"
                          stroke="none"
                        >
                          <path
                            d="M63 5034 c-31 -15 -63 -65 -63 -98 0 -32 29 -85 56 -103 25 -16 58
  -19 289 -23 245 -5 262 -6 302 -28 57 -30 108 -83 136 -140 13 -26 192 -555
  397 -1176 l374 -1128 -18 -47 c-73 -190 -70 -180 -71 -301 0 -94 4 -127 23
  -182 62 -180 210 -315 397 -363 87 -22 2431 -23 2474 -1 82 43 81 161 -2 209
  -17 9 -293 13 -1232 17 l-1210 5 -54 30 c-55 30 -104 83 -137 145 -13 26 -18
  58 -19 125 0 77 4 100 28 156 28 65 30 67 70 73 23 3 584 62 1247 131 663 69
  1230 130 1260 136 120 23 245 86 334 168 65 60 132 167 166 266 37 107 312
  1285 306 1310 -7 29 -41 63 -76 77 -16 6 -742 20 -1962 37 -1066 15 -1939 29
  -1942 32 -2 2 -27 76 -56 164 -74 223 -101 276 -185 361 -77 78 -175 133 -273
  153 -30 6 -156 11 -290 11 -195 0 -243 -3 -269 -16z m3207 -945 c866 -12 1576
  -23 1578 -25 2 -1 -55 -244 -127 -541 -136 -558 -157 -623 -221 -692 -51 -54
  -123 -97 -191 -115 -56 -14 -2435 -266 -2517 -266 l-31 0 -152 463 c-84 254
  -207 630 -275 836 l-123 374 242 -6 c133 -4 951 -16 1817 -28z"
                          />
                          <path
                            d="M1520 1124 c-192 -41 -360 -205 -405 -395 -43 -184 9 -365 143 -499
  137 -137 315 -188 501 -145 93 22 176 71 252 147 119 119 174 280 151 442 -25
  175 -137 329 -297 406 -118 58 -221 71 -345 44z m244 -258 c105 -51 160 -144
  161 -266 0 -59 -5 -84 -23 -120 -36 -68 -99 -127 -160 -150 -243 -91 -473 139
  -382 382 29 78 108 149 200 179 46 15 147 3 204 -25z"
                          />
                          <path
                            d="M3780 1124 c-102 -22 -185 -68 -265 -148 -108 -108 -155 -220 -155
  -371 0 -152 49 -272 157 -379 208 -208 538 -208 746 0 108 107 157 225 157
  373 0 158 -45 267 -155 377 -81 81 -163 126 -270 149 -85 18 -130 18 -215 -1z
  m232 -251 c60 -27 115 -84 146 -151 65 -142 5 -311 -135 -382 -69 -36 -197
  -36 -266 0 -164 83 -210 284 -101 442 73 105 231 146 356 91z"
                          />
                        </g>
                      </svg>
                    }
                    onClick={() =>
                      userinfo.userid > 0 && cartItems?.length > 0 ? (
                        <>
                          {setShoppingCart(
                            cartItems?.length > 0
                              ? cartItems
                              : alert(
                                  "Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү."
                                )
                          )}
                          {setCartItems([])}
                        </>
                      ) : (
                        <>{navigate("/login")}</>
                      )
                    }
                    size="large"
                    style={{
                      marginRight: 5,
                      marginLeft: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Сагсанд нэмэх
                  </Button>
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={() =>
                      userinfo.userid > 0 && cartItems?.length > 0 ? (
                        <>
                          {setShoppingCart(
                            cartItems?.length > 0
                              ? cartItems
                              : alert(
                                  "Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү."
                                )
                          )}
                          <>{navigate("/checkout")}</>
                        </>
                      ) : (
                        <>{navigate("/login")}</>
                      )
                    }
                  >
                    Худалдаж авах
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Content>

      <Anchor
        direction="horizontal"
        offsetTop={33}
        targetOffset={160}
        className={`sticky-container ${isMobile && "sticky-container mobile"}`}
        items={[
          {
            key: "productInfo",
            href: "#productInfo",
            title: "Барааны мэдээлэл",
          },
          {
            key: "review",
            href: "#review",
            title: "Хэрэглэгчийн өгсөн үнэлгээ",
          },
          {
            key: "delivery",
            href: "#delivery",
            title: "Хүргэлт / Буцаан олголт",
          },
          {
            key: "advice",
            href: "#advice",
            title: "Санал болгох бараа",
          },
        ]}
      />

      <Content style={{ maxWidth: 1024, margin: "0 auto" }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={15} xl={15}>
            <div
              id="productInfo"
              className={`product-desc ${expanded ? "expanded1" : ""}`}
            >
              <div className="image-container1">
                {pbody?.map((body, i) => (
                  <Image
                    src={`${getBaseURL()}/api/file/download?ID=${body?.id}`}
                    alt="product image"
                    preview={false}
                  />
                ))}

                {!expanded && isMobile ? (
                  <div className="img-cont">
                    <div className="linearGradient"></div>
                    <Button
                      block
                      type="primary"
                      className="show-more-button"
                      onClick={toggleExpand}
                      style={{ height: 30 }}
                    >
                      {expanded ? "Show Less" : "Дэлгэрэнгүй харах"}
                      <span style={{ marginLeft: "auto", paddingLeft: 5 }}>
                        <DownOutlined />
                      </span>
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="product-review" id="review">
              <ReviewSection data={griddata} />
            </div>
            <div className="delivery" id="delivery">
              <DeliverySection />
            </div>
            <div className="delivery" id="advice">
              <RecommendProduct />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            {!isMobile ? (
              <Affix offsetTop={130}>
                <div className="product-options">
                  <Dropdown
                    overlay={menus}
                    menu={menus}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <a
                      href="#"
                      className="ant-dropdown-link fss"
                      onClick={(e) => e.preventDefault()}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <b>Барааны сонголт</b>
                      {cartItems?.length > 0 ? (
                        <span>
                          <b style={{ color: "blue", fontSize: "small" }}>
                            {cartItems?.length} бараа сонгогдсон{" "}
                          </b>
                          <DownOutlined />
                        </span>
                      ) : (
                        <DownOutlined />
                      )}
                    </a>
                  </Dropdown>
                </div>
                <div className="product-cart-item">
                  {cartItems?.map((item) => (
                    <Card className="cart-item" bordered={false} key={item?.id}>
                      <div className="space-align-container">
                        <div className="space-align-block">
                          <div className="image-and-text">
                            <img src={item?.image} width={50} alt="" />
                            <p className="ptext-wrap" style={{ fontSize: 12 }}>
                              {item?.name}
                            </p>
                          </div>
                        </div>
                        <Button
                          type="text"
                          onClick={() => removeFromCart(item?.id)}
                          className="close-button"
                        >
                          <CloseOutlined />
                        </Button>
                      </div>
                      <Card className="cart-item2" bordered={false}>
                        <Space
                          className="item-details"
                          direction="horizontal"
                          align="center"
                        >
                          <div className="quantity-controls">
                            <Button
                              type="default"
                              onClick={() => decreaseQuantity(item?.id)}
                            >
                              <MinusOutlined />
                            </Button>
                            <span>{item?.quantity}</span>
                            <Button
                              type="default"
                              onClick={() => increaseQuantity(item?.id)}
                            >
                              <PlusOutlined />
                            </Button>
                          </div>
                          <p className="product-price">
                            <b>{item?.price?.toLocaleString()} ₮</b>
                          </p>
                        </Space>
                      </Card>
                    </Card>
                  ))}
                  <Divider />
                </div>
                <div className="total-price">
                  <Space className="fs">
                    <h4>Захиалгын дүн:</h4>
                    <h2> {totalPrice.toLocaleString()} ₮</h2>
                  </Space>
                </div>
                <div className="order-buttons">
                  <Button
                    type="default"
                    block
                    icon={
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 512.000000 512.000000"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                          fill="#000000"
                          stroke="none"
                        >
                          <path
                            d="M63 5034 c-31 -15 -63 -65 -63 -98 0 -32 29 -85 56 -103 25 -16 58
  -19 289 -23 245 -5 262 -6 302 -28 57 -30 108 -83 136 -140 13 -26 192 -555
  397 -1176 l374 -1128 -18 -47 c-73 -190 -70 -180 -71 -301 0 -94 4 -127 23
  -182 62 -180 210 -315 397 -363 87 -22 2431 -23 2474 -1 82 43 81 161 -2 209
  -17 9 -293 13 -1232 17 l-1210 5 -54 30 c-55 30 -104 83 -137 145 -13 26 -18
  58 -19 125 0 77 4 100 28 156 28 65 30 67 70 73 23 3 584 62 1247 131 663 69
  1230 130 1260 136 120 23 245 86 334 168 65 60 132 167 166 266 37 107 312
  1285 306 1310 -7 29 -41 63 -76 77 -16 6 -742 20 -1962 37 -1066 15 -1939 29
  -1942 32 -2 2 -27 76 -56 164 -74 223 -101 276 -185 361 -77 78 -175 133 -273
  153 -30 6 -156 11 -290 11 -195 0 -243 -3 -269 -16z m3207 -945 c866 -12 1576
  -23 1578 -25 2 -1 -55 -244 -127 -541 -136 -558 -157 -623 -221 -692 -51 -54
  -123 -97 -191 -115 -56 -14 -2435 -266 -2517 -266 l-31 0 -152 463 c-84 254
  -207 630 -275 836 l-123 374 242 -6 c133 -4 951 -16 1817 -28z"
                          />
                          <path
                            d="M1520 1124 c-192 -41 -360 -205 -405 -395 -43 -184 9 -365 143 -499
  137 -137 315 -188 501 -145 93 22 176 71 252 147 119 119 174 280 151 442 -25
  175 -137 329 -297 406 -118 58 -221 71 -345 44z m244 -258 c105 -51 160 -144
  161 -266 0 -59 -5 -84 -23 -120 -36 -68 -99 -127 -160 -150 -243 -91 -473 139
  -382 382 29 78 108 149 200 179 46 15 147 3 204 -25z"
                          />
                          <path
                            d="M3780 1124 c-102 -22 -185 -68 -265 -148 -108 -108 -155 -220 -155
  -371 0 -152 49 -272 157 -379 208 -208 538 -208 746 0 108 107 157 225 157
  373 0 158 -45 267 -155 377 -81 81 -163 126 -270 149 -85 18 -130 18 -215 -1z
  m232 -251 c60 -27 115 -84 146 -151 65 -142 5 -311 -135 -382 -69 -36 -197
  -36 -266 0 -164 83 -210 284 -101 442 73 105 231 146 356 91z"
                          />
                        </g>
                      </svg>
                    }
                    onClick={() =>
                      userinfo.userid > 0 && cartItems?.length > 0 ? (
                        <>
                          {setShoppingCart(
                            cartItems?.length > 0
                              ? cartItems
                              : alert(
                                  "Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү."
                                )
                          )}
                          {setCartItems([])}
                        </>
                      ) : (
                        <>{navigate("/login")}</>
                      )
                    }
                    size="large"
                    style={{
                      marginRight: 5,
                      marginLeft: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Сагсанд нэмэх
                  </Button>
                  &nbsp;
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={() =>
                      userinfo.userid > 0 && cartItems?.length > 0 ? (
                        <>
                          {setShoppingCart(
                            cartItems?.length > 0
                              ? cartItems
                              : alert(
                                  "Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү."
                                )
                          )}
                          <>{navigate("/checkout")}</>
                        </>
                      ) : (
                        <>{navigate("/login")}</>
                      )
                    }
                  >
                    Худалдаж авах
                  </Button>
                </div>
              </Affix>
            ) : (
              <div>
                <div className="order-buttons mobile">
                  <div className="order-bg">
                    <Button
                      type="primary"
                      block
                      size="large"
                      onClick={showDrawer}
                    >
                      Худалдаж авах
                    </Button>
                    <Drawer
                      title="ЗАХИАЛГА"
                      placement="bottom"
                      closable={true}
                      footer={
                        <div className="order-buttons">
                          <Button
                            type="default"
                            icon={
                              <svg
                                version="1.0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 512.000000 512.000000"
                                preserveAspectRatio="xMidYMid meet"
                              >
                                <g
                                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                  fill="#000000"
                                  stroke="none"
                                >
                                  <path
                                    d="M63 5034 c-31 -15 -63 -65 -63 -98 0 -32 29 -85 56 -103 25 -16 58
  -19 289 -23 245 -5 262 -6 302 -28 57 -30 108 -83 136 -140 13 -26 192 -555
  397 -1176 l374 -1128 -18 -47 c-73 -190 -70 -180 -71 -301 0 -94 4 -127 23
  -182 62 -180 210 -315 397 -363 87 -22 2431 -23 2474 -1 82 43 81 161 -2 209
  -17 9 -293 13 -1232 17 l-1210 5 -54 30 c-55 30 -104 83 -137 145 -13 26 -18
  58 -19 125 0 77 4 100 28 156 28 65 30 67 70 73 23 3 584 62 1247 131 663 69
  1230 130 1260 136 120 23 245 86 334 168 65 60 132 167 166 266 37 107 312
  1285 306 1310 -7 29 -41 63 -76 77 -16 6 -742 20 -1962 37 -1066 15 -1939 29
  -1942 32 -2 2 -27 76 -56 164 -74 223 -101 276 -185 361 -77 78 -175 133 -273
  153 -30 6 -156 11 -290 11 -195 0 -243 -3 -269 -16z m3207 -945 c866 -12 1576
  -23 1578 -25 2 -1 -55 -244 -127 -541 -136 -558 -157 -623 -221 -692 -51 -54
  -123 -97 -191 -115 -56 -14 -2435 -266 -2517 -266 l-31 0 -152 463 c-84 254
  -207 630 -275 836 l-123 374 242 -6 c133 -4 951 -16 1817 -28z"
                                  />
                                  <path
                                    d="M1520 1124 c-192 -41 -360 -205 -405 -395 -43 -184 9 -365 143 -499
  137 -137 315 -188 501 -145 93 22 176 71 252 147 119 119 174 280 151 442 -25
  175 -137 329 -297 406 -118 58 -221 71 -345 44z m244 -258 c105 -51 160 -144
  161 -266 0 -59 -5 -84 -23 -120 -36 -68 -99 -127 -160 -150 -243 -91 -473 139
  -382 382 29 78 108 149 200 179 46 15 147 3 204 -25z"
                                  />
                                  <path
                                    d="M3780 1124 c-102 -22 -185 -68 -265 -148 -108 -108 -155 -220 -155
  -371 0 -152 49 -272 157 -379 208 -208 538 -208 746 0 108 107 157 225 157
  373 0 158 -45 267 -155 377 -81 81 -163 126 -270 149 -85 18 -130 18 -215 -1z
  m232 -251 c60 -27 115 -84 146 -151 65 -142 5 -311 -135 -382 -69 -36 -197
  -36 -266 0 -164 83 -210 284 -101 442 73 105 231 146 356 91z"
                                  />
                                </g>
                              </svg>
                            }
                            onClick={() =>
                              userinfo.userid > 0 && cartItems?.length > 0 ? (
                                <>
                                  {setShoppingCart(
                                    cartItems?.length > 0
                                      ? cartItems
                                      : alert(
                                          "Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү."
                                        )
                                  )}
                                  {setCartItems([])}
                                </>
                              ) : (
                                <>{navigate("/login")}</>
                              )
                            }
                            size="large"
                            style={{
                              marginRight: 5,
                              marginLeft: 5,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Сагсанд нэмэх
                          </Button>
                          &nbsp;
                          <Button
                            type="primary"
                            size="large"
                            onClick={() =>
                              userinfo.userid > 0 && cartItems?.length > 0 ? (
                                <>
                                  {setShoppingCart(
                                    cartItems?.length > 0
                                      ? cartItems
                                      : alert(
                                          "Бүтээгдэхүүний сонголтоо хийсний дараа сагсанд хийнэ үү."
                                        )
                                  )}
                                  <>{navigate("/checkout")}</>
                                </>
                              ) : (
                                <>{navigate("/login")}</>
                              )
                            }
                          >
                            Худалдаж авах
                          </Button>
                        </div>
                      }
                      onClose={onCloseDrawer}
                      visible={drawerVisible}
                      height={600} // Set your desired height
                      zIndex={1001} // Ensure it's on top of other content
                    >
                      <div className="product-options">
                        <Dropdown
                          overlay={menus}
                          menu={menus}
                          placement="bottomRight"
                          trigger={["click"]}
                        >
                          <a
                            href="#"
                            className="ant-dropdown-link fss"
                            onClick={(e) => e.preventDefault()}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <b>Барааны сонголт</b>
                            {cartItems?.length > 0 ? (
                              <span>
                                <b
                                  style={{
                                    color: "blue",
                                    fontSize: "10rpx",
                                    paddingRight: 2,
                                  }}
                                >
                                  {cartItems?.length} бараа сонгогдсон{" "}
                                </b>
                                <DownOutlined />
                              </span>
                            ) : (
                              <DownOutlined />
                            )}
                          </a>
                        </Dropdown>
                      </div>
                      <div className="product-cart-item">
                        {cartItems.map((item) => (
                          <Card
                            className="cart-item"
                            bordered={false}
                            key={item?.id}
                          >
                            <div className="space-align-container">
                              <div className="space-align-block">
                                <div className="image-and-text">
                                  <img src={item?.image} width={50} alt="" />
                                  <p className="ptext-wrap">{item?.name}</p>
                                </div>
                              </div>
                              <Button
                                type="text"
                                onClick={() => removeFromCart(item?.id)}
                                className="close-button"
                              >
                                <CloseOutlined />
                              </Button>
                            </div>
                            <Card className="cart-item2" bordered={false}>
                              <Space
                                className="item-details"
                                direction="horizontal"
                                align="center"
                              >
                                <div className="quantity-controls">
                                  <Button
                                    type="default"
                                    onClick={() => decreaseQuantity(item?.id)}
                                  >
                                    <MinusOutlined />
                                  </Button>
                                  <span>{item?.quantity}</span>
                                  <Button
                                    type="default"
                                    onClick={() => increaseQuantity(item?.id)}
                                  >
                                    <PlusOutlined />
                                  </Button>
                                </div>
                                <p className="product-price">
                                  <b>{item?.price?.toLocaleString()} ₮</b>
                                </p>
                              </Space>
                            </Card>
                          </Card>
                        ))}
                        <Divider />
                      </div>
                      <div className="total-price">
                        <Space className="fs" style={{ paddingRight: 20 }}>
                          <h4>Захиалгын дүн: </h4>
                          <h2> {totalPrice?.toLocaleString()} ₮</h2>
                        </Space>
                      </div>
                    </Drawer>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </>
  );
}
