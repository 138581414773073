import React from "react";
import CarouselComp from "../../components/Carousel";
import HotSectionComp from "../../components/HotSection";
import ExtensionList from "../../components/ExhibitionList";
import { Layout } from "antd";
import CategoryCarousel from "../../components/CategoryCarousel";

const { Content } = Layout;
export default function HomePage() {
  return (
    <>
      <CarouselComp />
      <Content style={{ maxWidth: 1200, margin: "0 auto", paddingBottom: 50 }}>
        <ExtensionList />
        <CategoryCarousel />
        <HotSectionComp />
      </Content>
    </>
  );
}
