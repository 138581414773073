import React, { useEffect, useState } from "react";
import { Carousel, Image } from "antd";
import "./style.css";
import { api } from "../../system/api";
import { getBaseURL } from "../../system/apiconfig";

export default function CarouselComp() {
  const [carouselContent, setCarouselContent] = useState([]);

  useEffect(() => {
    api
      .get("/api/file/get_file_data?sourceID=1&sourceType=ecomslider")
      .then((res) => {
        if (res.status === 200 && res.data.rettype === 0) {
          setCarouselContent(res?.data?.retdata);
        }
      })
      .catch((error) => {
        console.error("Error fetching carousel data:", error);
      });
  }, []);
  return (
    <>
      <Carousel className="custom-carousel" draggable autoplay>
        {carouselContent?.map((content, index) => (
          <div key={index} className="carousel-slide">
            <div className="carousel-image">
              <Image
                src={`${getBaseURL()}/api/file/download?ID=${content?.id}`}
                alt={`Image ${index}`}
                preview={false}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}
