import React, { useState, useEffect } from "react";
import {
  Tabs,
  Space,
  Row,
  Col,
  Tag,
  Drawer,
  Card,
  Divider,
  Spin,
  Button,
  Image,
  Flex,
  Typography,
  Alert,
  Modal,
  message,
  QRCode,
} from "antd";
import { api } from "../../system/api";
import { getBaseURL } from "../../system/apiconfig";
import useUserInfo from "../../system/useUserInfo";
import { useAppContext } from "../../Context/index";
import {
  CheckOutlined,
  QrcodeOutlined,
  ExclamationCircleOutlined,
  SolutionOutlined,
  FileProtectOutlined,
  TruckOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import "./style.css";
import Countdown from "./CountDown";
import AllOrdersTab from "./AllOrder";
import PaidOrderTab from "./PaidOrder";
import DeliveredOrderTab from "./DeliveredOrder";
import CanceledOrderTab from "./CanceledOrder";
const { TabPane } = Tabs;
const { Paragraph } = Typography;
export default function Order() {
  const [griddata, setGridData] = useState();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const { userinfo } = useUserInfo;
  const { state } = useAppContext();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [paidOrder, setPaidOrder] = useState(null);
  const [deliveredOrder, setDeliveredOrder] = useState(null);
  const [canceledOrder, setCanceledOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ebarimt, setEbarimt] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(
          `/api/Product/get_Order_list?userid=${state?.userid}`
        );
        if (res?.status === 200 && res?.data?.rettype === 0) {
          const data = res?.data?.retdata;
          // Filter data based on status
          const paidOrders = data?.filter((item) => item?.status === 2);
          const deliveredOrders = data?.filter((item) => item?.status === 6);
          const canceledOrders = data?.filter((item) => item?.status === -1);
          // Set the states
          setGridData(data);
          setPaidOrder(paidOrders);
          setDeliveredOrder(deliveredOrders);
          setCanceledOrder(canceledOrders);
        } else {
          console.error("Invalid API response:", res);
        }
      } catch (error) {
        console.error("Error in API call:", error);
      } finally {
        setLoading(false);
      }
    };

    if (state?.userid > 0) {
      fetchData();
    }
  }, [state?.userid, userinfo]); // Include all dependencies here

  useEffect(() => {
    const fetchInvoice = async () => {
      const params = new URLSearchParams(window?.location?.search);
      const keyParam = params.get("key");
      const order = griddata?.find((item) => item?.id === parseInt(keyParam));
      if (order) {
        showDrawer(order);
      }
    };
    fetchInvoice();
  }, [griddata]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const showDrawer = async (order) => {
    if (order) {
      setLoading2(true);
      try {
        const res = await api.get(`/api/Product/get_order?id=${order?.id}`);
        if (res?.status === 200 && res?.data?.rettype === 0) {
          setOrderDetails(res?.data?.retdata);
        } else {
          console.error("Invalid API response:", res);
        }
      } catch (error) {
        console.error("Error in API call:", error);
      } finally {
        setLoading(false);
      }
    }
    setSelectedOrder(order);
  };

  const closeDrawer = () => {
    setSelectedOrder(null);
  };

  const deadline = moment(
    orderDetails?.orderStatus[0]?.statusdate,
    "YYYY-MM-DDTHH:mm:ss"
  )
    .add(30, "minutes")
    .format("HH:mm:ss");

  const checkPayment = async () => {
    try {
      const res = await api.post("/api/Product/qpay_payment_check", {
        object_type: "INVOICE",
        object_id: `${orderDetails?.qpayInvoice?.invoice_id}`,
        offset: {
          page_number: 1,
          page_limit: 10,
        },
      });
      if (res?.data?.retdata?.rows?.length > 0) {
        message.success("Төлбөр амжилттай төлөгдлөө.");
        window.location.reload();
      } else {
        message.warning("Төлбөр төлөгдөөгүй байна.");
      }
    } catch (error) {
      console.error("Error in payment check:", error);
    }
  };

  const showModal = async () => {
    try {
      // Fetch the order code
      const codeResponse = await api.get(
        `/api/Product/get_order_code?id=${orderDetails?.id}`
      );
      if (codeResponse?.status === 200 && codeResponse?.data?.rettype === 0) {
        const orderCode = codeResponse?.data?.retdata?.vercode;

        // Update order status
        await api.post("/api/Product/update_order_status", {
          orderid: orderDetails?.id,
          status: 7,
          statusdate: new Date().toISOString(), // Set current date and time
          ownerUserid: state?.userid,
          verCode: orderCode,
        });

        // Proceed to fetch e-barimt data
        const ebarimtResponse = await api.get(
          `/api/Product/qpay_ebarimt_create?orderid=${orderDetails?.id}`
        );
        if (
          ebarimtResponse?.status === 200 &&
          ebarimtResponse?.data?.rettype === 0
        ) {
          setEbarimt(ebarimtResponse?.data?.retdata);
        } else {
          message.warning("И-баримт байхгүй байна.");
        }
      } else {
        message.error("Error fetching order code.");
      }
    } catch (error) {
      console.error("Error in showModal:", error);
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isMobile ? (
        <Tabs className="st-menu" style={{ lineHeight: 3 }}>
          <TabPane tab="Бүх захиалга" key="3">
            <div
              style={{ maxWidth: 1200, margin: "0 auto", paddingBottom: 100 }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <AllOrdersTab
                    griddata={griddata}
                    showDrawer={showDrawer}
                    closeDrawer={closeDrawer}
                    loading={loading}
                  />
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Төлбөр төлөгдсөн" key="6">
            <div
              style={{ maxWidth: 1200, margin: "0 auto", paddingBottom: 100 }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <PaidOrderTab
                    griddata={paidOrder}
                    showDrawer={showDrawer}
                    loading={loading}
                  />
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Хүргэгдсэн" key="7">
            <div
              style={{ maxWidth: 1200, margin: "0 auto", paddingBottom: 100 }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <DeliveredOrderTab
                    griddata={deliveredOrder}
                    showDrawer={showDrawer}
                    loading={loading}
                  />
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Цуцалсан" key="8">
            <div
              style={{ maxWidth: 1200, margin: "0 auto", paddingBottom: 100 }}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <CanceledOrderTab
                    griddata={canceledOrder}
                    showDrawer={showDrawer}
                    loading={loading}
                  />
                </Col>
              </Row>
            </div>
          </TabPane>
        </Tabs>
      ) : (
        <Tabs className="st-menu" style={{ lineHeight: 3 }}>
          <TabPane tab="Бүх захиалга" key="3">
            <div style={{ maxWidth: 1200, margin: "0 auto" }}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <AllOrdersTab
                    griddata={griddata}
                    showDrawer={showDrawer}
                    closeDrawer={closeDrawer}
                  />
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Төлбөр төлөгдсөн" key="6">
            <div style={{ maxWidth: 1200, margin: "0 auto" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <PaidOrderTab griddata={paidOrder} showDrawer={showDrawer} />
              </Col>
            </div>
          </TabPane>
          <TabPane tab="Хүргэгдсэн" key="7">
            <div style={{ maxWidth: 1200, margin: "0 auto" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <DeliveredOrderTab
                  griddata={deliveredOrder}
                  showDrawer={showDrawer}
                />
              </Col>
            </div>
          </TabPane>
          <TabPane tab="Цуцалсан" key="8">
            <div style={{ maxWidth: 1200, margin: "0 auto" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <CanceledOrderTab
                  griddata={canceledOrder}
                  showDrawer={showDrawer}
                />
              </Col>
            </div>
          </TabPane>
        </Tabs>
      )}
      <Drawer
        title={
          <>
            {orderDetails?.status === 1 &&
            moment().diff(orderDetails?.orderdate, "minutes") <= 30 ? (
              <Flex className="fs" align="center" vertical={isMobile && "true"}>
                {isMobile ? (
                  <>
                    <Flex
                      align="center"
                      justify="space-between"
                      vertical={isMobile && "true"}
                    >
                      <span>Захиалгын дэлгэрэнгүй мэдээлэл</span>
                      <Space
                        className="countD"
                        align="center"
                        justify="space-evenly"
                        style={{ fontSize: 10 }}
                      >
                        <Tag
                          color="default"
                          icon={<ExclamationCircleOutlined />}
                          style={{ fontSize: 10 }}
                        >
                          Төлбөр хүлээгдэж байна.
                        </Tag>
                        <Countdown
                          title="Төлбөр төлөх хугацаа"
                          value={deadline}
                        />
                      </Space>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Space>
                      Захиалгын дэлгэрэнгүй мэдээлэл
                      <Tag
                        color="default"
                        style={{
                          whiteSpace: "normal",
                          display: "inline-block",
                        }}
                      >
                        Төлбөр хүлээгдэж байна.
                      </Tag>
                    </Space>
                    <div className="countD">
                      <Countdown
                        title="Төлбөр төлөх хугацаа"
                        value={deadline}
                      />
                    </div>
                  </>
                )}
              </Flex>
            ) : orderDetails?.status === -1 ||
              orderDetails?.status === 1 ||
              (orderDetails?.status === 0 &&
                moment().diff(orderDetails?.orderdate, "minutes") >= 30) ? (
              <Flex
                align="center"
                justify="space-between"
                vertical={isMobile && "true"}
              >
                <span>Захиалгын дэлгэрэнгүй мэдээлэл</span>
                <Tag
                  color="orange"
                  icon={<ExclamationCircleOutlined />}
                  bordered={false}
                >
                  Захиалга цуцлагдсан
                </Tag>
              </Flex>
            ) : orderDetails?.status === 2 ? (
              <Flex
                align="center"
                justify="space-between"
                vertical={isMobile && "true"}
              >
                <span>Захиалгын дэлгэрэнгүй мэдээлэл</span>
                <Tag color="green" icon={<CheckOutlined />} bordered={false}>
                  Төлбөр төлөгдсөн
                </Tag>
              </Flex>
            ) : orderDetails?.status === 3 ? (
              <Flex
                align="center"
                justify="space-between"
                vertical={isMobile && "true"}
              >
                <span>Захиалгын дэлгэрэнгүй мэдээлэл</span>
                <Tag color="green" icon={<SolutionOutlined />} bordered={false}>
                  Захиалга бэлтгэгдэж байна
                </Tag>
              </Flex>
            ) : orderDetails?.status === 4 ? (
              <Flex
                align="center"
                justify="space-between"
                vertical={isMobile && "true"}
              >
                <span>Захиалгын дэлгэрэнгүй мэдээлэл</span>
                <Tag
                  color="green"
                  icon={<FileProtectOutlined />}
                  bordered={false}
                >
                  Захиалга бэлдэж дууссан
                </Tag>
              </Flex>
            ) : orderDetails?.status === 5 ? (
              <Flex
                align="center"
                justify="space-between"
                vertical={isMobile && "true"}
              >
                <span>Захиалгын дэлгэрэнгүй мэдээлэл</span>
                <Tag color="green" icon={<TruckOutlined />} bordered={false}>
                  Хүргэлтэд гарсан
                </Tag>
              </Flex>
            ) : orderDetails?.status === 6 ? (
              <Flex
                align="center"
                justify="space-between"
                vertical={isMobile && "true"}
              >
                <span>Захиалгын дэлгэрэнгүй мэдээлэл</span>
                <Tag color="green" icon={<SmileOutlined />} bordered={false}>
                  Захиалгыг амжилттай хүргэсэн
                </Tag>
              </Flex>
            ) : null}
          </>
        }
        placement="bottom"
        closable={true}
        height={"80%"}
        onClose={closeDrawer}
        visible={selectedOrder !== null}
        loading={loading2}
      >
        {!isMobile ? (
          <Row gutter={[16, 16]}>
            <Col span={8}>
              {orderDetails?.status <= 5 && (
                <Card
                  title={
                    <Space direction="vertical">
                      <b>ТӨЛБӨРИЙН МЭДЭЭЛЭЛ</b>
                      <p className="pm0 f12">
                        Аль ч банкны аппликейшн ашиглан QR код уншуулж болно.
                      </p>
                    </Space>
                  }
                  headStyle={{ backgroundColor: "#fbfbfb" }}
                >
                  {orderDetails?.status < 2 &&
                  moment().diff(orderDetails?.orderdate, "minutes") <= 30 ? (
                    <>
                      <Flex justify="space-around">
                        <Space
                          direction="vertical"
                          align="center"
                          style={{ display: "flex" }}
                        >
                          <h3>
                            <QrcodeOutlined /> QR код уншуулах
                          </h3>
                          {loading1 ? (
                            <Spin />
                          ) : (
                            <Image
                              loading={"lazy"}
                              width={200}
                              src={`data:image/png;base64, ${orderDetails?.qpayInvoice?.qr_image}`}
                            />
                          )}
                          <span>
                            <p className="pm0">Захиалгын дүн</p>
                            <h1 className="pm0">
                              {orderDetails?.totalprice?.toLocaleString()}₮
                            </h1>
                          </span>
                          <Button
                            type="primary"
                            block
                            icon={<CheckOutlined />}
                            onClick={checkPayment}
                          >
                            ТӨЛБӨР ШАЛГАХ
                          </Button>
                        </Space>
                      </Flex>
                      <br />
                      <Alert
                        message={`Төлбөр бүрэн төлөгдсөний дараа таны захиалга баталгаажих болно.`}
                        type="warning"
                        showIcon
                      />
                    </>
                  ) : orderDetails?.status === 0 ||
                    orderDetails?.status === 1 ||
                    (orderDetails?.status === -1 &&
                      moment().diff(orderDetails?.orderdate, "minutes") >=
                        30) ? (
                    <Space
                      direction="vertical"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h3 className="pm0">Захиалга цуцлагдсан</h3>
                      <Alert
                        type="warning"
                        message=" Төлбөр төлөгдөөгүй 30 минут өнгөрсөн учраас захиалга
                      цуцлагдсан."
                        showIcon
                      ></Alert>
                    </Space>
                  ) : orderDetails?.status === 2 ? (
                    <Space
                      direction="vertical"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Alert
                        showIcon
                        type="success"
                        message="Төлбөр төлөгдсөн"
                      ></Alert>
                    </Space>
                  ) : orderDetails?.status === 3 ? (
                    <Alert
                      showIcon
                      type="success"
                      message="Захиалга бэлтгэгдэж байна"
                    ></Alert>
                  ) : orderDetails?.status === 4 ? (
                    <Alert
                      showIcon
                      type="success"
                      message="Захиалга бэлдэж дууссан"
                    ></Alert>
                  ) : orderDetails?.status === 5 ? (
                    <Alert
                      showIcon
                      type="success"
                      message="Хүргэлтэд гарсан"
                    ></Alert>
                  ) : orderDetails?.status === 6 ? (
                    <Alert showIcon type="success" message="Хүргэгдсэн"></Alert>
                  ) : orderDetails?.status === 7 ? (
                    <Alert
                      showIcon
                      type="success"
                      message="И-баримт хэвлэсэн"
                    ></Alert>
                  ) : (
                    ""
                  )}
                </Card>
              )}
              {orderDetails?.status >= 6 && (
                <Card
                  title={
                    <Space direction="vertical">
                      <b>И-БАРИМТЫН МЭДЭЭЛЭЛ</b>
                    </Space>
                  }
                  headStyle={{ backgroundColor: "#fbfbfb" }}
                >
                  <Flex>
                    {orderDetails?.regno !== null ? (
                      <Alert
                        showIcon
                        type="info"
                        message={`${orderDetails?.regno} регистрийн дугаартай байгууллага дээр и-баримтыг шивсэн.`}
                      ></Alert>
                    ) : (
                      <>
                        <Button
                          type="primary"
                          block
                          icon={<CheckOutlined />}
                          onClick={showModal}
                        >
                          И-БАРИМТ АВАХ
                        </Button>
                        {isModalOpen && (
                          <Modal
                            title={<b>И-БАРИМТЫН МЭДЭЭЛЭЛ</b>}
                            open={isModalOpen}
                            onCancel={handleCancel}
                            footer={null}
                            centered
                          >
                            <Card>
                              <Flex justify="center">
                                <QRCode value={ebarimt[0]?.ebarimt_qr_data} />
                              </Flex>
                              <Flex
                                align="normal"
                                gap="large"
                                justify="space-around"
                              >
                                <b>И-баримт төрөл:</b>
                                <Paragraph>
                                  {ebarimt[0]?.ebarimt_receiver_type ===
                                  "CITIZEN"
                                    ? "Хувь хүн"
                                    : "Байгууллага"}
                                </Paragraph>
                              </Flex>
                              <Flex justify="space-around" align="normal">
                                <b>Сугалааны дугаар:</b>
                                <Paragraph copyable>
                                  {ebarimt[0]?.ebarimt_lottery}
                                </Paragraph>
                              </Flex>
                              <Flex justify="space-around" align="normal">
                                <b>Баримтын дүн:</b>
                                <Paragraph copyable>
                                  {ebarimt[0]?.amount.toLocaleString()}
                                </Paragraph>
                              </Flex>
                            </Card>
                          </Modal>
                        )}
                      </>
                    )}
                  </Flex>
                </Card>
              )}
            </Col>
            <Col span={8}>
              <Card
                title={<b>ЗАХИАЛГЫН МЭДЭЭЛЭЛ</b>}
                className="custom-card"
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="horizontal"
                  style={{ padding: 10 }}
                  className="fs"
                >
                  <span>
                    <p className="pm0">Захиалгын дугаар</p>
                    <h2 className="pm0">{orderDetails?.id}</h2>
                  </span>
                  <span>
                    <p className="pm0">Захиалга хийсэн огноо</p>
                    <h2 className="pm0">
                      {moment(
                        orderDetails?.orderStatus[
                          orderDetails?.orderStatus?.length - 1
                        ]?.statusdate
                      ).format("YYYY/MM/DD HH:mm")}
                    </h2>
                  </span>
                </Space>
              </Card>
              <Card
                className="mt15"
                title={<b>ХҮРГЭЛТИЙН МЭДЭЭЛЭЛ</b>}
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="horizontal"
                  style={{ padding: 10 }}
                  className="fs"
                >
                  <span>
                    <b>Хаяг:</b> {orderDetails?.userAddress?.province},
                    {orderDetails?.userAddress?.district},{" "}
                    {orderDetails?.userAddress?.committee},
                    {orderDetails?.userAddress?.address}
                  </span>
                  <span>
                    <b>Утас:</b>
                    {orderDetails?.userAddress?.phone},
                    {orderDetails?.userAddress?.phone2}
                  </span>
                </Space>
              </Card>
              <br />
              <Card
                title={<b>ЗАХИАЛАГЧИЙН МЭДЭЭЛЭЛ</b>}
                className="custom-card"
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="horizontal"
                  className="fs"
                  style={{ padding: 10 }}
                >
                  <span>
                    <b>Овог</b>
                    <p className="pm0">
                      {orderDetails?.userAddress?.firstname}
                    </p>
                  </span>
                  <span>
                    <b>Нэр</b>
                    <p className="pm0">{orderDetails?.userAddress?.lastname}</p>
                  </span>
                  <span>
                    <b>Утас 1</b>
                    <p className="pm0">{orderDetails?.userAddress?.phone}</p>
                  </span>
                  <span>
                    <b>Утас 2</b>
                    <p className="pm0">{orderDetails?.userAddress?.phone2}</p>
                  </span>
                </Space>
              </Card>
              <br />
              <Card
                title={<b>И-БАРИМТЫН МЭДЭЭЛЭЛ</b>}
                className="custom-card"
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="horizontal"
                  style={{ padding: 10 }}
                  className="fs"
                >
                  <span>
                    <b>Хувь хүн/Байгууллага:</b>{" "}
                    {orderDetails?.regno === null
                      ? "Хувь хүн"
                      : orderDetails?.regno}
                  </span>
                </Space>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title={
                  <Space direction="vertical">
                    <b>БАРААНЫ МЭДЭЭЛЭЛ</b>
                    <p className="pm0 f12">
                      Захиалсан барааны мэдээллийг харуулж байна.
                    </p>
                  </Space>
                }
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="vertical"
                  style={{ padding: 10, maxHeight: "400px", overflowY: "auto" }}
                >
                  {orderDetails?.orderDetails?.map((item, i) => (
                    <div className="p-info">
                      <div>
                        <p>{i + 1}</p>
                      </div>
                      <div className="p-image">
                        <Image
                          src={`${getBaseURL()}/api/file/download?ID=${
                            item?.item?.images?.length > 0
                              ? item?.item?.images[0]?.id
                              : ""
                          }&size=50&type=1`}
                          alt="Image"
                          style={{ width: 50 }}
                          preview={false}
                        />
                      </div>
                      <div className="p-details">
                        <b className="lh">{item?.item?.itemtype}</b>
                        <p className="lh light">
                          {(item?.item?.name).substring(0, 100) + "..."}
                        </p>
                      </div>
                    </div>
                  ))}
                </Space>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {orderDetails?.status <= 1 && (
                <Card
                  className="mt15"
                  title={
                    <Space direction="vertical">
                      <b>ТӨЛБӨРИЙН МЭДЭЭЛЭЛ</b>
                      <p className="pm0 f12">
                        Аль ч банкны аппликейшн ашиглан уншуулж болно.
                      </p>
                    </Space>
                  }
                  actions={
                    orderDetails?.status < 2 &&
                    orderDetails?.status > 0 &&
                    moment().diff(orderDetails?.orderdate, "minutes") <= 30 && [
                      <Space>
                        <Button
                          type="primary"
                          block
                          icon={<CheckOutlined />}
                          onClick={checkPayment}
                        >
                          ТӨЛБӨР ШАЛГАХ
                        </Button>
                      </Space>,
                    ]
                  }
                  headStyle={{ backgroundColor: "#fbfbfb" }}
                >
                  <>
                    <>
                      {orderDetails?.status < 2 &&
                      moment().diff(orderDetails?.orderdate, "minutes") <=
                        30 ? (
                        <>
                          <Alert
                            showIcon
                            closable
                            type="warning"
                            style={{ marginTop: 10, marginBottom: 10 }}
                            message="
                            Төлбөр бүрэн төлөгдсөний дараа таны захиалга баталгаажих болно."
                          />

                          <Flex align="center" justify="space-around">
                            <Image
                              loading={"lazy"}
                              width={100}
                              src={`data:image/png;base64, ${orderDetails?.qpayInvoice?.qr_image}`}
                            />

                            <Flex
                              align="center"
                              vertical
                              justify="space-around"
                            >
                              <p className="pm0">Захиалгын дүн</p>
                              <h3 className="pm0">
                                {orderDetails?.totalprice?.toLocaleString()}₮
                              </h3>
                            </Flex>
                          </Flex>
                        </>
                      ) : orderDetails?.status === -1 ||
                        orderDetails?.status === 1 ||
                        (orderDetails?.status === 0 &&
                          moment().diff(orderDetails?.orderdate, "minutes") >=
                            30) ? (
                        <Space
                          direction="vertical"
                          align="center"
                          style={{ padding: 5 }}
                        >
                          <h3 className="pm0">Захиалга цуцлагдсан </h3>
                          <Alert
                            type="warning"
                            showIcon
                            message=" Төлбөр төлөгдөөгүй 30 минут өнгөрсөн учраас захиалга
                            цуцлагдсан."
                          ></Alert>
                        </Space>
                      ) : orderDetails?.status === 2 ? (
                        <Alert
                          showIcon
                          type="success"
                          message="Төлбөр төлөгдсөн"
                        ></Alert>
                      ) : orderDetails?.status === 3 ? (
                        <Alert
                          showIcon
                          type="success"
                          message="Захиалга бэлтгэгдэж байна"
                        ></Alert>
                      ) : orderDetails?.status === 4 ? (
                        <Alert
                          showIcon
                          type="success"
                          message="Захиалга бэлдэж дууссан"
                        ></Alert>
                      ) : orderDetails?.status === 5 ? (
                        <Alert
                          showIcon
                          type="success"
                          message="Хүргэлтэд гарсан"
                        ></Alert>
                      ) : orderDetails?.status === 6 ? (
                        <Alert
                          showIcon
                          type="success"
                          message="Хүргэгдсэн"
                        ></Alert>
                      ) : orderDetails?.status === 7 ? (
                        <Alert
                          showIcon
                          type="success"
                          message="И-баримт хэвлэсэн"
                        ></Alert>
                      ) : (
                        ""
                      )}
                    </>
                  </>
                  <Divider style={{ padding: 0, margin: 0 }} />
                  {orderDetails?.qpayInvoice?.urls !== null &&
                    orderDetails?.status < 2 &&
                    orderDetails?.status > 0 &&
                    moment().diff(orderDetails?.orderdate, "minutes") <= 30 && (
                      <>
                        <Row
                          style={{
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 10,
                          }}
                        >
                          <Col style={{ width: "50%" }}>
                            <Link to={orderDetails?.qpayInvoice?.urls[1]?.link}>
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={orderDetails?.qpayInvoice?.urls[1]?.logo}
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[1]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                          <Col style={{ width: "50%" }}>
                            <Link to={orderDetails?.qpayInvoice?.urls[5]?.link}>
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={orderDetails?.qpayInvoice?.urls[5]?.logo}
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[5]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 10,
                          }}
                        >
                          <Col style={{ width: "50%" }}>
                            <Link to={orderDetails?.qpayInvoice?.urls[2]?.link}>
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={orderDetails?.qpayInvoice?.urls[2]?.logo}
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[2]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                          <Col style={{ width: "50%" }}>
                            <Link to={orderDetails?.qpayInvoice?.urls[6]?.link}>
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={orderDetails?.qpayInvoice?.urls[6]?.logo}
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[6]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 10,
                          }}
                        >
                          <Col style={{ width: "50%" }}>
                            <Link to={orderDetails?.qpayInvoice?.urls[4]?.link}>
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={orderDetails?.qpayInvoice?.urls[4]?.logo}
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[4]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                          <Col style={{ width: "50%" }}>
                            <Link
                              to={orderDetails?.qpayInvoice?.urls[10]?.link}
                            >
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={
                                    orderDetails?.qpayInvoice?.urls[10]?.logo
                                  }
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[10]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <Col style={{ width: "50%" }}>
                            <Link
                              to={orderDetails?.qpayInvoice?.urls[12]?.link}
                            >
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={
                                    orderDetails?.qpayInvoice?.urls[12]?.logo
                                  }
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[12]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                          <Col style={{ width: "50%" }}>
                            <Link to={orderDetails?.qpayInvoice?.urls[9]?.link}>
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={orderDetails?.qpayInvoice?.urls[9]?.logo}
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[9]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <Col style={{ width: "50%" }}>
                            <Link
                              to={orderDetails?.qpayInvoice?.urls[13]?.link}
                            >
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={
                                    orderDetails?.qpayInvoice?.urls[13]?.logo
                                  }
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[13]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                          <Col style={{ width: "50%" }}>
                            <Link
                              to={orderDetails?.qpayInvoice?.urls[11]?.link}
                            >
                              <Space style={{ flex: 1 }}>
                                <Image
                                  preview={false}
                                  src={
                                    orderDetails?.qpayInvoice?.urls[11]?.logo
                                  }
                                  width={40}
                                />
                                <b>
                                  {orderDetails?.qpayInvoice?.urls[11]?.name}
                                </b>
                              </Space>
                            </Link>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <Col>
                            <Button
                              type="link"
                              target="_blank"
                              href={orderDetails?.qpayInvoice?.qpay_shorturl}
                            >
                              Бусад банк бол энд дарна уу
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                </Card>
              )}
              {orderDetails?.status >= 6 && (
                <Card
                  title={
                    <Space direction="vertical">
                      <b>И-БАРИМТЫН МЭДЭЭЛЭЛ</b>
                    </Space>
                  }
                  headStyle={{ backgroundColor: "#fbfbfb" }}
                >
                  <Flex>
                    {orderDetails?.regno !== null ? (
                      <Alert
                        showIcon
                        type="info"
                        message={`${orderDetails?.regno} регистрийн дугаартай байгууллага дээр и-баримтыг шивсэн.`}
                      ></Alert>
                    ) : (
                      <>
                        <Button
                          type="primary"
                          block
                          icon={<CheckOutlined />}
                          onClick={showModal}
                        >
                          И-БАРИМТ АВАХ
                        </Button>
                        {isModalOpen && (
                          <Modal
                            title={<b>И-БАРИМТЫН МЭДЭЭЛЭЛ</b>}
                            open={isModalOpen}
                            onCancel={handleCancel}
                            footer={null}
                            centered
                          >
                            <Card>
                              <Flex justify="center">
                                <QRCode value={ebarimt[0]?.ebarimt_qr_data} />
                              </Flex>
                              <Flex
                                align="normal"
                                gap="large"
                                justify="space-around"
                              >
                                <b>И-баримт төрөл:</b>
                                <Paragraph>
                                  {ebarimt[0]?.ebarimt_receiver_type ===
                                  "CITIZEN"
                                    ? "Хувь хүн"
                                    : "Байгууллага"}
                                </Paragraph>
                              </Flex>
                              <Flex justify="space-around" align="normal">
                                <b>Сугалааны дугаар:</b>
                                <Paragraph copyable>
                                  {ebarimt[0]?.ebarimt_lottery}
                                </Paragraph>
                              </Flex>
                              <Flex justify="space-around" align="normal">
                                <b>Баримтын дүн:</b>
                                <Paragraph copyable>
                                  {ebarimt[0]?.amount.toLocaleString()}
                                </Paragraph>
                              </Flex>
                            </Card>
                          </Modal>
                        )}
                      </>
                    )}
                  </Flex>
                </Card>
              )}
            </Col>
            <Col span={24}>
              <Card
                title={<b>ЗАХИАЛГЫН МЭДЭЭЛЭЛ</b>}
                className="custom-card"
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="horizontal"
                  style={{ padding: 10, marginTop: 20, marginBottom: 20 }}
                  className="fs"
                >
                  <span>
                    <p className="pm0">Захиалгын дугаар</p>
                    <h2 className="pm0">{orderDetails?.id}</h2>
                  </span>
                  <span>
                    <p className="pm0">Захиалга хийсэн огноо</p>
                    <h2 className="pm0">
                      {moment(
                        orderDetails?.orderStatus[
                          orderDetails?.orderStatus?.length - 1
                        ]?.statusdate
                      ).format("YYYY/MM/DD HH:mm")}
                    </h2>
                  </span>
                </Space>
              </Card>
              <Card
                className="mt15"
                title={<b>ХҮРГЭЛТИЙН МЭДЭЭЛЭЛ</b>}
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="horizontal"
                  style={{ padding: 10 }}
                  className="fs"
                >
                  <span>
                    <b>Хаяг:</b> {orderDetails?.userAddress?.province},
                    {orderDetails?.userAddress?.district},{" "}
                    {orderDetails?.userAddress?.committee},
                    {orderDetails?.userAddress?.address}
                  </span>
                  <span>
                    <b>Утас:</b>
                    {orderDetails?.userAddress?.phone},
                    {orderDetails?.userAddress?.phone2}
                  </span>
                </Space>
              </Card>
              <br />
              <Card
                title={<b>ЗАХИАЛАГЧИЙН МЭДЭЭЛЭЛ</b>}
                className="custom-card"
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="horizontal"
                  className="fs"
                  style={{ padding: 10 }}
                >
                  <span>
                    <b>Овог</b>
                    <p className="pm0">
                      {orderDetails?.userAddress?.firstname}
                    </p>
                  </span>
                  <span>
                    <b>Нэр</b>
                    <p className="pm0">{orderDetails?.userAddress?.lastname}</p>
                  </span>
                  <span>
                    <b>Утас 1</b>
                    <p className="pm0">{orderDetails?.userAddress?.phone}</p>
                  </span>
                  <span>
                    <b>Утас 2</b>
                    <p className="pm0">{orderDetails?.userAddress?.phone2}</p>
                  </span>
                </Space>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={
                  <Space direction="vertical">
                    <b>БАРААНЫ МЭДЭЭЛЭЛ</b>
                    <p className="pm0 f12">
                      Захиалсан барааны мэдээллийг харуулж байна.
                    </p>
                  </Space>
                }
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="vertical"
                  style={{
                    padding: 10,
                    maxHeight: "400px",
                    overflowY: "auto",
                    marginBottom: 10,
                  }}
                >
                  {orderDetails?.orderDetails?.map((item, i) => (
                    <div className="p-info">
                      <div>
                        <p>{i + 1}</p>
                      </div>
                      <div className="p-image">
                        <Image
                          src={`${getBaseURL()}/api/file/download?ID=${
                            item?.item?.images?.length > 0
                              ? item?.item?.images[0]?.id
                              : ""
                          }&size=50&type=1`}
                          alt="Image"
                          style={{ width: 50 }}
                          preview={false}
                        />
                      </div>
                      <div className="p-details">
                        <b className="lh">{item?.item?.itemtype}</b>
                        <p className="lh light">
                          {(item?.item?.name).substring(0, 72) + "..."}
                        </p>
                      </div>
                    </div>
                  ))}
                </Space>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                className="mb15"
                title={<b>И-БАРИМТЫН МЭДЭЭЛЭЛ</b>}
                headStyle={{ backgroundColor: "#fbfbfb" }}
              >
                <Space
                  direction="horizontal"
                  style={{ padding: 10 }}
                  className="fs"
                >
                  <span>
                    <b>Хувь хүн/Байгууллага:</b>{" "}
                    {orderDetails?.regno === null
                      ? "Хувь хүн"
                      : orderDetails?.regno}
                  </span>
                </Space>
              </Card>
            </Col>
          </Row>
        )}
      </Drawer>
    </>
  );
}
