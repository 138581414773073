import React, { useState, useEffect } from "react";
import { Card, Badge, Typography, Rate, Space, Button, Image } from "antd";
import Countdown from "antd/lib/statistic/Countdown";
import "./style.css";
import { Link } from "react-router-dom";
import { StarOutlined } from "@ant-design/icons";

const { Meta } = Card;
const { Text } = Typography;

export default function RecommendProduct() {
  const exampleProducts = [
    {
      id:1,
      productName: "Example Product 1",
      imageUrl: "/assets/images/p1.avif",
      discountPercent: 20,
      starRating: 4.5,
      lastOrdered: 123,
      price: 450000,
      saleEndDate: Date.now() + 2 * 24 * 60 * 60 * 1000, // Sale end date
    },
    {
      id:2,
      productName: "Example Product 2 sdas Example Product 2",
      imageUrl: "/assets/images/169571280543333268.avif",
      discountPercent: 30,
      starRating: 4.0,
      lastOrdered: 541,
      price: 550000,
      saleEndDate: Date.now() + 3 * 24 * 60 * 60 * 1000, // Sale end date
    },
    {
      id:3,
      productName: "Example Product 3",
      imageUrl: "/assets/images/p2.jpeg",
      discountPercent: 15,
      starRating: 4.2,
      lastOrdered: 7890,
      price: 420000,
      saleEndDate: Date.now() + 1 * 24 * 60 * 60 * 1000, // Sale end date
    },
    {
      productName: "Example Product 4",
      imageUrl: "/assets/images/p3.webp",
      discountPercent: 25,
      starRating: 3.8,
      lastOrdered: 9876,
      price: 600000,
      saleEndDate: Date.now() + 4 * 24 * 60 * 60 * 1000, // Sale end date
    },
  ];

  const formatNumberWithCommas = (number) => {
    return number?.toLocaleString();
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="recom-body">
      <Space direction="horizontal" className="h-style">
        <h3>Санал болгох бараа</h3>
      </Space>
      <div className={`product-container ${isMobile ? "mobile" : ""}`}>
        {exampleProducts.map((product, index) => (
          <Card
            className={`crt ${isMobile ? "mobile-card" : ""}`}
            key={index}
            style={{ border: 0 }}
          >
            {!isMobile ? (
              <>
                <div className="image-container">
                  <Link to={`/product/${product.id}`}>
                    <Image
                      src={product.imageUrl}
                      alt="Product Image"
                      className="product-image"
                      preview={false}
                    />
                  </Link>
                </div>
                <div className="sale-timer">
                  <Countdown
                    value={product.saleEndDate}
                    format="Дуусах: H[ц] m[м] s[с]"
                  />
                </div>
                <div className="favorite-button">
                  <Badge>
                    <Link to="/collections">
                      <StarOutlined className="starsvg" />
                    </Link>
                  </Badge>
                </div>
                <Meta
                  title={
                    <Text
                      className="product-title"
                      strong
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {product.productName}
                    </Text>
                  }
                  description={
                    <>
                      <Space>
                        <div>
                          <Text className="discount-percent" strong>
                            {product.discountPercent}% 
                          </Text>
                        </div>
                        <Text className="price">
                          {formatNumberWithCommas(product.price)}₮
                        </Text>
                      </Space>
                      <div className="star-ratings">
                        <Space direction="horizontal">
                          <div>
                            <Rate
                              allowHalf
                              defaultValue={1}
                              count={1}
                              disabled
                              style={{ fontSize: 18 }}
                            />
                            <Text className="star-rating-number">
                              {product.starRating.toFixed(1)}
                            </Text>
                          </div>
                          <Text className="order-number" type="secondary">
                            Захиалсан тоо:
                            {formatNumberWithCommas(product.lastOrdered)}
                          </Text>
                        </Space>
                      </div>
                    </>
                  }
                />
              </>
            ) : (
              <>
                <Space direction="horizontal" className="row-st">
                  <div>
                    <div className="image-container">
                      <Image
                        src={product.imageUrl}
                        alt="Product Image"
                        style={{ margin: 0, padding: 0, width: 150 }}
                        preview={false}
                      />
                    </div>
                    <div className="sale-timer">
                      <Countdown
                        value={product.saleEndDate}
                        format="Дуусах: H[ц] m[м] s[с]"
                      />
                    </div>
                    <div className="favorite-button">
                      <Badge>
                        <Link to="/collections">
                          <StarOutlined className="starsvg" />
                        </Link>
                      </Badge>
                    </div>
                  </div>
                  <div>
                    <Meta
                      title={
                        <Text
                          className="product-title"
                          strong
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          {product.productName}
                        </Text>
                      }
                      description={
                        <>
                          <Space>
                            <div>
                              <Text className="discount-percent" strong>
                                {product.discountPercent}%
                              </Text>
                            </div>
                            <Text className="price">
                              {formatNumberWithCommas(product.price)}₮
                            </Text>
                          </Space>
                          <div className="star-ratings">
                            <Space direction="horizontal">
                              <div>
                                <Rate
                                  allowHalf
                                  defaultValue={1}
                                  count={1}
                                  disabled
                                  style={{ fontSize: 18 }}
                                />
                                <Text className="star-rating-number">
                                  {product.starRating.toFixed(1)}
                                </Text>
                              </div>
                              <Text className="order-number" type="secondary">
                                Захиалга:
                                {formatNumberWithCommas(product.lastOrdered)}
                              </Text>
                            </Space>
                          </div>
                        </>
                      }
                    />
                  </div>
                </Space>
              </>
            )}
          </Card>
        ))}
      </div>
    </div>
  );
}
