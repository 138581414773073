import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  Space,
  Button,
  Form,
  Input,
  Radio,
  DatePicker,
  message,
  Upload,
  notification,
} from "antd";
import {
  UploadOutlined,
  UserOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { api } from "../../system/api";
import { getBaseURL } from "../../system/apiconfig";

export default function MemberInfo({ griddata, userid }) {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setFormData(values);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      api
        .post("/api/systems/User/set_user", formData)
        .then((response) => {
          message.success("Мэдээлэл амжилттай шинэчлэгдлээ.");
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating user information:", error);
        });
    }
  }, [formData]);

  useEffect(() => {
    if (griddata) {
      // Set form values when griddata changes
      form.setFieldsValue({
        userid: userid,
        email: griddata?.email,
        username: griddata?.username,
        gender: griddata?.gender,
        birthday: dayjs(griddata?.birthday, "YYYY-MM-DD").add(1, "day"),
      });
    }
  }, [griddata]);
  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Card
        className="user-editing-card"
        style={{ width: "90%", padding: 10, marginBottom: 50 }}
      >
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Space className="fs">
            <h3>Гишүүний мэдээлэл засах</h3>
            {/* <Button type="link" className="f10">
              Бүртгэлээ цуцлах
            </Button> */}
          </Space>
          <Form
            name="profile-edit-form"
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 13 }}
            form={form}
            initialValues={{
              userid: griddata?.userid,
              email: griddata?.email,
              username: griddata?.username,
              gender: griddata?.gender === 1 ? "1" : "2",
              birthday: dayjs(griddata?.birthday, "YYYY-MM-DD"),
            }}
          >
            <Form.Item name="userid" label="Дугаар" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item
              label="И-мэйл"
              name="email"
              rules={[
                {
                  required: true,
                  message: "И-мэйл хаяг оруулна уу!",
                },
              ]}
            >
              <Input placeholder="И-мэйл хаяг" defaultValue={griddata?.email} />
            </Form.Item>

            <Form.Item
              label="Хэрэлэгчийн нэр"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Хэрэглэгчийн нэр оруулна уу!",
                },
              ]}
            >
              <Input
                placeholder="Хэрэглэгчийн нэр"
                defaultValue={griddata?.username}
              />
            </Form.Item>

            <Form.Item
              label="Хүйс"
              name="gender"
              defaultValue={griddata?.gender}
            >
              <Radio.Group>
                <Radio value={1}>эрэгтэй</Radio>
                <Radio value={2}>эмэгтэй</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item label="Төрсөн огноо" name="birthday">
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={dayjs(griddata?.birthday, "YYYY-MM-DD")}
              />
            </Form.Item>

            <Form.Item label="Профайл зураг" name="profilePicture">
              <Space direction="vertical" align="center">
                <Upload
                  name="avatar"
                  fileList={
                    griddata?.images?.length > 0
                      ? [
                          {
                            uid: "-1",
                            status: "done",
                            url: `${getBaseURL()}/api/file/download?ID=${
                              griddata?.images[griddata?.images?.length - 1]
                            }&size=64`,
                          },
                        ]
                      : []
                  }
                  listType="picture-circle"
                  showPreviewIcon={false}
                  showDownloadIcon={false}
                  onRemove={(file) => {
                    // Check if a file is being removed
                    if (file.status === "done" && file.url) {
                      // Extract the file ID from the URL
                      const fileId = file.url.split("=")[1].split("&")[0];

                      // Make the API call to delete the file
                      api
                        .post(`/api/file/delete?ID=${fileId}`)
                        .then((response) => {
                          // Handle success
                          message.success("Профайл зургийг устгалаа.");
                          window.location.reload();
                        })
                        .catch((error) => {
                          // Handle error
                          console.error("Error deleting file:", error);
                          message.error("Failed to delete file.");
                        });
                    }
                  }}
                  beforeUpload={(file) => {
                    const formData = new FormData();
                    formData.append("file", file);
                    api
                      .post(
                        `/api/file/upload?sourcetype=ecomuser&SourceID=${griddata?.userid}`,
                        formData,
                        {
                          headers: { "Content-Type": "multipart/form-data" },
                        }
                      )
                      .then((response) => {
                        // Handle success
                        setImageUrl(response.data.url);
                        message.success("Амжилттай зураг байршууллаа.");
                        window.location.reload();
                      })
                      .catch((error) => {
                        // Handle error
                        console.error("Error uploading file:", error);
                        message.error(
                          "Зураг байршуулж чадсангүй. Дахин оролдоно уу."
                        );
                      });

                    // Prevent default upload behavior
                    return false;
                  }}
                  maxCount={1}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Space>
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 3, span: 16 }}
              style={{ paddingTop: 30 }}
            >
              <Button type="primary" htmlType="submit" size="large">
                Гишүүний мэдээлэл засах
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Col>
  );
}
