import { Divider, Descriptions } from "antd";
import React from "react";
import './style.css';

export default function DeliverySection() {
  return (
    <>
      <div className='del-body'>
        <h3>Хүргэлт</h3>
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Хүргэлт" >Ерөнхий хүргэлт</Descriptions.Item>
          <Descriptions.Item label="Хүргэлтийн хураамж" >ҮНЭГҮЙ</Descriptions.Item>
          <Descriptions.Item label="Хөдөө орон нутагт хүргэгдэх хүргэлт">
            Аймгийн зай болон барааны хэмжээнээс хамаарч тусдаа үнэ бодогдоно
          </Descriptions.Item>
          <Descriptions.Item label="Хүргэлт боломжгүй газар">
            Алслагдсан сум / баг
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Descriptions title="Бараа буцаан олгох / солих" column={1} bordered>
          <Descriptions.Item label="Буцах тээврийн зардал">
            7,000 вон ( Эхний хүргэлт үнэгүй бол 14,000 вон авна )
          </Descriptions.Item>
          <Descriptions.Item label="Хүргэлтийн төлбөрийг солино">
            14,000 вон
          </Descriptions.Item>
          <Descriptions.Item label="Илгээх">
            (16703) Ханжин Экспресс Ёнтун салбар, 1579 Бонгён-ро, Ёнтонг-гу,
            Сувон-си, Кёнгидо
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Descriptions 
          title="Буцаах/солилцох шалтгаанаас хамааран хүссэн хугацаа"
          column={1} style={{wordWrap: 'break-word'}}
        >
          <Descriptions.Item label="Бүтээгдэхүүнийг буцаахдаа эхлээд худалдагчтай холбоо барьж, буцаах болсон шалтгаан, шуудангийн компани, тээвэрлэлтийн зардал, буцах хаяг гэх мэт зүйлийг буцаасан бүтээгдэхүүнийг илгээнэ үү." />
          <Descriptions.Item label="Хэрэв худалдан авагч бүтээгдэхүүнийг хүлээн авснаас хойш 7 хоногийн дотор бодлоо өөрчилсөн бол (худалдан авагч буцах тээврийн зардлыг хариуцна)" />
        </Descriptions>
      </div>
    </>
  );
}
