import React from "react";
import { Tooltip, Image, Carousel } from "antd";
import { getBaseURL } from "../../system/apiconfig";

const ImagePreviewContainer = ({
  pimg,
  selectedImage,
  handleImageMouseEnter,
  isMobile,
}) => {
  return (
    <>
      {!isMobile ? (
        <div className="image-preview-container">
          <div className="image-list">
            {pimg?.map((image) => (
              <Tooltip
                placement="right"
                key={image?.id}
                onMouseEnter={() => handleImageMouseEnter(image?.id)}
              >
                <Image
                  src={`${getBaseURL()}/api/file/download?ID=${
                    image?.id
                  }&size=100`}
                  alt="Image"
                  className={`thumbnail-image ${
                    selectedImage === image?.id ? "selected" : ""
                  }`}
                  style={{ width: 80 }}
                  preview={false}
                />
              </Tooltip>
            ))}
          </div>
          {selectedImage && (
            <div className="preview-image">
              <Image
                src={`${getBaseURL()}/api/file/download?ID=${selectedImage}&size=500`}
                alt="Image"
                className="selected"
                preview={false}
              />
            </div>
          )}
        </div>
      ) : (
        <Carousel className="product-carousel" draggable dotPosition="bottom">
          {pimg?.map((image, index) => (
            <div key={index} className="carousel-slide">
              <div className="carousel-image">
                <Image
                  src={`${getBaseURL()}/api/file/download?ID=${
                    image?.id
                  }&size=500`}
                  alt="Image"
                  preview={false}
                />
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default ImagePreviewContainer;
