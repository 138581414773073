import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Input,
  InputNumber,
  Drawer,
  Button,
  Form,
  Upload,
  Image,
  message as antMessage,
  Space,
  DatePicker,
  message,
  Popconfirm,
  Divider,
} from "antd";
import { api } from "../../../system/api";
import { getBaseURL } from "../../../system/apiconfig";
import { UploadOutlined } from "@ant-design/icons";

export default function TagRelations() {
  const [griddata, setGridData] = useState();
  const [loading, setLoading] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();

  const fetchData = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/api/Product/get_Tag_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.rettype === 0) {
          setGridData(res?.data?.retdata);
        }
      })
      .finally(() => {
        setLoading(false);
        setDrawerVisible(false);
      });
  }, []);

  const onFinish = async (values) => {
    try {
      // Step 1: Add category
      const addCategoryResponse = await api.post("/api/Product/set_tag", {
        id: values.id !== null ? values.id : undefined,
        name: values.name,
        subName: values.subname,
        orderno: values.orderno,
      });

      if (
        addCategoryResponse?.status === 200 &&
        addCategoryResponse?.data?.rettype === 0
      ) {
        const newCategoryId = addCategoryResponse?.data?.retdata?.id;
        // Trigger image upload separately if there is an icon file
        if (values?.image?.file !== null) {
          await uploadImage(newCategoryId, values?.image?.file);
        }

        message.success("Category added successfully");
        showDrawer(false);
        fetchData();
      } else {
        console.error("Failed to add category:", addCategoryResponse);
        message.error(
          "Failed to add category. Please check the console for details."
        );
      }
    } catch (error) {
      console.error("Add category and upload image failed:", error);
      console.log("Error response:", error.response);
      message.error("An error occurred. Please check the console for details.");
    }
  };

  const uploadImage = async (categoryId, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadImageResponse = await api.post(
        `/api/file/upload?sourcetype=ecomtag&SourceID=${categoryId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (
        uploadImageResponse?.status === 200 &&
        uploadImageResponse?.data?.rettype === 0
      ) {
        message.success("Image uploaded successfully");
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Image upload failed:", error);
      message.error("Failed to upload image");
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      ellipsis: true,
      render: (text, record) => (
        <Button type="link" onClick={() => showDrawer(record)}>
          {text}
        </Button>
      ),
    },

    {
      title: "subname",
      dataIndex: "subname",
      key: "subname",
    },
    {
      title: "orderno",
      dataIndex: "orderno",
      key: "orderno",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <Image
          key={record?.image}
          src={`${getBaseURL()}/api/file/download?ID=${record?.image}`}
          alt={`Image ${record?.image}`}
          style={{ maxWidth: "50px", marginRight: "5px" }}
        />
      ),
    },
  ];

  const newFormData = async () => {
    form.setFieldsValue({
      id: 0,
      name: null,
      subname: null,
      orderno: 0,
      image: { file: null },
    });
    setDrawerVisible(true);
  };

  const showDrawer = (product) => {
    form.setFieldsValue(product);
    setDrawerVisible(true);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={(e) => newFormData()}
        style={{ marginBottom: 16 }}
      >
        Бараа нэмэх
      </Button>
      <Table
        dataSource={griddata}
        columns={columns}
        bordered
        rowKey={(record) => record?.id}
        loading={loading}
        scroll={{ x: "max-content" }}
      />
      <Drawer
        title="Add Product"
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={800}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item name="id" label="Дугаар" hidden={true}>
            <Input />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="orderno"
                label="orderno"
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
            </div>

            {/* Second Column */}
            <div style={{ width: "48%" }}>
              <Form.Item
                name="subname"
                label="subname"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="image" name="image">
                <Upload
                  maxCount={1}
                  beforeUpload={() => false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>Upload Icon</Button>
                </Upload>
              </Form.Item>
            </div>
          </div>

          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
