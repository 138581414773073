import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Form,
  Input,
  Image,
  Drawer,
  message,
  InputNumber,
  Upload,
} from "antd";
import { api } from "../../../system/api";
import { getBaseURL } from "../../../system/apiconfig";
import { UploadOutlined } from "@ant-design/icons";
import "./style.css";

export default function CategoryOfProduct() {
  const [griddata, setGridData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [fileList, setFileList] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const parentResponse = await api.get(`/api/Product/Get_category_Tree`);

      if (
        parentResponse?.status === 200 &&
        parentResponse?.data?.rettype === 0
      ) {
        setGridData(parentResponse?.data?.retdata);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: "Parent ID",
      dataIndex: "parentid",
      key: "parentid",
    },
    {
      title: "Order Number",
      dataIndex: "orderno",
      key: "orderno",
    },
    {
      title: "Updated",
      dataIndex: "updated",
      key: "updated",
    },
    {
      title: "Updated By",
      dataIndex: "updatedby",
      key: "updatedby",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      render: (_, record) => (
        <>
          {record?.images !== null ? (
            <Image
              src={`${getBaseURL()}/api/file/download?ID=${
                record?.images?.[record?.images?.length - 1]
              }&size=40`}
              alt="Image"
              style={{ maxWidth: "50px" }}
            />
          ) : null}
        </>
      ),
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) =>
        griddata?.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleDelete = async (record) => {
    try {
      if (record && record.id) {
        await api.delete(`/api/product/delete_category?id=${record.id}`);
        if (record && record?.images?.length > 0) {
          await api.post(
            `/api/file/delete?ID=${record?.images[record?.images?.length - 1]}`
          );
          message.success("zurag ustlaa");
        } else {
          console.error("Invalid record object or missing 'id' property.");
        }
        fetchData();
      }
    } catch (error) {
      console.error("Delete failed:", error);
    }
  };

  const handleRowClick = (record, e) => {

    // Set the form fields with the selected row data
    form.setFieldsValue(record);

    // Update the fileList state based on the images array of the selected row
    if (record?.images && record?.images?.length > 0) {
      const fileList = record.images.map((image, index) => ({
        uid: `${index + 1}`, // Unique ID for each image
        name: `Image ${index + 1}`, // Name of the image
        status: "done", // Status of the image (done, uploading, error)
        url: `${getBaseURL()}/api/file/download?ID=${image}&size=40`, // URL for the image
      }));

      setFileList(fileList);
    } else {
      setFileList([]);
    }

    setSelectedRow(record);
    setVisibleDrawer(true);
  };

  const handleAddCategoryAndUploadImage = async (values) => {
    try {
      // Step 1: Add category
      const addCategoryResponse = await api.post("/api/Product/Set_category", {
        id: values.id !== null ? values.id : undefined,
        name: values.name,
        parentid: values.parentid,
        orderno: values.orderno,
      });

      if (
        addCategoryResponse?.status === 200 &&
        addCategoryResponse?.data?.rettype === 0
      ) {
        const newCategoryId = addCategoryResponse?.data?.retdata?.id;

        // Trigger image upload separately if there is an icon file
        if (values.icon?.file) {
          await uploadImage(newCategoryId, values.icon.file);
        }

        message.success("Category added successfully");
        setVisibleDrawer(false);
        fetchData();
      } else {
        console.error("Failed to add category:", addCategoryResponse);
        message.error(
          "Failed to add category. Please check the console for details."
        );
      }
    } catch (error) {
      console.error("Add category and upload image failed:", error);
      console.log("Error response:", error.response);
      message.error("An error occurred. Please check the console for details.");
    }
  };

  const uploadImage = async (categoryId, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadImageResponse = await api.post(
        `/api/file/upload?sourcetype=ecomcategory&SourceID=${categoryId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (
        uploadImageResponse?.status === 200 &&
        uploadImageResponse?.data?.rettype === 0
      ) {
        const newFile = {
          uid: `${fileList?.length + 1}`, // Unique ID for the new file
          name: file.name,
          status: "done",
          url: `${getBaseURL()}/api/file/download?ID=${
            uploadImageResponse.data.retdata.id
          }&size=40`,
        };

        // Update the fileList state with the new file
        setFileList((prevFileList) => [...prevFileList, newFile]);

        message.success("Image uploaded successfully");
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Image upload failed:", error);
      message.error("Failed to upload image");
    }
  };

  const handleRemoveFile = async (file) => {
    try {
      if (file && file.uid) {
        // Extract the file ID from the URL
        const fileId = file.url.split("=")[1];

        // Make an API request to delete the file
        await api.post(`/api/file/delete?ID=${fileId}`);

        // Update the fileList state by filtering out the removed file
        setFileList((prevFileList) =>
          prevFileList.filter((f) => f.uid !== file.uid)
        );

        message.success("File deleted successfully");
      }
    } catch (error) {
      console.error("File deletion failed:", error);
      message.error("Failed to delete file");
    }
  };

  const newFormData = async () => {
    form.setFieldsValue({
      id: 0,
      name: null,
      parentid: null,
      orderno: 0,
      icon: { file: null },
    });
    setFileList([]);
    setVisibleDrawer(true);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => newFormData()}
        style={{ marginBottom: 16 }}
      >
        Категори нэмэх
      </Button>

      <Table
        bordered
        rowKey={(record) => record?.id}
        dataSource={griddata}
        columns={columns}
        loading={loading}
      />

      <Drawer
        title={selectedRow ? "Edit Category" : "Add Category"}
        placement="right"
        closable={true}
        onClose={() => setVisibleDrawer(false)}
        visible={visibleDrawer}
        width={400}
      >
        <Form form={form} onFinish={handleAddCategoryAndUploadImage}>
          <Form.Item name="id" label="Дугаар" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please enter the category name" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Parent ID"
            name="parentid"
            rules={[{ required: true, message: "Please enter the parent ID" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Order Number"
            name="orderno"
            rules={[
              { required: true, message: "Please enter the order number" },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item label="Icon" name="icon">
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              listType="picture"
              fileList={fileList}
              onRemove={(file) => handleRemoveFile(file)}
            >
              <Button icon={<UploadOutlined />}>Upload Icon</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {selectedRow ? "Save Changes" : "Add Category"}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
